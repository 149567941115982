<template>
   <div class="wrapper">
    <div class="tabs">
         <keep-alive>
            <template v-for="(item,index) in homePages">
                <component :is="item.component" :key="index" v-if="active==index" :ref="item.ref" class="tab-item"></component>
            </template>
        </keep-alive>
    </div>
    <van-tabbar v-model="active" :active-color="mainColor" inactive-color="#ccc" @change="tabChange">
        <van-tabbar-item v-for="(item,index) in homePages" :key="index" :icon="item.icon">
            {{item.name}}
        </van-tabbar-item>
    </van-tabbar>
   </div>
</template>

<script>
import indexPage from "@/views/common/index";
import fkyPage from "@/views/common/fky";
import myPage from "@/views/common/my";
import sessionStorage from "@/utils/sessionStorage";
import dataStorage from "@/utils/dataStorage"
import mapLoad from "@/utils/Amap";
import { getCityData } from "@/utils";
import { Decrypt } from "@/utils/crypto";
export default {
    
    name:'home',

    components:{

        indexPage,

        fkyPage,

        myPage

    },

    data(){

        return{

            active:null,

            oldActive:0,

            homePages:[

                {
                    name:'首页',

                    icon:"system-home",

                    component:'indexPage',

                    ref:'index_page'

                },{
                    name:'报备',

                    icon:"system-fky",

                    component:'fkyPage',

                    ref:'fky-page'

                },{
                    name:'我的',

                    icon:"system-my",

                    component:'myPage',

                    ref:'my-page'

                },

            ],

            pageLock:false,

            cancelLock:false,

            queue:null

        }

    },

    computed:{

        mainColor(){
            return this.$store.state.userInfo?.company?.color || '#00a0e4'
        }

    },

    methods:{

        //个人定位

        accessToLocate(AMap){

                const onComplete = (data)=> {

                    if(data.regeocode){

                        let cityData = this.checkCity(data?.regeocode?.addressComponent?.city);

                        //判断当前选择的城市和定位城市是否一致;

                        if(this.$store.state.userInfo.currentCity && this.$store.state.userInfo.currentCity.value == cityData?.value) return;

                        //是否存在对应的城市数据，不存在直接选择海口

                        if(cityData && this.active == 0){

                            this.$dialog.confirm({

                                title:'快速定位',

                                message:`当前定位城市为 ${data?.regeocode?.addressComponent.province}${data?.regeocode?.addressComponent.city}\n是否选择该城市`,

                                confirmButtonText:'选择',

                                confirmButtonColor:'#00a0e4'

                            }).then(()=>{

                                let date = new Date();

                                let city = Object.assign(cityData,{time:date.getTime()});

                                this.$store.commit('setUserData',{
                                    key:'currentCity',
                                    value:city
                                });

                                //储存当前数据

                                dataStorage.StorageSetter('city',city);

                                this.$nextTick(()=>{

                                    this.$refs.index_page[0].getIndexData();

                                })
                                        
                            }).catch(()=>{

                                this.$nextTick(()=>{

                                    global.setTimeout(()=>{

                                        this.$dialog.confirm({

                                            title:'选择城市',

                                            message:'是否要选择其他城市？',

                                            confirmButtonColor:"#00a0e4",

                                            confirmButtonText:'前往选择'

                                        }).then(()=>{

                                            this.$router.push({name:'selectCity'})

                                        }).catch(()=>{

                                            let city = getCityData(this.$store,{name:'海口市'});

                                            this.$store.commit('setUserData',{

                                                key:'currentCity',

                                                value:city

                                            });

                                            this.$nextTick(()=>{

                                                this.$refs.index_page[0].getIndexData();

                                            })

                                            this.$toast({

                                                position:'bottom',

                                                message:'已为您定位到海口市'

                                            });

                                        });

                                    },300)

                                })

                            })

                        }

                    
                    }else{
                        this.selectCityFaild();

                    }

              
                }

                const onError = (err)=> {

                    console.log(err)

                    // 定位出错

                    this.selectCityFaild();

                }

                AMap.plugin('AMap.Geolocation', ()=> {
                           
                        var geolocation = new AMap.Geolocation({
                            // 是否使用高精度定位，默认：true
                            enableHighAccuracy: true,
                            // 设置定位超时时间，默认：无穷大
                            timeout: 20000,
                            // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                            buttonOffset: new AMap.Pixel(10, 20),
                            //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                            zoomToAccuracy: true,     
                            //  定位按钮的排放位置,  RB表示右下
                            buttonPosition: 'RB'
                        })

                        geolocation.getCurrentPosition((status,result)=>{

                            if(status=='complete'){

                                var geocoder = new AMap.Geocoder({city: '全国'});

                                geocoder.getAddress({lat:result.position.lat,lng:result.position.lng}, (_status, _result)=>{

                                    onComplete(_result);

                                })

    

                            }else{

                                onError(result);

                            }

                         });
                })

        },

        //提示绑定微信弹窗,一次页面打开只提示一次

        bindWxTips(){

            //首先检查此次是否弹出了此窗口

            let check = sessionStorage.StorageGetter("bindWxTipsAlert");

            if(check) return;

            //首先检查客户是否绑定了微信

            if(!this.$store.state.userInfo.bindWx && this.$store.state.userInfo.ready){

                sessionStorage.StorageSetter("bindWxTipsAlert",true);

                this.$dialog.confirm({

                    title:'未绑定微信',

                    message:'我们发现您还未绑定微信，请绑定微信，以便能够接收到报备通知。',

                    confirmButtonText:"前往绑定",

                    confirmButtonColor:'#00a0e4'

                }).then(()=>{

                    this.active = 2;

                }).catch(()=>{})

            }

        },

        //高德地图加载

        Amapload(){
            
            sessionStorage.StorageSetter("selectCityAlert",true);

            mapLoad(['AMap.Geocoder']).then((AMap)=>{

                this.accessToLocate(AMap);

                return;

            }).catch((e)=>{

                console.log(e);

                this.selectCityFaild();

            })

        },


        //微信加载

        wxLoad(){

            //判断是否已经进行过弹窗

            let check = sessionStorage.StorageGetter("selectCityAlert"); 

            if(check) return;

            let city = dataStorage.StorageGetter('city');

            if(city) return;

            this.Amapload();

        },


        //检查对应城市是否存在

        checkCity(cityName){

           return getCityData(this.$store,{name:cityName});

        },

        //选择城市失败

        selectCityFaild(){

            if(this.active !=0 || this.$route.name != 'home') return;

            this.$dialog.confirm({

                title:'定位失败',

                message:'定位失败，请手动选择城市',

                confirmButtonText:'前往选择',

                confirmButtonColor:"#00a0e4"

            }).then(()=>{

                this.$router.push({name:"selectCity"});

            }).catch(()=>{

                let date = new Date();

                let city = getCityData(this.$store,{name:'海口市'});

                city.time = date.getTime();

                this.$store.commit('setUserData',{

                    key:'currentCity',

                    value:city

                });
                //记录当前用户选择的城市

                dataStorage.StorageSetter('city',city);

                this.$nextTick(()=>{

                    this.$refs.index_page[0].getIndexData();

                })


            });




        },

        //切换验证

        tabChange(val){

            if(val == 1 && !this.$store.state.userInfo.ready){

                this.$router.push({name:'login'});

                this.active=this.oldActive;

                return;

            }

            if(val!=1){

                this.oldActive=val;

            }

        },

        //数据恢复

        recoveryRecordData(){

            //超时了

            return new Promise((res,rej)=>{

                //检查是否存在需要恢复的数据

                let recordData=dataStorage.StorageGetter('record_data_cache');

                if(!recordData){

                    res();

                    return;

                }

       

                recordData = Decrypt(recordData);

                //如果存在，检查一下过期时间

                let date = new Date();

                let time = recordData.cacheTime;

                if(date.getTime()>time){

                    this.$store.commit('resetRecordData');

                    res();

                    return;

                }

  

                this.$dialog.confirm({

                    title:'恢复报备',

                    message:'检查到您有正在进行的报备单，是否前往继续报备？',

                    confirmButtonText:'前往',

                    confirmButtonColor:"#00a0e4"


                }).then(()=>{

                    //继续恢复

                    this.$store.commit('recoveryRecordData',recordData);

                    //前往报备页面

                    this.$nextTick(()=>{

                        this.$router.push({name:'rapidRecord'},{

                            parent:this.$store.state

                        });

                    });

                    rej();

                }).catch(()=>{

                    this.$store.commit('resetRecordData');
                    
                    res()
                    
                })

                
            })


         


        }


    },

    activated() {



        if(this.pageLock) return;

        this.pageLock=true;
        
        const home_type = this.$route.query.active || this.$route.params.active;

        this.active = home_type == 'index' ? 0 : home_type == 'fky' ? 1 : home_type == 'my' ? 2 : 0;

        //首页才会执行的数据

        if(this.active == 0){

            this.$nextTick(()=>{

                this.recoveryRecordData().then(()=>{

                    this.wxLoad();

                }).catch(()=>{})

            
            })

        }

    },

    watch:{

        active(val){

            if(val == 1 && this.$store.state.userInfo.ready){

                this.$nextTick(()=>{

                    this.bindWxTips();

                })

            }

        }

    }

}
</script>

<style scoped lang="less">
.wrapper{

    position: relative;

    .tabs{

        position: absolute;

        top:0;

        bottom:50px;

        left:0;

        right:0;

        .tab-item{

            width: 100%;

            height:100%;

        }

    }

}
</style>
