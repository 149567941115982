<template>
<van-popup v-model="show" position="center" closeable :close-on-click-overlay="false" round @open="init" @close="clearForm">
	<div class="verify-code">
        <div class="verify-code-title">机器验证</div>
        <div class="verify-code-img">
            <img :src="verifyCode.img"  v-if="verifyCode.img" mode="" @click="getVerifyCode"/>
        </div>
        <div class="verify-code-input">
            <van-field v-model="code" placeholder="请输入上方的图形验证码" ref="codeInput"/>
        </div>
        <div class="verify-code-btn">
            <van-button type="info" round @click="confirm" :disabled="isDisabled" :loading="loading" class="verify-code-btn--btn">验证</van-button>
        </div>
    </div>
</van-popup>
</template>
<script>
import api from "@/api";
export default {
    props:{
        value:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            verifyCode:{},
            code:''
        }
    },
    computed:{
        show:{
            get(){
                return this.value;
            },
            set(val){
                this.$emit('input',val);
            }
        },
        isDisabled(){
            return this.code.length <4
        }
    },
    methods: { 
        init(){
            this.getVerifyCode();
        },
        getVerifyCode(){
            api.getVerifyCode({
                success:(res)=>{
                    if(res.type == 200){
                        this.verifyCode = res.data;
                        this.$refs.codeInput.focus();
                    }else{
                        this.$toast('获取验证码失败');
                    }

                },
                error:(err)=>{
                    console.log(err);
                },
                complete:()=>{}
            })
        },
        clearForm(){
            this.verifyCode = {};
            this.code = '';
        },
        resetForm(){
            this.getVerifyCode();
            this.code = '';
        },
        confirm(){
            this.$emit('confirm',{
                sn:this.verifyCode.sn,
                code:this.code
            })
        }
    }



}
</script>

<style lang="less" scoped>
    .verify-code{
        width: 90vw;
        padding: 10px 10px 30px;
        display: flex;
        align-items: center;
        flex-direction:column;
        &-title{
            font-size:20px;
            color:#888888;
        }
        &-img{
            width: 170px;
            height:60px;
            margin-top:20px;
            margin-bottom:20px;
            img{
                width: 100%;
                height:100%;
            }
        }
        .van-cell{
            font-size:14px;
            padding: 5px 15px;
            
           /deep/ .van-field__control{
                padding-top:0;
            }
        }
        &-input{
            width: 190px;
            margin-bottom:30px;
            border:1px solid #eee;
            
            border-radius: 8px;
            overflow: hidden;
        }

        &-btn{
            width: 190px;
            &--btn{
                width: 100%;
            }
        }
    }
</style>