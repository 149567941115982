const state = {

    //列表所需要的滚动

    recordListScroll:null,

    //列表是否需要刷新

    recordListRefresh:false,

    //首页是否需要刷新

    indexRefresh:false,

    //收藏列表是否需要刷新

    favoriteListRefresh:false,

    //最后一个页面

    lastPage:null,
    
    //是否进行完第一次跳转

    isFirstPush:false,

    //员工列表是否需要刷新

    isStaffListRefresh:false
  
}

const mutations = {

    //设置数据

    setRouterData(state,{key,value}){
        
        state[key] = value;

    },



}

const actions = {

  

}

const getters = {}


 export default {

    state:state,
    mutations:mutations,
    actions:actions,
    getters:getters

 }