//所有有关楼盘报备的路由都写在这里




import list from "@/views/house/list";
import detail from "@/views/house/detail";
import record from "@/views/house/record";
import selectHouse from "@/views/house/selectHouse";
import selectCustomer from "@/views/house/selectCustomer";
import selectStaff from "@/views/house/selectStaff";

const router = [

    {

        path:'/house/list',

        name:'houseList',

        component:list,

        meta:{

            lv:2,

            live:true

        }

    },{

        path:'/house/detail/:id',

        name:'houseDetail',

        component:detail,

        meta:{
 
            lv:3,

            live:true

        }

    },{

        path:'/house/detail/:id/:shareId',

        name:'houseShareDetail',

        component:detail,

        meta:{
 
            lv:3,

            live:true

        }

    },{

        path:'/house/record/:id',

        name:'/house/detail/record',

        component:record,

        meta:{

            lv:5,

            parent:'/house/detail/:id'

        }

    },{

        //选择楼盘

        path:'/house/record/select/house',

        name:'selectHouse',

        component:selectHouse,
        
        meta:{

            lv:5,

            parent:'/house/record/:id'

        }

    },{

        //导入客户

        path:"/house/record/select/customer",
        
        name:'selectCustomer',

        component:selectCustomer,

        meta:{

            lv:5

        }


    },{

        //导入带看人

        path:"/house/record/select/staff",
        
        name:'selectStaff',

        component:selectStaff,

        meta:{

            lv:5

        }


    },{
        path:"/house/navigation",

        name:'houseNavigation',

        component:() => import('@/views/house/navigation'),

        meta:{

            lv:5,

            //标记为懒加载组件

            module:true,

            //不受登录影响

            live:true

        }


    },{
        path:"/house/vr/:id",

        name:'houseVr',

        component:() => import('@/views/house/vrIframe'),

        meta:{

            lv:5,

            //标记为懒加载组件

            module:true,

            //不受登录影响

            live:true

        }


    }


]




export default router

