import Vue from "vue";
import Vuex from "vuex";
import record from "@/store/modules/record";
import routerStore from "@/store/modules/router";
import userInfo from "@/store/modules/userInfo";
import commonForm from '@/store/modules/commonForm';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    record,
    routerStore,
    userInfo,
    commonForm
  }
});
