/**
 * 
 * 微信js-sdk 配置
 * 
 */



 import api from "@/api";

 import {isWeex} from "@/utils"

 class fkyWxConfig{

    constructor(){

        this.wx = global.wx;

        this.config = {

            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: '', // 必填，公众号的唯一标识
            timestamp:'', // 必填，生成签名的时间戳
            nonceStr: '', // 必填，生成签名的随机串
            signature: '',// 必填，签名
            jsApiList: [] // 必填，需要使用的JS接口列表
            
        }

    }

    //初始化微信

    init(){

        return new Promise((res,rej)=>{

            if(!isWeex()){

                rej('请在微信浏览器打开!');

                return;

            }

            api.getWxConfig({

                options:{
    
                    url:window.location.href
    
                },
    
                success: (_res)=>{
    
                    if(_res.type == 200){
    
                        let options = Object.assign(this.config,_res.data);
    
                        this.wx.config(options);
    
                        this.wx.ready(()=>{
    
                            res(this.wx);
    
                        });
    
                        this.wx.error((err)=>{

                            rej(err);
                            
                        })
    
                    }
                    
                }
    
            })



        })

     

       
    }


 }


export default new fkyWxConfig();