<template>
   <div class="customer-list-wrapper">
       <div class="flex-box-column customer-list-cover">
            <div class="hd">
                 <van-nav-bar left-arrow safe-area-inset-top title="客户管理"   class="search" @click-left="back">
                </van-nav-bar>
                <div class="search-cover">
                    <van-search v-model="keyword" class="sm border" shape="round" placeholder="搜索客户" @input="onSearch" ref="recordListSearch"/>
                </div>
            </div>
            <div class="bd">
                <van-empty image="error" description="获取数据失败,点击重试" @click.native="getCustomerList" v-if="pageErr" />
                <van-index-bar :sticky="false" highlight-color="#333">
                    <template v-for="(item,index) in renderList">
                        <div :key="index">
                            <van-index-anchor :index="item.anchor" v-if="item.anchor"/>
                            <van-swipe-cell v-for="(_item,_index) in item.list" :key="_index">
                                <van-cell title-class="p-b-10">
                                    <template #icon>
                                        <div class="avatar" :class="{man:_item.sex==1,woman:_item.sex==2}"></div>
                                    </template> 
                                    <template #title>
                                        <span>{{_item.name}}</span>
                                    </template>  
                                    <template #label>
                                        <span>{{_item.phone}}</span>
                                    </template>
                                </van-cell>
                                <template #right>
                                    <a :href="'tel:'+_item.phone" class="tel">拨打</a>
                                    <van-button square type="danger" text="删除" @click="deleteCustomer(_item)" />
                                </template>
                            </van-swipe-cell>
                            <van-empty image="search" description="没有对应客户信息" v-if="item.empty"/>
                        </div> 
                    </template>
                    <van-empty description="没有客户信息" v-if="customerList.length == 0"/>
                </van-index-bar>
            </div>
       </div>
   </div>
</template>

<script>
import backMixin from '@/mixins/back';
import permissionCheckMixin from "@/mixins/permissionCheck";
import api from "@/api";
import throttle from "@/mixins/throttle";
export default {

    mixins:[backMixin,permissionCheckMixin,throttle],


    data(){

        return {

            keyword:'',

            customerList:[],

            searchList:[],

            pageErr:false

        }

    },

    computed:{

        renderList(){

            return this.searchList.length>0 ? this.searchList : this.customerList;

        }

    },


    methods:{

        searchCustom(){
            this.throttle({
                key:'searchCustom',
                time:400,
                callback:this.onSearch
            })
        },

        onSearch(keyword){
            
            this.$toast.loading({

                overlay:true,

                duration:0,

                message:"加载中..."

            });

            api.getCustomerList({
                    options:{
                        keyword
                    },

                    success:(res)=>{

                        if(res.type == 200){

                            this.customerList = res.data;

                            this.pageErr=false;

                        }else{

                            this.$dialog.confirm({

                                title:'获取数据失败',

                                message:res.msg || "获取客户列表失败，请稍后重试",

                                confirmButtonText:'重试',

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{

                                this.getCustomerList();

                            }).catch(()=>{})

                            this.pageErr=true;

                        }

                        

                    },

                    error:(err)=>{

                        console.log(err);

                        this.pageErr=true;

                        this.$dialog.confirm({

                            title:'获取数据失败',

                            message: err || "获取客户列表失败，请求异常，请稍后重试",

                            confirmButtonText:'重试',

                            confirmButtonColor:"#00a0e4"

                        }).then(()=>{

                                this.getCustomerList();

                        }).catch(()=>{})

                    },

                    complete:()=>{

                        this.$toast.clear();

                    }

                })

        },

        init(){

            this.getCustomerList();

        },

        //获取用户列表

        getCustomerList(){

            this.$toast.loading({

                overlay:true,

                duration:0,

                message:"加载中..."

            });

            api.getCustomerList({

                success:(res)=>{

                    if(res.type == 200){

                        this.customerList = res.data;

                        this.pageErr=false;


                    }else{

                        this.$dialog.confirm({

                            title:'获取数据失败',

                            message:res.msg || "获取客户列表失败，请稍后重试",

                            confirmButtonText:'重试',

                            confirmButtonColor:"#00a0e4"

                        }).then(()=>{

                            this.getCustomerList();

                        }).catch(()=>{})

                        this.pageErr=true;

                    }

                    

                },

                error:(err)=>{

                    console.log(err);

                    this.pageErr=true;

                    this.$dialog.confirm({

                        title:'获取数据失败',

                        message: err || "获取客户列表失败，请求异常，请稍后重试",

                        confirmButtonText:'重试',

                        confirmButtonColor:"#00a0e4"

                    }).then(()=>{

                            this.getCustomerList();

                    }).catch(()=>{})

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },

        //删除客户

        deleteCustomer(item){

            this.$dialog.confirm({

                title:'删除客户',

                message:`确定要删除 ${item.name} 吗?\n删除客户后不可恢复`,

                confirmButtonColor:"#00a0e4"

            }).then(()=>{

                this.$toast.loading({

                    duration:0,

                    message:"正在删除...",

                    overlay:true

                });

                api.removeCustomer({

                    options:{id:item.id},

                    success:(res)=>{

                        if(res.type == 200){

                            this.$notify({ type: 'success', message: '客户删除成功' });

                            let index,_index;

                            //返回的数据应当为被删除的元素id,删除对应元素

                            for(let i = 0;i<this.customerList.length;i++){

                                for(let j = 0;j<this.customerList[i].list.length;j++){

                                    if(this.customerList[i].list[j].id == res.data){

                                        index = i;

                                        _index = j;

                                        break;

                                    }

                                }

                                if(index || index  == 0) break;

                            }

              

                            if(index || index == 0){

                                this.customerList[index].list.splice(_index,1);

                                if(!this.checkCustomerListNone()){

                                    this.onSearch(this.keyword);

                                }else{

                                    this.getCustomerList();

                                }

                

                            }


                            

                    
                            
                        

                        }else{

                            this.$dialog.alert({

                                title:'删除失败',

                                message:res.msg || '客户删除失败，请稍后重试',

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{});

                            
                        }

                    },


                    error:(err)=>{

                        console.log(err);

                        this.$dialog.alert({

                            title:'删除失败',

                            message:'客户删除失败,发生异常，请稍后重试',

                            confirmButtonColor:"#00a0e4"

                        }).then(()=>{});

                    },

                    complete: ()=> {

                        this.$toast.clear();
                        
                    }

                })


            }).catch(()=>{})

        },

        //检查是否为空

        checkCustomerListNone(){

            let check = true;

            this.customerList.forEach(el=>{

                if(el.list.length>0){

                    check = false;

                }

            })
            

            return check;

        }

    },


    created(){

        this.$nextTick(()=>{

            //检查一下使用权限

            let check = this.permissionCustomerListCheck();

            if(check){

                this.init();

            }


        })

    }

}
</script>

<style lang="less">

 .customer-list-wrapper{

     height:100%;

    .customer-list-cover{

        height:100%;

        .search-cover{

            width: 65%;

            margin: 0 auto;

 

        }

        .bd{

            flex:1;

            height:0;

            position: relative;

            overflow: hidden;

            overflow-y:auto;

            background: #f6f6f6;

            padding-bottom:50px;

            .van-empty{

                background: #fff;

            }

            .van-button{

                height:100%;

            }

            .van-index-anchor{

                padding: 0 30px;

            }

            .van-swipe-cell__wrapper{

                background: #fff;

                .tel{

                    width: 60px;

                    display: inline-flex;

                    align-items: center;

                    justify-content: center;

                    background: #09dd70;

                    color:#fff;

                    height:100%;

                    vertical-align: top;

                    font-size:15px;

                    line-height: 1.2;

                }

            }


            .van-cell{

                align-items: center;

                padding: 5px 0 5px 16px;

                &:after{

                    right:0;

                    left:55px;

                }

                .avatar{

                    width: 38px;

                    height:38px;

                }

                .van-cell__title{

                    padding-left:15px;

                    padding-bottom:0;

                }

            }
               
        }
        

    }
    

 }

</style>
