export default{


    data(){

        return{

            
            wxCodeText:'获取验证码',

            //倒计时长

            wxCountdownNumber:60,

            //倒计时储存id

            wx_inv_id:null,

            //微信获取验证码锁

            wxLock:false,

        }

    },


    methods: {
        
        /**
         * 
         * 微信验证码获取倒计时
         * 
         */

        wxCountdown(){

            let countdown = this.wxCountdownNumber;

            this.wxLock = true;

            this.inv_id = global.setInterval(()=>{

                this.wxCodeText= countdown--;

                if(this.wxCodeText == 0){

                  this.wxCountdownEnd();

                }

            },1000)

        },

        
        //微信验证码倒计时结束

        wxCountdownEnd(){

            if(this.inv_id){

                global.clearInterval(this.inv_id);

            }

            this.wxCodeText = '重新获取';

            this.wxLock=false;

        },

        //获取微信验证码

        getWxCode(api,options){
            api.getCode({
                options,
                success: (res) => {
                  if (res.type == 200) {
                    this.verifyCodeShow = false;
                    this.$toast({
                      message: "已成功发送验证码",
                      position: "bottom",
                    });
                    this.wxCountdown();
                    this.isInit = true;
                    this.status = "checkCode";
                    //聚焦
                    this.$nextTick(() => {
                      this.$refs.codeFocus.focus();
                    });
                  } else {
                    this.$toast(res.msg);
                    this.$refs.verifyCode?.getVerifyCode &&
                      this.$refs.verifyCode?.getVerifyCode();
                  }
                },
                error: (err) => {
                  console.log(err);
                  this.$refs.verifyCode?.getVerifyCode &&
                    this.$refs.verifyCode?.getVerifyCode();
                },
                complete: () => {
                  this.codeLoading = false;
                },
              });

        },

    },



}