<template>
   <div class="change-passwrod-wrapper">
       <div class="hd"><van-nav-bar left-arrow safe-area-inset-top title="修改密码" @click-left="back"></van-nav-bar></div>
       <div class="bd">
           <van-form @submit="onSubmit" label-width="5em" validate-first ref="passwordChangeForm">
            <van-field
                v-model="oldPassword"
                label="原密码"
                right-icon="eye"
                name="oldPssword"
                :type="oldShow ? '' :'password'"
                placeholder="原密码"
                :rules="[{ required: true, message: '请填写原密码' }]"
                :class="{show:oldShow}"
                @click-right-icon="oldShow=!oldShow"
            />
            <van-field
                v-model="password"
                :type="newShow ? '' : 'password'"
                name="password"
                label="新密码"
                right-icon="eye"
                placeholder="新密码，最少六位"
                :class="{show:newShow}"
                @click-right-icon="newShow=!newShow"
                :rules="passwordRules"
                @input="checkPasswordMore"
                ref="password"
            />  
            <van-field
                v-model="passwordMore"
                :type="confirmShow ? '' : 'password'"
                name="passwordMore"
                label="确认密码"
                right-icon="eye"
                placeholder="确认密码"
                :class="{show:confirmShow}"
                @click-right-icon="confirmShow=!confirmShow"
                :rules="[
                {required: true, message: '请再一次输入新密码' },
                {validator:passwordMoreCheck,message:'两次密码输入不一致'}
                ]"
                ref="passwordmore"
            />
            <div class="align-right"><router-link :to="{name:'retrievePassword'}" class="retrieve-Password">忘记原密码?</router-link></div>
            <div class="tips">为了保证您的账号安全，请自行设置密码，采用字母+数字等组合方式，密码长度应不小于8位，且不使用纯数字或纯字母,以及不能含有空格。</div>
            <van-button round block type="info" native-type="submit" :disabled="!btnDisabled">提交</van-button>
            </van-form>
       </div>
   </div>
</template>

<script>
import api from '@/api'
import backMixin from "@/mixins/back";
import {isEqual} from "@/utils";
export default {

    mixins:[backMixin],

    data(){

        return{

            oldPassword:'',

            password:'',

            passwordMore:'',

            oldShow:false,

            newShow:false,

            confirmShow:false

        }

    },

    computed:{

        btnDisabled(){

            return this.oldPassword && this.password && this.passwordMore;

        },

        passwordRules(){

            return [
                    {required: true, message: '请填写最少六个字符的密码' },
                {validator:this.passwordLengthCheck,message:'密码长度最少为六个字符'}
            ]

        }

    },



    methods:{

        onSubmit(){

             //判断是否满足六位

            if(isEqual(this.password,this.oldPassword)){

                this.$notify({type:"danger",message:"新旧密码不能相同"});

                this.$refs.password.focus();

                return;

            }

            //判断是否满足六位

            if(this.password.length <6){

                this.$notify({type:"danger",message:"新密码最少六位"});

                this.$refs.password.focus();

                return;

            }

            //判断两次密码是否输入一致

            if(!isEqual(this.password,this.passwordMore)){

                this.$notify({type:"danger",message:"两次密码输入不一致"});

                this.$refs.passwordmore.focus();

                return false;

            }

            
            this.$toast.loading({

                message:'提交中...',

                duration:0

            })

            api.changePassword({

                options:{

                    oldPassword:this.oldPassword,

                    password:this.password,

                    passwordMore:this.passwordMore

                },

                success:(res)=>{

                    if(res.type == 200){
                        
                        this.$dialog.alert({

                            title:'密码修改成功',

                            message:'密码修改成功，将会在下一次登录启用',

                            confirmButtonText:"好的",

                            confirmButtonColor:'#00a0e4'

                        }).then(()=>{

                            this.$router.back();

                        })


                    }else{

                        this.$dialog.alert({

                            title:'修改失败',

                            message:res.msg || '密码修改失败，请稍后重试'

                        }).then(()=>{})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'修改失败',

                        message:'密码修改失败，提交异常，请稍后重试'

                    }).then(()=>{})


                },

                complete:()=>{

                    this.$toast.clear();

                }

            })


        },

        passwordLengthCheck(val){

            //强制转化为字符串

            val = val+'';

            if(val.length <6){

                return false;

            }else{

                return true;

            }

            

        },

        
        passwordMoreCheck(val){

            if(val != this.password){

                return false;

            }

            return true

        },

        checkPasswordMore(){

            if(!this.passwordMore) return;

            this.$refs.passwordChangeForm.validate('passwordMore').then(()=>{}).catch(()=>{});

        }



    }

}
</script>

<style lang="less">
.change-passwrod-wrapper{

    background:#f6f6f6;

    height:100%;

    .van-button{

        margin:20px auto 0;

        width: 80%;

    }

    .tips{

        padding: 15px;

        line-height:20px;

        color:#666666;

    }

}
</style>
