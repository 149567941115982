<template>
   <div class="my-wrapper">
       <div class="my-cover flex-box-column">
           <div class="my-wrapper-hd border_bottom">我的</div>
            <div class="my-wrapper-bd">
                <section class="my-wrapper-bd__column user-info" @click="goLink({name:'userInfo'})">
                        <div class="user-info-l">
                                <div class="user-info__avater" :style="avatar" :class="{avatar:!avatar,man:sex==1,woman:sex==2,secret:!avatar && sex == 0}"></div>
                                <div class="user-info__content">
                                    <p class="user-info_name">{{loginReady ? name :'登录/注册'}}</p>
                                    <p class="user-info_phone" v-if="loginReady">{{phone}}</p>
                                </div>
                        </div>
                        <div class="user-info-r"><van-icon name="arrow" class="user-info____more"/></div>
                </section>
                    <section class="my-wrapper-bd__column toolbar">
                        <van-grid :border="false" :column-num="2" :gutter="15">
                            <van-grid-item :to="{name:'favorite'}">
                                <div class="item">
                                    <van-icon name="star-o" class="icon"/>
                                    <p class="type">我的收藏</p>
                                </div>
                            </van-grid-item>    
                            <van-grid-item :to="{name:'calculator'}">
                                <div class="item">
                                    <van-icon name="system-calculator" class="icon"/>
                                    <p class="type">房贷计算器</p>
                                </div>
                            </van-grid-item>           
                        </van-grid>
                </section>
                <section class="my-wrapper-bd__column system-settings">
                    <van-cell  title="我的对接渠道人" center is-link @click="channelListOpen" v-if="role == 'YUEWUYUAN' || role=='DIANZHANG'"/>
                    <van-cell  title="报备休假" center class="value-center center-end" v-if="role == 'AUDITOR'">
                        <template>
                            <van-switch 
                            v-model="isVacation" 
                            :loading="switchLoading" 
                            :disabled="switchLoading" 
                            @change="setVacation" 
                            :size="switchSize"
                            :active-value="1"
                            :inactive-value="0"/>
                        </template>
                    </van-cell>
                    <van-cell  title="我的分享" center is-link :to="{name:'myShare'}"/>
                    <!-- <van-cell  title="修改密码" center is-link :to="{name:'changePassword'}"/> -->
                    <van-cell  v-if="loginReady" :title="bindWx ? '解绑微信' : '绑定微信'" @click="getWxOpenId" label-class="error" center is-link :label="bindWx ? '' :'您还没有绑定微信，无法接收到微信通知'"/>
                    <van-cell  v-if="loginReady" title="更新权限" center is-link @click="updatePermission"/>
                    <van-cell title="帮助与反馈" center is-link :to="{name:'help'}"/>
                    <van-cell :title="'关于'+mainTitle" center is-link :to="{name:'aboutUs'}"/>
                </section>       
                <section class="my-wrapper-bd__column system-settings logout" v-if="loginReady">
                        <van-cell title="退出登录" center is-link @click="logOut" :title-style="{color:'#fd7171'}"/>
                </section>
            </div>
       </div>
       <!-- 展示渠道列表 -->
       <van-overlay :show="channelListShow" @click="channelListShow = false" z-index="2000">
           <div class="channel-list-cover" @click.stop>
                <div class="channel-list-title van-hairline--bottom">渠道对接人列表</div>
                <div class="channel-list-cell" v-if="channelList.length>0">
                    <van-cell 
                    :title="item.name" 
                    v-for="(item,index) in channelList" 
                    :key="index" 
                    :value="'负责区域\n'+item.manageArea" 
                    :label="item.mobile" 
                    is-link 
                    :url="'tel:'+item.mobile" /> 
                </div>
                <div class="channel-list-none" v-else>
                    <p>暂无渠道对接人，请联系客服</p>
                </div>
           </div>
       </van-overlay>
   </div>
</template>

<script>

import api from '@/api';

import {pxToVw} from "@/utils";

export default {

    data(){

        return{

            channelListShow:false,

            //是否开始请求

            switchLoading:false

        }

    },

    computed:{

        name(){

            return this.$store.state.userInfo.name;

        },   
        
        phone(){

            return this.$store.state.userInfo.phone;

        },     
        
        sex(){

            return this.$store.state.userInfo.sex;

        },   

        avatar(){

            return this.$store.state.userInfo.avatar ? {backgroundImage:`url(${this.$getImageUrl(this.$store.state.userInfo.avatar)})`} : '';

        },

        bindWx(){

            return this.$store.state.userInfo.bindWx;

        },

        loginReady(){

            return this.$store.state.userInfo.ready;

        },

        //渠道负责人列表

        channelList(){

            return this.$store.state.userInfo?.channelList || [];

        },

        //是否为分销商或经纪人

        role(){

            return this.$store.state.userInfo?.distributorStaffType;

        },

        //switch字体大小

        switchSize(){

            return pxToVw(20)+'vw';

        },

        //报备专员休假状态

        isVacation:{
            get(){
                return this.$store.state.userInfo?.vacation;
            },
            set(val){
                this.$store.commit('setUserData',{
                    key:'vacation',
                    value:val,
                    save:true
                });
            }
        },

        mainTitle(){
            return this.$store.state.userInfo?.company?.title;
        }

    },

    methods:{

        goLink(path){

            this.$router.push(path);

        },

        //登出

        logOut(){

            this.$dialog.confirm({

                title:'退出登录',

                message:'是否要退出登录状态',

                confirmButtonColor:"#00a0e4"

            }).then(()=>{

                this.$toast.loading({

                    message:'正在登出...',

                    duration:0,

                    forbidClick:true

                })

                api.logOut({

                    success:(res)=>{

                        if(res.type == 200){

                            this.$toast("退出成功");

                            this.$store.commit('resetUserData');

                            this.$nextTick(()=>{

                                this.$router.replace({name:"login"});

                            })

                        }else{

                            this.$dialog.alert({

                                title:'退出失败',

                                message:res.msg || "退出失败，请稍后重试"

                            }).then(()=>{})

                        }

                    },

                    eror:(err)=>{

                        console.log(err);

                        this.$dialog.alert({

                            title:'退出失败',

                            message:"退出失败，请求异常，请稍后重试"

                        }).then(()=>{})

                    },

                    complete: ()=> {

                        this.$toast.clear();
                        
                    }

                })

                
            }).catch(()=>{})

        },
        
        //更新权限

        updatePermission(){

            this.$toast.loading({

                message:'正在更新...',

                forbidClick:true

            })

            api.getInitConfig({
    
                    success:(res)=>{
        
                        if(res.type == 200){
        
                              this.$dialog.alert({

                                title:'更新权限成功',

                                message:'更新权限成功',

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{})


                            this.$store.commit('initData',res.data);
                    
                        }else{
        
                            this.$dialog.alert({

                                title:'更新权限失败',

                                message:'更新权限失败，请稍后重试'

                            }).then(()=>{})
        
                        }
        
                    },
        
                    error:(err)=>{
        
                        console.log(err);

                        this.$dialog.alert({

                            title:'更新权限失败',

                            message:'更新权限失败，请稍后重试'

                        }).then(()=>{})
        
                    },

                    complete:()=>{

                        this.$toast.clear();

                    }
        
                })
        

        },

        //获取微信Openid

        getWxOpenId(){

            if(this.bindWx){

                this.$dialog.confirm({

                    title:"解绑微信",

                    message:'是否要解绑微信'

                }).then(()=>{

                    this.closeWx();

                }).catch(()=>{})

            }else{

                this.$toast.loading({

                    message:"正在绑定...",

                    duration:0,

                    forbidClick: true,

                });


                api.bindWx({

                    options:{

                        url:'https://'+window.location.host+this.$route.path+'?active=my'

                    },

                    success:(res)=>{

                        if(res.type == 200){
                            window.location.href=res.data;

                        }else{

                            this.$dialog.alert({

                                title:'绑定失败',

                                message:res.msg || "微信绑定失败，请求异常，请稍后重试"

                            }).then(()=>{})


                        }

                    },

                    error:(err)=>{

                        console.log(err);

                        this.$dialog.alert({

                            title:'绑定失败',

                            message:"微信绑定失败，请求异常，请稍后重试"

                        }).then(()=>{})

                    },

                    complete:()=>{

                        this.$toast.clear();

                    }

                })

            }

        },

        //绑定微信
        
        bindOpenId(code){

            this.$toast.loading({

                message:"正在绑定...",

                duration:0,

                forbidClick: true,

            });

            api.bindWxCode({

                options:{

                    code

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.$dialog.alert({

                            title:'绑定成功',

                            message:"微信绑定成功，请关注公众号，以便能够正常的接收到通知",

                            confirmButtonColor:"#00a0e4"

                        })

                        this.$store.commit('setUserData',{

                            key:'bindWx',

                            value:1,

                            save:true

                        });

                    }else{

                        this.$dialog.alert({

                            title:'绑定失败',

                            message:res.msg || "微信绑定失败，请求异常，请稍后重试"

                        }).then(()=>{});

                    }


                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'绑定失败',

                        message:"微信绑定失败，请求异常，请稍后重试"

                    }).then(()=>{})

                },

                complete:()=>{

                    this.$toast.clear();

                    this.$nextTick(()=>{

                        this.$router.replace({name:"home",params:{active:'my'}});

                    })


                }

            })

        },

        //微信解绑

        closeWx(){

            this.$toast.loading({

                message:'正在解除...',

                duration:0,

                forbidClick: true

            });

            api.closeWx({

                success:(res)=>{

                    if(res.type == 200){

                        this.$toast('微信解绑成功');

                        this.$store.commit('setUserData',{

                            key:'bindWx',

                            value:0,

                            save:true

                        })

                    }else{

                        this.$dialog.alert({

                            title:'解绑失败',

                            message:res.msg || '微信解绑失败，请稍后重试'

                        }).then(()=>{})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'解绑失败',

                        message:'微信解绑失败，请稍后重试'

                    }).then(()=>{})


                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },

        //打开对接渠道列表
        
        channelListOpen(){

            this.channelListShow = true;

        },

        //切换值班状态

        setVacation(val){

            this.switchLoading = true;

            api.updateUserData({

                options:{

                    vacation:val

                },

                success:(res)=>{

                    if(res.type == '200'){

                        let vacation = parseInt(res.data.vacation);

                        this.$toast(`切换${vacation ? '休假' : '值班'}状态成功`);

                        this.$store.commit('setUserData',{

                            key:'vacation',

                            value:vacation,

                            save:true

                        });

                    }else{
                        
                        this.$toast(`切换${val ? '休假' :'值班'}状态失败，请稍后重试`);

                        this.$store.commit('setUserData',{

                            key:'vacation',

                            value:val ? 0 : 1,

                            save:true

                        });

                    }

                },

                error:(err)=>{

                    this.$toast(`切换${val ? '休假' : '值班'}状态失败，请稍后重试`+err);

                    this.$store.commit('setUserData',{

                        key:'vacation',

                        value:val ? 0 : 1,

                        save:true

                    });

                },
                complete:()=>{

                    this.switchLoading=false;

                }

            })

        }

    },


    activated(){

        //获取参数
        if(this.$route.query.code && this.$route.query.active =='my'){
            this.bindOpenId(this.$route.query.code);
        }

    }

}
</script>

<style lang="less">
.my-wrapper{

    background:#f6f6f6;

    height:100%;

    .my-cover{

        height:100%;

    }

    .channel-list-cover{

        width: 90%;

        position: absolute;

        left:50%;

        top:40%;

        transform: translate(-50%,-50%);

        padding: 10px 0;

        border-radius:5px;

        background: #fff;

        .van-cell__value{

            width: 0;

            span{

                white-space: pre-wrap;

            }

        }

        .channel-list-title{

            font-size:16px;

            padding: 0 16px 16px;

        }

        .channel-list-none{

            padding: 16px;

            color:#888888;

            font-size:14px;

        }
        
    }

    .my-wrapper-hd{

        height:50px;

        line-height:50px;

        text-align: center;

        font-size:16px;

        background:#fff;

    }

    .my-wrapper-bd{

        flex:1 1 auto;

        overflow: hidden;

        overflow-y:auto;

        .user-info{

            display: flex;

            justify-content: space-between;

            align-items: center;

            padding: 20px 10px 20px 16px;

            .user-info-l{

                display: flex;

                align-items: center;

                .user-info__avater{

                    width: 55px;

                    height:55px;

                    background-size:cover;

                    border-radius:50%;

                    background-repeat: no-repeat;

                    background-position: center center;

                }

                .user-info__content{

                    padding-left:12px;

                    .user-info_name{

                        font-size:16px;

                    }

                    .user-info_phone{

                        font-size:14px;

                        padding-top:10px;

                    }

                }

            }

            .user-info-r .user-info____more{

                font-size:26px;

                color:#a5a5a5;

            }

        }

        .toolbar{

            padding: 10px 0;

            background:#fff;

            .van-grid-item{

                height:60px;

                .van-grid-item__content{

                    background:#fcf8f0;

                    border-radius: 5px;

                    .item{

                        width: 100%;

                        height:100%;

                        display: flex;

                        align-items: center;

                        justify-content: center;

                        color:#aea790;

                        .type{

                            font-size:15px;

                        }

                        .icon{

                            font-size:22px;

                            margin-right:5px;

                            margin-top:-2px;

                        }

                    }

                }

                &:nth-child(2) .van-grid-item__content{

                    background: #f6f5fb;

                    .item{

                        color:#9ba5ad;

                    }

                }


                &:active{
                    
                    opacity: .6;

                }

            }

        }

        .system-settings{

            padding: 0;

            margin-top:15px;

            .van-cell{

                line-height:30px;

                .van-cell__title, .van-cell__value{

                    font-size:14px;

                }

            }

            &.logout{

                margin-bottom:15px;

            }

        }

    }


}
</style>
