import {isObjectNone} from "@/utils";
export default{

    data(){

        return{

            //是否进入多选模式

            multiple:false,

            //多选储存对象

            selectedItemObj:{},

        }
        
    },
    
    methods: {

        //进入多选

        multipleStart(){

            this.$toast({

                message:'已进入多选模式\n右侧按钮可预览导出内容',

                position:'bottom'

            });

            this.multiple=true;

        },

        //退出多选

        multipleEnd(){

            this.$toast({

                message:'已退出多选模式',

                position:'bottom'

            });

            this.selectedItemObj={};

            this.multiple=false;

        },
        

        //多选完毕

        multipleSelectComplete(){

            //转化为数组，并去掉空值

            let selected = [];
            
            if(isObjectNone(this.selectedItemObj)){

                this.$toast('至少选择一个报备单');

                return;


            }

            //创建一个复制对象,基本的复制内容
            
            Object.keys(this.selectedItemObj).forEach((item,)=>{

                selected.push(this.selectedItemObj[item]);
                
            });
            //设置复制对象,一级分销以及二级分销公司名称固定使用第一个元素的 

            this.$dialog.confirm({

                title:'批量导出',

                message:`确定要导出选定的 ${selected.length} 个报备单的数据吗？\n如果选定了多个不同的二级分销公司订单，\n二级分销公司名称将默认以其中一个订单为准，当前选定的二级分销公司名称为:\n ${selected[0].distributorName || '暂无'}`,

                confirmButtonText:"导出",

                confirmButtonColor:'#00a0e4'
            }).then(()=>{

                this.$copyText(this.copyText).then(()=>{
                
                    this.$toast({
    
                        message:'报备格式复制成功',
    
                        position:"bottom"
    
                    });
        
                    },()=>{
                    
                    this.$toast({
    
                        message:'复制失败，如果多次复制失败，请联系管理员',
    
                        position:'bottom'
    
                    });
    
                })

                this.multipleEnd();

            }).catch(()=>{})


        },


        //处理当前选择的数据

        setCopyData(selectData){

            let selected = [];

            Object.keys(selectData).forEach((item,)=>{

                if(selectData[item]){

                    selected.push(selectData[item]);

                }
                
            });
 

            if(selected.length>0){

                let obj = {};

                let topItem = selected[0];

                //添加一级分销

                obj.topDistribution = this.companyName;

                //添加二级分销，默认使用第一个元素

                obj.distributorName = topItem.distributorName || '暂无';

                //添加带看员工名称

                obj.dkStaffName = topItem.dkStaffName || topItem.subdistributorStaffName;

                //添加员工电话

                obj.dkStaffMobiles = topItem.dkStaffMobiles || topItem.subdistributorStaffMobile;

                //循环添加客户信息

                obj.customerList = selected.map(el=>{

                    return el.customerInfo;

                });

                //到访人数

                obj.visitorNum = obj.customerList.length;

                //带看项目

                obj.houseName = topItem.houseName;

                //到访时间

                obj.estimatedVisitTime = topItem.estimatedVisitTime;

                //指定接待

                obj.reception = topItem.reception;

                return obj;

            }else{

                return null;
                
            }


        },

        //将选择的内容转化为copy文本

        copyDataToText(selectedItemObj){

            //首先获取到选择的数据

            let copyData = this.setCopyData(selectedItemObj);

            if(!copyData) return '';


            let copyText = `一级公司名称:${copyData.topDistribution}\n二级公司全称:${copyData.distributorName}\n带看员工:${copyData.dkStaffName}\n员工电话:${copyData.dkStaffMobiles}\n${copyData.customerList.map((el,index)=>{return `${index == 0 ? '' : '\n'}客户姓名:${el.name}\n客户电话:${el.phone}\n客户性别:${el.sex == 1 ?'男':'女'}`}).join('')}\n到访人数:${copyData.visitorNum}\n带看项目:${copyData.houseName}\n到访时间:${copyData.estimatedVisitTime}\n指定接待:${copyData.reception || '暂无'}`;

            return copyText;


        },

        multipleCtnCheck(){

            this.$dialog.alert({

                title:'复制内容预览',

                message: this.copyText || '暂无内容',

                theme: 'round-button',

                messageAlign:'left',

                confirmButtonColor:'#00a0e4'

            }).then(() => {});

        }
        
      
    },

    computed:{

        copyText(){

            return this.copyDataToText(this.selectedItemObj);


        }


    },
    





}