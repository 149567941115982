<template>
    <div class="user-info-wrapper">
       <div class="user-info-cover flex-box-column">
           <div class="hd">
               <van-nav-bar left-arrow safe-area-inset-top :title="title" @click-left="back" :right-text="isError ? '' :'保存'" @click-right="saveUserData">
                </van-nav-bar>
           </div>
           <div class="bd" v-if="!isError">
               <div class="form-item" v-if="this.$route.params.type == 'name'">
                    <van-field v-model="name" placeholder="请输入姓名" type="textarea" rows="3" autosize/>
               </div>
               <div class="form-item" v-if="this.$route.params.type == 'phone'">
                    <van-field v-model="phone" @keydown.native.enter="saveUserData" placeholder="请输入手机号码" type="tel" rows="5" autosize/>
               </div>  
               <div class="form-item" v-if="this.$route.params.type == 'resume'">
                    <van-field v-model="resume" placeholder="请输入个人简介" type="textarea" rows="5" autosize/>
               </div>
           </div>
           <div v-else>
               <van-empty image="error" description="不存在对应的修改项" />
           </div>
       </div>
   </div>
</template>

<script>
import api from "@/api";
import backMixin from "@/mixins/back";
import {isPhone} from "@/utils"
export default {

    mixins:[backMixin],

    data(){

        return {

            value:'',

            infoTypeList:{

                name:'姓名',

                phone:'手机号码',

                resume:"个人简介"

            },

            name:'',

            phone:'',

            resume:''

        }

    },

    computed:{

        title(){

            return  this.$route.params.type 

            ? this.infoTypeList[this.$route.params.type]

            ? this.infoTypeList[this.$route.params.type]

            :'无对应项'

            :'无对应项'

        },

        isError(){

            return !this.infoTypeList[this.$route.params.type];

        }

    },

    methods:{

        saveUserData(){

            const type = this.$route.params.type;

            if(this.isError) return;

            if(type == 'name' && !this.name){

                this.$notify({ type: 'warning', message: '姓名不能为空' });

                return;

            }

            if(type == 'phone' && !this.phone){

                this.$notify({ type: 'warning', message: '手机号码不能为空' });

                return;

            }        
            
            if(type == 'phone' && !isPhone(this.phone)){

                this.$notify({ type: 'warning', message: '手机号码格式不正确' });

                return;

            }

            if(this[this.$route.params.type] == this.$store.state.userInfo[this.$route.params.type]){

                this.$router.back();

                return;

            }

            
            this.$toast.loading({

                message:'提交中...',

                duration:0

            });


            api.updateUserData({

                options:{

                    [this.$route.params.type] : this[this.$route.params.type]

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.$store.commit('setUserData',{

                            key:this.$route.params.type,

                            value:res.data[this.$route.params.type],
                            
                            save:true

                        });

                        if(this.$route.params.type == 'phone'){

                            this.$dialog.alert({

                                title:'修改手机号码成功',

                                message:"修改成功"

                            }).then(()=>{

                                this.$router.back();

                            })

                        }else{

                            this.$nextTick(()=>{

                                this.$router.back();

                            })

                        }



                    }else{

                        this.$dialog.alert({

                            title:'修改失败',

                            message:res.msg || '修改失败，请稍后重试'

                        }).then(()=>{})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'修改失败',

                        message:'修改失败，提交异常，请稍后重试'

                    }).then(()=>{})

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        }

    },

    activated(){

        if(this.infoTypeList[this.$route.params.type]){

            if(this.$route.params.type == 'name'){

                this.name = this.$store.state.userInfo.name;

            }else if(this.$route.params.type == 'phone'){

                this.phone = this.$store.state.userInfo.phone;

            }else if(this.$route.params.type == 'resume'){

                this.resume = this.$store.state.userInfo.resume;

            }

        }

    }




}
</script>

<style scoped lang="less">
.bd{

    padding-top:10px;

}
</style>
