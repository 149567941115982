/**
 * 
 * 图片上传的一些方法
 * 
 * 其中success 方法不会添加，因为这个一般需要定制化
 * 
 * 
 *  maxSize 默认为0 如果设置了的话就会启用大小检查
 * 
 * 
 */


export default{

    data(){

        return{

            //文件类型判断

            imageMimeList:['image/jpeg','image/png','image/gif','image/bmp','image/jpg'],

            imageAccept:'.jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PNG,.GIF, .BMP',

            imageUploadApi:"/api/upload",

            imageMaxSize:5


        }

    },


    methods: {
        
        /**
         * 
         * 图片上传前的检查
         * 
         */

        beforeThumbUpload(file){

            if(!file) return false;

            if(file.type && this.imageMimeList.indexOf(file.type) == -1){

                this.$notify({ 
                    type: 'warning',
                    message: `${file.name} 不是图片文件，请确认后重新上传！`,
                 })
     
               return false;
     
            }

            //判断是否超出限制大小

            if(this.imageMaxSize && file.size && file.size/1024 > this.imageMaxSize * 1024){

                this.$notify({

                    type: 'warning',

                    message: `${file.name} 大小超出限制，图片大小不能大于 ${this.imageMaxSize < 1 ? parseInt(this.imageMaxSize * 1000) + 'kb' : this.imageMaxSize + 'MB'}`,


                });
                return false;
                
            }

            return true;

        }

    },


}