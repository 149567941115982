<template>
   <div class="customer-list-wrapper">
       <div class="flex-box-column customer-list-cover">
            <div class="hd">
                 <van-nav-bar left-arrow safe-area-inset-top title="导入带看人" class="search" @click-left="back">
                </van-nav-bar>
                <div class="search-cover">
                    <van-search v-model="keyword" class="sm border" shape="round" placeholder="搜索带看人" @input="onSearch"/>
                </div>
            </div>
            <div class="bd">
                <van-empty image="error" description="获取数据失败,点击重试" @click.native="getStaffList" v-if="pageErr" />
                <van-index-bar :sticky="false" z-index="50">
                    <template v-for="(item,index) in renderList">
                        <div :key="index">
                            <van-index-anchor :index="item.anchor" v-if="item.anchor"/>
                               <van-cell title-class="p-b-10" v-for="(_item,_index) in item.list" :key="_index" @click="selectStaff(_item)" is-link>
                                    <template #icon> 
                                        <div class="avatar secret"></div>
                                    </template> 
                                    <template #title>
                                        <span>{{_item.name}}</span>
                                    </template>  
                                    <template #label>
                                        <span>{{_item.phone}}</span>
                                    </template>
                                    <template #right-icon>
                                        <van-icon name="success" class="selected-icon" v-if="_item.isSelected" color="#00a0e4" />
                                    </template>
                                </van-cell>
                            <van-empty image="search" description="没有对应带看人信息" v-if="item.empty"/>
                        </div> 
                    </template>
                    <van-empty description="没有带看人信息" v-if="staffList.length == 0 && !pageErr"/>
                </van-index-bar>
            </div>
       </div>
   </div>
</template>

<script>
import backMixin from '@/mixins/back';
import api from "@/api";
export default {

    mixins:[backMixin],


    data(){

        return {

            keyword:'',

            searchList:[],

            staffList:[],

            pageErr:false

        }

    },

    computed:{

        renderList(){

            return this.searchList.length>0 ? this.searchList : this.staffList;

        }

    },


    methods:{

        onSearch(keyword){

            let searchList= [{list:[]}];

            if(keyword){

                //搜索用户

                this.staffList.forEach(el=>{

                    el.list && el.list.forEach(_el=>{

                        if(_el.name.indexOf(keyword) !=-1 || _el.phone.indexOf(keyword) != -1){

                            searchList[0].list.push(_el);

                        }

                    })

                });

                if(searchList[0].list.length == 0){

                    searchList[0].empty = true;

                }

            }else{

                searchList = [];

            }

            this.searchList = searchList;
            

        },

        init(){

            this.getStaffList();

        },

        //获取用户列表

        getStaffList(){

            this.$toast.loading({

                overlay:true,

                duration:0,

                message:"加载中..."

            });

            api.getDaikanList({

                success:(res)=>{

                    if(res.type == 200){

                        this.staffList = res.data;

                        if(this.$store.state.record?.dkStaffMobiles){

                            //循环选中

                            for(let j =0;j<this.staffList.length;j++){

                                let check = false;

                                for(let k=0;k<this.staffList[j].list.length;k++){

                                    if(this.staffList[j].list[k].phone == this.$store.state.record.dkStaffMobiles){

                                    this.staffList[j].list[k].isSelected = true;

                                    check = true;

                                    break;

                                }

                                if(check) break;


                                }

                                

                            }

                        }


                        this.pageErr=false;

                    }else{

                        this.$dialog.confirm({

                            title:'获取数据失败',

                            message:res.msg || "获取带看人列表失败，请稍后重试",

                            confirmButtonText:'重试',

                            confirmButtonColor:"#00a0e4"

                        }).then(()=>{

                            this.getStaffList();

                        }).catch(()=>{})

                        this.pageErr=true;

                    }

                    

                },

                error:(err)=>{

                    console.log(err);

                    this.pageErr=true;

                    this.$dialog.confirm({

                        title:'获取数据失败',

                        message:"获取带看人列表失败，请求异常，请稍后重试",

                        confirmButtonText:'重试',

                        confirmButtonColor:"#00a0e4"

                    }).then(()=>{

                            this.getStaffList();

                    }).catch(()=>{})

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },

        //选择用户

        selectStaff(item){

            this.$store.commit('setRecordData',{

                key:"dkStaffName",

                value:item.name

            });
            
            this.$store.commit('setRecordData',{

                key:"dkStaffPhone",

                value:item.phone

            });

            this.$nextTick(()=>{

                this.back();

            })
        },

    },

    created(){

        this.$nextTick(()=>{

            this.init();

        })

    }

}
</script>

<style lang="less">

 .customer-list-wrapper{

     height:100%;

     .van-index-bar__index--active{

         color:#333;

     }

    .customer-list-cover{

                     .select-list{

                padding:5px 20px 0;

                padding-right:0;

                display: flex;

                flex-wrap: wrap;

                background: #fff;

                .item{

                    background: #f3f9ff;

                    color:#00a0e4;
                    
                    width: 30%;

                    padding: 3px 10px;

                    margin-right:8px;

                    margin-bottom:8px;

                    border-radius: 22px;

                    font-size:12px;

                    display: flex;

                    align-items: center;

                    justify-content: space-between;

                    border: 1px solid #00a0e4;

                    span:first-child{

                        max-width: 80%;

                        overflow: hidden;

                        white-space: nowrap;

                        text-overflow: ellipsis;

                    }

                    span:nth-child(2){

                        height:100%;

                        i{

                            font-size:14px;

                            position: relative;

                            top:1px;

                        }

                    }

                }
                
            }


        height:100%;

        .search-cover{

            width: 65%;

            margin: 0 auto;

 

        }

        .bd{

            flex:1;

            height:0;

            position: relative;

            overflow: hidden;

            overflow-y:auto;

            background: #f6f6f6;

            padding-bottom:50px;

            .selected-icon{

                padding-right:50px;

                font-size:18px;

            }


            .van-empty{

                background: #fff;

            }

            .van-button{

                height:100%;

            }

            .van-index-anchor{

                padding: 0 30px;

            }

            .van-swipe-cell__wrapper{

                background: #fff;

                .tel{

                    width: 60px;

                    display: inline-flex;

                    align-items: center;

                    justify-content: center;

                    background: #09dd70;

                    color:#fff;

                    height:100%;

                    vertical-align: top;

                    font-size:15px;

                    line-height: 1.2;

                }

            }


            .van-cell{

                align-items: center;

                padding: 5px 0 5px 16px;

                z-index:20;

                &:after{

                    right:0;

                    left:55px;

                }

                .avatar{

                    width: 38px;

                    height:38px;

                }

                .van-cell__title{

                    padding-left:15px;

                    padding-bottom:0;

                }

            }
               
        }
        

    }
    

 }

</style>
