<template>
   <div class="select-house-wrapper">
       <div class="flex-box-column select-house-cover">
           <div class="hd">
                <van-nav-bar left-arrow title="选择楼盘" right-text="确认" :border="false" safe-area-inset-top @click-right="houseSelectOver" @click-left="back"></van-nav-bar>
            </div>
            <div class="bd flex-box-column">
                <div class="search">
                    <div class="search-cover">
                        <van-search v-model="keyword" placeholder="请输入楼盘关键字" @input="searchHouseThrottle" @search="searchHouse"/>
                    </div>
                    <div class="area-cover">
                        <span class="van-hairline--left hairline-icon"></span>
                        <span @click="showArea=true">{{area.cityname || '区域'}}</span>
                    </div>
                </div>
                <div class="select-list">
                    <div class="item" v-for="(item,index) in selected" :key="index">
                        <span>{{item.name}}</span>
                        <span @click="close(index,item)"><van-icon name="cross"/></span>
                    </div>  
                </div>
                <div class="scroll-view">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            :error.sync="error"
                            error-text="请求失败，点击重新加载"
                            :offset="100"
                            @load="onLoad"
                        >
                        <van-cell v-for="item in houseList" @click="houseSelected(item)" :key="item.id" :title="item.name">
                            <template #right-icon>
                                <van-icon name="success" class="selected-icon" v-if="item.isSelected" color="#00a0e4" />
                            </template>
                        </van-cell>
                    </van-list>
                </div>
            </div>
       </div>
       <!-- 地区选择弹窗 -->
        <van-popup v-model="showArea" position="bottom">
            <van-picker 
            show-toolbar 
            ref="cityPicker"
            title="区域选择" 
            :columns="cityColumns" 
            @cancel="showArea=false"
            @confirm="selectedCity"/>
        </van-popup> 
   </div>   
</template>

<script>
/**
 * 
 * 选择楼盘专用页面
 * 
 */

import api from "@/api";
import {findIndex,cloneDeep} from "@/utils";
import backMixin from "@/mixins/back";
import throttleMixin from "@/mixins/throttle";
import houseTipsObj from "@/config/houseTips";
export default {
    mixins:[backMixin,throttleMixin],
    data(){
        return{
            keyword:'',
            finished:false,
            loading:false,
            error:false,
            houseList:[],
            selected:[],
            //当前页数
            page:1,
            //一页显示的数量
            size:20,
            //是否显示选择区域弹窗
            showArea:false,
            cityColumns:[],
            area:{}
        }
    },

    computed:{

        selectHouse(){

            return this.$store.state.record.selectHouse;

        }

    },

    activated(){

        if(this.$store.state.record.selectHouse.length>0){

            this.selected = cloneDeep(this.$store.state.record.selectHouse);

        }

    },

    methods:{

        onLoad(){

            this.getHouseData();

        },

        searchHouse(){

            this.page=1;

            this.$toast.loading({

                message: '正在加载...',

                forbidClick: true,

                duration:0

            });

            //重置一下

            api.getHouseList({

                options:{

                    page:this.page,

                    pageSize:this.size,

                    keyword:this.keyword,

                    areaId:this.area?.locationId

                },

                success:(res)=>{

                    if(res.type == 200){

                        //首先判断是否存在勾选状态的楼盘

                        res.data.list.forEach(el=>{
                            let index = findIndex(this.selected,(item)=>{
                                return item.id == el.id;
                            });
                            if(index == -1){
                                el.isSelected = false;
                            }else{
                                el.isSelected = true;
                            }
                        });
                        this.houseList=res.data.list;
                        this.error=false;
                        let last = this.$listLastCheck(res.data.list,this.size);
                        if(!last){
                            this.page++;
                        }
                        this.finished=last;

                    }else{

                        this.error = true;

                    }


                },

                error:(err)=>{

                    console.log(err);

                    this.error=true;

                },

                complete: ()=> {

                    this.loading=false;

                    this.$toast.clear();
                    
                }


            })



        },

        //动态自动发起搜索

        searchHouseThrottle(){

            this.throttle({

                key:"search_house",

                time:400,

                callback:this.searchHouse
            })

        },

        //楼盘数据添加入列表

        houseSelected(item){

            //检查部是否存在

            let index = findIndex(this.selected,item);

            if(index!=-1){

                this.selected.splice(index,1);

                this.$set(item,'isSelected',false);

            }else{

                //检查一下楼盘个数。不能超过5个;

                if(this.selected.length == 10){

                    this.$toast('一次报备的楼盘不能超过10个');

                    return;

                };

                //获取提示内容

                const houseTips = houseTipsObj.joinTips(item);

                
                if(houseTips.status){

                    this.$dialog.confirm({

                        title:houseTips.title,

                        message:houseTips.message,

                        confirmButtonColor:'#00a0e4',

                        confirmButtonText:'确认添加',

                        messageAlign:"left"

                        
                    }).then(()=>{

                        this.$set(item,'isSelected',true);

                        this.selected.push(item);

                    }).catch(()=>{});

                }else{

                    this.$set(item,'isSelected',true);

                    this.selected.push(item);

                }

              

            }


        },


        //楼盘数据删除

        close(index,item){

            this.selected.splice(index,1);

            //清除选中状态

            let _index = findIndex(this.houseList,(_item)=>{

                return _item.id == item.id

            });

            this.$set(this.houseList[_index],'isSelected',false);

        },

        //获取楼盘数据

        getHouseData(){

            api.getHouseList({

                options:{

                    page:this.page,

                    pageSize:this.size,
                    
                    areaId:this.area?.locationId,

                    keyword:this.keyword

                },

                success:(res)=>{

                    if(res.type == 200){
                        //首先判断是否存在勾选状态的楼盘
                        res.data.list.forEach(el=>{
                            let index = findIndex(this.selected,(item)=>{
                                return item.id == el.id;
                            });
                            if(index == -1){
                                el.isSelected = false;
                            }else{
                                el.isSelected = true;
                            }
                        }) 
                        this.houseList.push(...res.data.list);
                        this.error=false;
                        let last = this.$listLastCheck(res.data.list,this.size);
                        if(!last){
                            this.page++;
                        }
                        this.finished=last;
                    }else{
                        this.error = true;
                    }
                },
                error:(err)=>{
                    console.log(err);
                    this.error=true;
                },
                complete: ()=> {
                    this.loading=false;
                }
            })
        },

        //楼盘选择

        houseSelectOver(){

            //设置楼盘参数

            this.$store.commit('setRecordData',{

                key:'selectHouse',
                    
                value:this.selected,

             });
                

            this.$router.back();

        },

        //选择城市

        selectedCity(val,index){
            //根据index找到对应的数据
            let city = this.cityColumns[index[0]]?.children[index[1]];

            this.$set(this.area,'locationId',city?.value || '');

            this.$set(this.area,'cityname',city?.text || '');

            this.showArea = false;

            console.log(city)

            this.$nextTick(()=>{

                this.searchHouse();

            })

        },

        //将城市数据转化为对应的picker数据
        
        setCityColumns(city){
            let _city = cloneDeep(city);
            _city.forEach((el)=>{
                el.value = el.id;
                el.children && el.children.forEach((_el)=>{
                    this.$delete(_el,'children');
                });
                if(el.children){
                    el.children = el.children.filter(_el=>{
                        return _el.text != '不限'
                    })
                }
            });
            console.log(city)
            _city.unshift({text:'不限',value:'',children:[{text:'不限',value:''}]});
            return _city;
        },

    },
    created(){
        this.cityColumns = this.setCityColumns(this.$store.state.userInfo?.cityList || []);
    }
    

}
</script>

<style lang="less">
.select-house-wrapper{

    height:100%;

    .select-house-cover{

        height:100%;

    }

    .bd{
        
        flex:1;

        height:0;

        overflow: hidden;

        .van-cell{

            overflow: visible;

        }

        .search{

            position: relative;

            .search-cover{

                .van-cell{

                    padding-right:90px;

                }

            }

            .area-cover{

                position: absolute;

                right:12px;

                top:50%;

                font-size:14px;

                height:34px;

                line-height:34px;

                transform: translateY(-50%);

                padding: 0 10px;


                color:#00a0e4;
                

                .hairline-icon{

                    position: absolute;

                    height:20px;

                    left:0;

                    top:50%;

                    transform: translateY(-50%);

                    &::after{

                        border-color:#cccccc;

                    }

                }

            }
            
        }

              .select-list{

                padding:5px 20px 0;

                padding-right:0;

                display: flex;

                flex-wrap: wrap;

                .item{

                    background: #f3f9ff;

                    color:#00a0e4;
                    
                    width: 30%;

                    padding: 3px 10px;

                    margin-right:8px;

                    margin-bottom:8px;

                    border-radius: 22px;

                    font-size:12px;

                    display: flex;

                    align-items: center;

                    justify-content: space-between;

                    border: 1px solid #00a0e4;

                    span:first-child{

                        max-width: 80%;

                        overflow: hidden;

                        white-space: nowrap;

                        text-overflow: ellipsis;

                    }

                    span:nth-child(2){

                        height:100%;

                        i{

                            font-size:14px;

                            position: relative;

                            top:1px;

                        }

                    }

                }
                
            }

        
        .scroll-view{

            flex:1;

            height:0;

            overflow: hidden;

            overflow-y:auto;

            .selected-icon{

                padding-right:15px;

                font-size:18px;

            }

      
        }

    }

}
</style>
