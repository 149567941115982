<template>
  <div class="change-passwrod-wrapper">
    <div class="flex-box-column">
      <div class="hd">
        <van-nav-bar
          left-arrow
          safe-area-inset-top
          title="分销商注册"
          @click-left="back"
        ></van-nav-bar>
      </div>
      <div class="bd f-scroll-view">
        <van-form
          @submit="onSubmit"
          label-width="5em"
          validate-first
          ref="registerForm"
        >
          <van-field
            required
            readonly
            :is-link="!companyLicenseName"
            :value="companyLicenseName"
            label="公司名称"
            placeholder="请选择公司"
            @click="selectCompany"
            :rules="[{ required: true, message: '请选择公司' }]"
          />
          <van-field
            required
            v-model="name"
            label="分销商简称"
            placeholder="请填写公司简称"
            clearable
            :rules="[{ required: true, message: '请填写公司简称' }]"
            ref="name"
          />
          <van-field
            required
            :readonly="companyReadonly"
            v-model="creditNo"
            label="信用代码"
            :placeholder="companyReadonly ? '根据公司名称获取' :'请输入公司信用代码' "
            :rules="[{ required: true, message: '请输入公司信用代码' }]"
          />
          <van-field
            required
            v-model="managerName"
            label="负责人"
            clearable
            placeholder="请填写负责人名称"
            :rules="[{ required: true, message: '请填写负责人名称' }]"
            ref="managerName"
          />
          <van-field
            required
            v-model="managerPhone"
            label="负责人号码"
            clearable
            placeholder="请填写负责人手机号码"
            type="tel"
            :rules="[
              { required: true, message: '请填写负责人手机号码' },
              { validator, message: '手机号码格式错误' },
            ]"
            ref="managerPhone"
          />
          <van-field
            required
            v-model="address"
            label="公司地址"
            placeholder="请填写公司地址"
            type="textarea"
            rows="1"
            autosize
            clearable
            :rules="[{ required: true, message: '请填写公司地址' }]"
            ref="address"
          />
          <van-field name="uploader" :label="'营业执照'" required>
            <template #input>
              <div class="order-images-cover">
                <div class="order-images-show">
                  <div
                    class="order-image"
                    @click="
                      fktImagePreviewShowOpen({ item: businessLicensePic })
                    "
                    v-if="businessLicensePic"
                  >
                    <img :src="businessLicensePic|$imageUrl" alt="" />
                    <div class="close-btn">
                      <van-icon
                        name="cross"
                        class="delete-icon"
                        @click.stop="deleteImage"
                      />
                    </div>
                  </div>
                  <van-uploader
                    v-else
                    accept="image/*"
                    :after-read="afterRead"
                  />
                </div>
              </div>
            </template>
          </van-field>
          <van-field
            readonly
            :is-link="!channel.id"
            :value="channel.name"
            label="渠道对接人"
            placeholder="点击选择渠道对接人"
            @click="selectChannel"
          >
            <template #right-icon v-if="channel.name">
              <van-icon name="cross" @click.stop="removeChannel" />
            </template>
          </van-field>
          <van-field
            required
            readonly
            is-link
            :value="locationText"
            label="所属区域"
            placeholder="点击选择所属区域"
            @click="selectLocation"
            :rules="[{ required: true, message: '请选择所属区域' }]"
          >
          </van-field>
          <van-popup v-model="locationShow" round position="bottom">
            <van-cascader
              v-model="locationId"
              title="请选择所在地区"
              :options="cityList"
              @close="locationShow = false"
              @finish="onFinish"
            />
          </van-popup>
          <van-field
            required
            v-model="password"
            name="password"
            :type="passwordShow ? '' : 'password'"
            label="密码"
            placeholder="请填写您的密码,不少于六位"
            right-icon="eye"
            :class="{ show: passwordShow }"
            :rules="passwordRules"
            @click-right-icon="passwordShow = !passwordShow"
            ref="password"
            @input="checkPasswordMore"
          />
          <van-field
            required
            v-model="passwordmore"
            name="passwordMore"
            :type="passwordMoreShow ? '' : 'password'"
            label="确认密码"
            placeholder="请再一次填写密码"
            :rules="[
              { required: true, message: '请再一次填写密码' },
              { validator: passwordMoreCheck, message: '两次密码输入不一致' },
            ]"
            right-icon="eye"
            :class="{ show: passwordMoreShow }"
            @click-right-icon="passwordMoreShow = !passwordMoreShow"
            ref="password"
          />
          <div class="tips">
            为了保证您的账号安全，请自行设置密码，采用字母+数字等组合方式，密码长度应不小于8位，且不使用纯数字或纯字母,以及不能含有空格。
          </div>
          <van-button
            round
            block
            type="info"
            native-type="submit"
            :disabled="!btnDisabled"
            >提交</van-button
          >
        </van-form>
        <div
          class="tips text-center main-color"
          v-if="name == 'debug'"
          @click="debugShow"
        >
          debug
        </div>
        <div class="tips text-center main-color" v-if="debug">
          {{ businessLicensePic }}
        </div>
      </div>
    </div>
    <!-- 图片预览 -->
    <van-image-preview
      v-model="fkyImagePreviewShow"
      :images="fkyImagePreviewList"
      :start-position="fkyImagePreviewIndex"
      :max-zoom="3"
    ></van-image-preview>
  </div>
</template>

<script>
import api from "@/api";
import backMixin from "@/mixins/back";
import imageUploadMixin from "@/mixins/imageUpload";
import imagePreviewMixin from "@/mixins/imagePreview";
import { isPhone } from "@/utils";
export default {
  mixins: [backMixin, imageUploadMixin, imagePreviewMixin],

  data() {
    return {

      //负责人

      managerName: "",

      //负责人手机号码

      managerPhone: "",

      //公司地址

      address: "",

      //密码

      password: "",

      //再一次填写密码

      passwordmore: "",

      //营业执照

      businessLicensePic: "",

      //password显隐

      passwordShow: false,

      //再一次填写密码显示

      passwordMoreShow: false,

      debug: false,

      locationShow: false,

      locationId: "",

      locationText: "",

      cityList: [],

      oldName:'',
      
      creditNo:'',
      creditNoError:false,
      name:'',
      companyReadonly:true
    };
  },

  computed: {
    btnDisabled() {
      return (
        this.name &&
        this.companyLicenseName &&
        this.managerName &&
        this.managerPhone &&
        this.address &&
        this.businessLicensePic &&
        this.locationId && 
        this.creditNo
      );
    },

    passwordRules() {
      return [
        { required: true, message: "请填写最少六个字符的密码" },
        {
          validator: this.passwordLengthCheck,
          message: "密码长度最少为六个字符",
        },
      ];
    },

    channel() {
      return this.$store.state.commonForm?.registerChannelData || {};
    },
    companyLicenseName() {
      return this.$store.state.commonForm?.registerCompanyData || '';
    },
  },

  methods: {
    validator(val) {
      return isPhone(val);
    },

    onSubmit() {
      if (this.password && this.password.length < 6) {
        this.$notify({ type: "danger", message: "密码最少六位" });

        this.$refs.password.focus();

        return;
      }

      this.$toast.loading({
        message: "提交中...",

        duration: 0,
      });

      api.distributorRegister({
        options: {
          password: this.password,

          companyLicenseName: this.companyLicenseName,

          name:this.name,

          managerName: this.managerName,

          managerPhone: this.managerPhone,

          address: this.address,

          channelId: this.channel?.id,

          channelName: this.channel?.name,

          employeeId: this.channel?.employeeId,

          businessLicensePic: this.businessLicensePic,

          locationId: this.locationId,
          creditNo:this.creditNo
        },

        success: (res) => {
          if (res.type == 200) {
            this.$dialog
              .alert({
                title: "提交成功",

                message:
                  "提交成功，请等待客服审核，审核成功后会对您进行短信通知，届时方可登录，请耐心等待。",

                confirmButtonText: "好的",

                confirmButtonColor: "#00a0e4",
              })
              .then(() => {
                this.$router.back();
              });
          } else {
            this.$dialog
              .alert({
                title: "注册失败",

                message: res.msg || "注册失败，请稍后重试",
              })
              .then(() => {});
          }
        },

        error: (err) => {
          console.log(err);

          this.$dialog
            .alert({
              title: "注册失败",

              message: "注册修改失败，提交异常，请稍后重试",
            })
            .then(() => {});
        },

        complete: () => {
          this.$toast.clear();
        },
      });
    },

    passwordLengthCheck(val) {
      //强制转化为字符串

      val = val + "";

      if (val.length < 6) {
        return false;
      } else {
        return true;
      }
    },

    passwordMoreCheck(val) {
      if (val != this.password) {
        return false;
      }

      return true;
    },

    checkPasswordMore() {
      if (!this.passwordmore) return;

      this.$refs.registerForm
        .validate("passwordMore")
        .then(() => {})
        .catch(() => {});
    },

    //跳转选择渠道人员

    selectChannel() {
      this.$router.push({ name: "register/select/channel" });
    },

    //跳转选择公司
    selectCompany(){
      this.$router.push({ name: "register/select/company" });
    },

    //获取公司信用代码
    getCompanyCode(){
      if(!this.companyLicenseName) return;
      this.$toast.loading({
        message: "正在获取信用代码...",
        duration: 0,
      });

      api.getCompanyCode({
        options:{
          name:this.companyLicenseName
        },
        success:(res)=>{
          this.$toast.clear();
          if(res.type == 200){
            this.creditNo = res.data.creditNo;
            this.creditNoError = false;
          }else{
            this.$toast({
              message: res.message || "信用代码获取失败,请手动填写",
              duration: 2000
            });
            this.creditNo = '';
            this.creditNoError = false;
            this.companyReadonly = false;
          }
        },
        error:(err)=>{
          this.creditNo = '';
          this.creditNoError = false;
          this.$toast.clear();
          this.$toast({
            message: "信用代码获取失败,请手动填写",
            duration: 2000
          });
          this.companyReadonly = false;
        }
      })
    },
    //选择人员
    selectLocation() {
      this.locationShow = true;
    },

    onFinish({ selectedOptions }) {
      this.locationShow = false;
      this.locationId = selectedOptions[selectedOptions.length - 1].value;
      this.locationText = selectedOptions
        .map((el) => {
          return el.text;
        })
        .join(" / ");
    },

    //营业执照上传

    afterRead(file) {
      const _check = this.beforeThumbUpload(file.file);

      if (!_check) return;

      const formData = new FormData();

      formData.append("file", file.file);

      api.externalUpload({
        options: formData,

        success: (res) => {
          if (res.type == 200) {
              console.log(res)
            this.businessLicensePic = res.data.url;
          } else {
            this.$notify({
              type: "danger",
              message: res.msg || "图片上传失败，请稍后重试",
            });
          }
        },

        error: (err) => {
          console.log(err);

          this.$notify({ type: "danger", message: "图片上传失败，请检查网络" });
        },
      });
    },

    deleteImage() {
      this.businessLicensePic = "";
    },

    debugShow() {
      this.debug = true;
    },

    removeChannel() {
      this.channel.name = "";

      this.channel.id = "";
    },
    cityListInit() {
      api.getCityList({
        success: (res) => {
          let cityList = JSON.stringify(res.data);
          cityList = cityList.replace(/\"children\"\:\[\]/g, '"children":null');
          this.cityList = JSON.parse(cityList);
        },
      });
    },
  },

  mounted() {
    this.cityListInit();
  },
  watch:{
    companyLicenseName(val,oldVal){
      this.oldName = oldVal;
    }
  },
  beforeDestroy() {
    //销毁vuex

    this.$store.commit("setCommonFormData", {
      key: "registerChannelData",

      value: null,
    });
    this.$store.commit("setCommonFormData", {
      key: "registerCompanyData",

      value: null,
    });
  },
  activated(){
    //当存在名称，且两次名称不一致或为空的时候拉取数据
    if(this.companyLicenseName && ((this.oldName != this.companyLicenseName) || !this.creditNo)){
      this.oldName = this.companyLicenseName;
      //获取信用代码
      this.getCompanyCode();
    }
  }
};
</script>

<style lang="less">
.change-passwrod-wrapper {
  background: #f6f6f6;

  height: 100%;

  .flex-box-column {
    height: 100%;

    .bd {
      padding-bottom: 20px;
    }
  }

  .van-button {
    margin: 20px auto 0;
    width: 80%;
  }

  .tips {
    padding: 15px;

    line-height: 20px;

    color: #666666;
  }

  .order-images-cover {
    background: #fff;

    .order-images-show {
      display: flex;

      flex-wrap: wrap;
    }

    .order-image {
      width: 80px;

      height: 80px;

      margin-bottom: 10px;

      margin-right: 10px;

      box-shadow: 0 5px 16px rgba(0, 0, 0, 0.08);

      position: relative;

      overflow: hidden;

      img {
        width: 100%;

        height: 100%;
      }

      .close-btn {
        position: absolute;
        width: 80px;
        height: 40px;
        right: -60px;
        top: -25px;
        background: rgba(0, 0, 0, 0.6);
        text-align: center;
        padding-top: 20px;
        transform: translateY(10px) translateX(-26px) rotate(45deg);

        color: #fff;

        .delete-icon {
          font-size: 15px;

          text-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
        }

        &:active .delete-icon {
          opacity: 0.6;
        }
      }
    }

    .van-uploader__upload {
      width: 80px;

      height: 80px;

      .van-uploader__upload-icon {
        font-size: 24px;
      }
    }
  }
}
</style>
