<template>
   <div class="user-agreement-wrapper">
       <div class="user-agreement-cover flex-box-column">
           <div class="hd">
               <van-nav-bar left-arrow safe-area-inset-top title="用户协议及隐私政策" @click-left="back"></van-nav-bar>
           </div>
           <div class="bd">
               <div class="user-agreement__content">
                   <p class="indent">{{mainTitle}}应用软件的所有权和运营权归{{companyName}}（以下简称{{mainTitle}}）所有。用户在使用{{mainTitle}}的服务之前，应当仔细阅读本协议，并同意遵守本协议后方可成为{{mainTitle}}用户，一旦用户注册成功，则用户与{{mainTitle}}之间自动形成协议关系，用户应当受本协议的约束。</p><br>
                   <p class="indent">本协议的修改权归{{companyName}}所有，对用户不承担通知义务。用户应当随时关注本协议的修改，并决定是否继续使用{{mainTitle}}提供的各项服务。</p><br><br>
                   <p>一、服务条款</p><br>
                   <p class="indent">{{mainTitle}}服务的具体内容由{{mainTitle}}根据实际情况提供。除非本使用协议另有其它明确规定，增加或强化目前本服务的任何新功能，包括所推出的新产品，均受到本使用协议之规范。您了解并同意，本服务仅依其当前所呈现的状况提供，对于任何用户信息或个人化设定之时效、删除、传递错误、未予储存或其它任何问题，{{mainTitle}}均不承担任何责任。{{mainTitle}}保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。</p><br><br>
                   <p>二、法律约束</p><br>
                   <p class="indent">您同意遵守中华人民共和国相关法律法规的所有规定，并对以任何方式使用您的账号及密码使用本服务的任何行为及其结果承担全部责任。如您的行为违反国家法律和法规的任何规定，有可能构成犯罪的，将被追究刑事责任，并由您承担全部法律责任。如果{{mainTitle}}有理由认为您的任何行为，包括但不限于您的任何言论和其它行为违反或可能违反国家法律和法规的任何规定，{{mainTitle}}可在任何时候不经任何事先通知终止向您提供服务。</p><br><br>
                   <p>三、用户注册</p><br>
                   <p class="indent">为了能使用本服务，您同意以下事项：依本服务注册提示填写正确的手机号码和密码。若您提供任何违法、不道德或{{mainTitle}}认为不适合在{{mainTitle}}上展示的资料；或者{{mainTitle}}有理由怀疑你的资料属于程序或恶意操作，{{mainTitle}}有权暂停或终止您的账号，并拒绝您于现在和未来使用本服务之全部或任何部分。{{mainTitle}}无须对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、正确性、完整性、适用性及是否为最新资料的责任。</p><br><br>
                   <p>四、隐私保护</p><br>
                   <p>（1）事先获得用户的明确授权；<br>（2）根据有关的法律法规要求；<br>（3）按照相关政府主管部门的要求；<br>（4）该第三方同意承担与{{mainTitle}}同等的保护用户隐私的责任；</p><br><br>
                   <p>五、协议条款</p><br>
                   <p class="indent">本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。本协议解释权及修订权归{{companyName}}所有。</p>

               </div>
           </div>
       </div>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
export default {

    mixins:[backMixin],

    computed:{

        mainTitle(){

            return this.$store.state?.userInfo.company?.title;

        },

        companyName(){

            return this.$store.state?.userInfo.company?.description;

        }

    }

}
</script>

<style scoped lang="less">
.user-agreement-wrapper{

    height:100%;

    .user-agreement-cover{

        height:100%;

    }

    .bd{

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y: auto;

        padding: 15px 15px 30px;

        .user-agreement__content{

            font-size:14px;

            line-height:24px;

            color:#555;

            p.indent{

                text-indent:2em;

            }

        }

    }

}
</style>
