import vue from "vue";

const state = {}

const mutations = {

    //设置数据

    setCommonFormData(state,{key,value}){
        
        vue.set(state,key,value);

    },


    /**
     * 
     * 添加数组，删除数组
     * 
     * 
     * @param key 需要更改变量名
     * 
     * @param isDelete true 为删除，否则为添加
     * 
     * @param item 需要添加的元素
     * 
     * @param index 需要删除的下标
     * 
     */

    commonFormArrayChange(state,{key,isDelete,item,index}){

        if(isDelete){

            state[key].splice(index,1); 

        }else{

            state[key].push(item);

        }

    },
}

const actions = {

  

}

const getters = {

  getCommonFormState: (state) => (key) => {
    return state[key];
  }

}


 export default {

    state:state,
    mutations:mutations,
    actions:actions,
    getters:getters

 }