<template>
   <div class="house-record-wrapper">
       <div class="fixed-cover">
                  <div class="house-record-cover flex-box-column">
           <div class="hd">
            <van-nav-bar left-arrow title="报备客户" :border="false" safe-area-inset-top @click-left="back" @click-right="submitForm">
                <template #right>
                    <div>
                        <van-button type="info" size="small" color="#00a0e4">提交报备</van-button>
                    </div>
                </template>
            </van-nav-bar>
           </div>
            <div class="bd">
                <div class="conent-recognition-warpper">
                    <van-collapse v-model="activeNames">
                        <van-collapse-item title="智能识别" name="1">
                            <template #title>
                            <span>智能识别</span>
                            <span class="sub-title">（粘贴客户信息智能填写）</span>
                            </template>
                            <div class="paste-cover">
                                <textarea placeholder="请将要识别的内容粘贴在此处，只要包含模板格式中的客户信息、带看人、到访人数，即可识别，如识别不成功，请参考模板，注意：识别成功后会覆盖原来添加的客户信息" v-model="demoMessage"></textarea>
                            </div>
                            <div class="recognition-btns">
                                <div class="btn recognition-demo" @click="recognitionDemoOpen">参考模板</div>
                                <div>
                                    <div class="recognition-empty btn" @click="demoMessageClear">清空</div>
                                    <div class="recognition-start btn" @click="getDemoMessage">智能识别</div>
                                </div>
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <van-cell-group>
                    <van-cell size="large">
                        <template #right-icon>
                            <div class="main-color" @click="openSelectHouse">添加楼盘</div>
                        </template>
                         <template #title>
                            <div>
                                楼盘<span class="ipt-icon">*</span>
                                <span class="sm-des">（不超过10个）</span>
                            </div>
                        </template>
                    </van-cell>
                    <van-cell label-class="main-color sm-font label-pre" v-for="(item,index) in selectHouse" :key="item.id" :title="item.name" :label="item.incentiveFull" size="large" class="house-cell">
                        <template #right-icon>
                            <van-icon name="delete-o" @click.native="closeHouse(index,item.name)" class="delete-icon" color="#0076e4"/>
                        </template>
                        <template #label>
                            <span class="house-tips" v-if="item.houseRecordTips">{{item.houseRecordTips}}</span>
                        </template>
                    </van-cell>
                </van-cell-group>       
                <van-cell-group>
                    <van-cell is-link size="large" class="date-cover" @click="openPicker" :value="visitingTime || '请选择时间'" >
                         <template #title>
                            <div>
                                到访时间<span class="ipt-icon">*</span>
                            </div>
                        </template>
                    </van-cell>
                    <van-cell size="large">
                        <template #extra>
                            <input type="number" v-model="visitorNum" class="p-num" placeholder="请填写"><span>人</span>
                        </template>
                        <template #title>
                            <div>
                                到访人数<span class="ipt-icon">*</span>
                            </div>
                        </template>
                    </van-cell>       
                </van-cell-group>
                <van-cell-group>
                    <van-cell size="large" :border="false">
                         <template #title>
                            <span class="custom-title">客户</span>
                            <span class="ipt-icon">*</span>
                            <span class="sm-des">（不超过5个,已添加 {{customerList.length}} 个）</span>
                        </template>
                         <template #right-icon>
                            <div class="main-color" @click="openSelectCustomer">导入客户</div>
                        </template>
                    </van-cell>
                    <div class="clinet-list">
                        <van-cell label-class="main-color sm-font label-pre" v-for="(item,index) in customerList" :key="index" :title="item.name" @click="clientAddDialogOpen($event,item)" size="large" class="house-cell client-cell customer-cell">
                            <template #icon> 
                                <div class="avatar customer-sex-icon" :class="{man:item.sex==1,woman:item.sex==2}"></div>
                            </template>  
                            <template #label>
                                <p><span class="f-12 main-color" v-if="uploadCardlist.length>0 && (!item.customerPic || item.customerPic.length == 0)">点我上传相关资质</span></p>
                            </template>
                            <template #right-icon>
                                <div>
                                    <p class="f-15 p-r-t-2 user-phone">{{item.phone}}</p>
                                </div>
                            </template>
                            <template #extra>
                                <van-icon name="minus" @click.native.stop="closeClient(index)" class="delete-icon bold" color="#fff"/>
                            </template>
                        </van-cell>
                    </div>
                    <div class="user-add-btn" v-if="customerList.length<5">
                        <van-button round type="info" color="#00a0e4" block @click="clientAddDialogOpen">添加客户</van-button>
                    </div>
                     <p class="main-color text-center p-b-10"
                        :class="{'p-t-10':customerList.length>=5}"
                         v-if="uploadCardlist.length>0">* <span v-for="(item,index) in uploadCardlist" :key="index">{{item.name}} </span><br v-if="uploadCardlist.length>1">必须要上传相关资质证件<br>(如身份证、户口本等)，请点击客户头像上传</p>
                </van-cell-group> 
                <van-cell-group>
                    <van-cell title="带看人信息" size="large">
                        <div class="main-color" @click="openSelectStaff">选择带看人</div>
                    </van-cell> 
                    <van-cell title="带看人名称" size="large" label="默认使用报备人姓名">
                        <template #extra>
                            <input v-model="dkStaffName" class="p-normal" placeholder="请填写">
                        </template>
                    </van-cell>  
                    <van-cell title="带看人手机号码" size="large" label="默认使用报备人手机号码">
                        <template #extra>
                            <input v-model="dkStaffPhone" class="p-normal" placeholder="请填写" type="tel">
                        </template>
                    </van-cell>  
                </van-cell-group>
                <van-cell-group :border="false">
                   <van-field
                    v-model="reception"
                    rows="4"
                    label-class="hide"
                    :border="false"
                    type="textarea"
                    placeholder="指定接待请写在此处,多楼盘报备的情况下，请根据楼盘对应接待人，以便客服能够区分,例：xxxx:xxxx"
                    />
                </van-cell-group>                   
            </div>
            <!-- 时间选择弹窗 -->
            <van-popup v-model="showPicker" safe-area-inset-bottom
            close-on-popstate position="bottom">
                <van-datetime-picker
                v-model="currentDate"
                type="datetime"
                title="选择到访时间"
                :min-date="minDate"
                :max-date="maxDate"
                @cancel="closePicker"
                @confirm="selectTime"
                :formatter="formatter"
                />
            </van-popup>
            <!-- 客户添加弹窗 -->
            <van-dialog v-model="clientAddDialogShow" show-cancel-button  :before-close="addClient" @closed="resetForm" confirmButtonColor="#00a0e4">
                <div class="client-add-wrapper">
                    <div class="client-add-cover">
                        <div class="client-add-item">
                            <div class="client-name van-hairline--bottom">
                                <van-field
                                v-model="userForm.name"
                                center
                                clearable
                                placeholder="请输入客户姓名"
                                ref="clientName"
                                @blur="changeUserName"
                                >
                                <template #label>
                                    <div class="client-add-label avatar" :class="{woman:userForm.sex==2,man:userForm.sex==1,secret:userForm.sex == 0}"></div>
                                </template>
                                <template #button>
                                    <div class="sex-select" :class="{'border-blink':addBlink}">
                                        <div class="sex-select-btn" @click="sexChange(1)" :class="{active:userForm.sex==1}">男士</div>
                                        <div class="sex-select-btn" @click="sexChange(2)" :class="{active:userForm.sex==2}">女士</div>
                                    </div>
                                </template>
                                </van-field>
                            </div>
                        </div>
                        <div class="client-add-item selected">
                            <div class="client-phone van-hairline--bottom">
                                <van-field
                                v-model="userForm.phone"
                                center
                                clearable
                                :placeholder="telPlaceholder"
                                ref="clientPhone"
                                type="tel"
                                >
                                <template #label>
                                    <div class="tel-area-select">
                                        <div class="client-add-label border-all radius phone">
                                        <van-icon name="phone-o" />
                                        </div>
                                        <div class="area-selected" @click="telTypeShow = true">{{telTypeSelected.text}}<van-icon name="arrow-down" /></div>
                                    </div>
                                </template>
                                </van-field>
                            </div>
                        </div>
                        <div class="client-add-item" v-if="uploadCardlist.length>0">
                            <div class="client-upload van-hairline--bottom">
                                <p class="title">相关资质证明：</p>
                                <div class="upload-cover">
                                      <div class="order-images-cover">
                                      <div class="order-images-show">
                                                <div class="order-image" v-for="(item,index) in userForm.customerPic" :key="index" @click="fktImagePreviewShowOpen({index,list:userForm.customerPic})">
                                                    <img :src="item | $imageUrl" alt="">
                                                    <div class="close-btn"><van-icon name="cross" class="delete-icon" @click.stop="deleteImage(index)"/></div>
                                                </div>
                                                <van-uploader 
                                                accept="image/*"
                                                :after-read="afterRead"/>
                                            </div>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="notes">用户号码会根据开发商规则隐藏</div>
                    <div class="notes s" v-if="telTypeSelected.value == 1">选择<span class="important p-lr-5">其他</span>时将不进行号码校验,请保证填写的号码的正确性，如果有区号请自行添加。</div>
                </div>
            </van-dialog>
       </div>
       </div>
       <!-- 号码格式选择 -->
       <van-popup v-model="telTypeShow" round position="bottom">
        <van-picker
            show-toolbar
            title="选择号码地区"
            :columns="telTypeColumns"
            @cancel="telTypeShow = false"
            @confirm="telTypeConfirm"
            :default-index="telTypeIndex"
        />
        </van-popup>
        <!-- 图片预览 -->
        <van-image-preview 
        v-model="fkyImagePreviewShow" 
        :images="fkyImagePreviewList" 
        :start-position="fkyImagePreviewIndex"
        :max-zoom="3"></van-image-preview>
   </div>
</template>

<script>
import { mapState } from 'vuex';
import backMixin from "@/mixins/back";
import {isPhone,findIndex,cloneDeep,keepNumber,replaceBlank,isArray} from "@/utils";
import imagePreviewMixin from "@/mixins/imagePreview";
import api from "@/api";
import houseTipsObj from "@/config/houseTips";
import imageUpload from "@/mixins/imageUpload";
import dataStorage from "@/utils/dataStorage";
import {Decrypt} from "@/utils/crypto";

export default {

    mixins:[backMixin,imageUpload,imagePreviewMixin],

    data(){

        return {

            minDate: new Date(2020, 0, 1),

            maxDate: new Date(2025, 10, 1),
            
            currentDate: new Date(),

            showPicker:false,

            activeNames:[],

            //用户添加弹窗显示

            clientAddDialogShow:false,

            demoMessage:'',

            userForm:{

                name:'',

                sex:0,

                phone:'',

                customerPic:[]


            },

            //显示号码地区选择

            telTypeShow:false,

            //号码选择列表

            telTypeColumns:[
                {text:'大陆',value:0}
                // {text:'其他',value:1}
            ],

            telTypeSelected:{text:'大陆',value:0},

            //用户性别聚焦提示

            addBlink:false,

            //用户性别提示节流记录

            addBlinkInvId:''

        }

    },

    computed:{
        
        ...mapState({

            visitingTime:state => state.record.visitingTime,

            customerList:state => state.record.customerList

        }),

        selectHouse(){

            return this.selectHouseHandle(this.$store.state.record.selectHouse);

        },

        //上传证件楼盘列表

        uploadCardlist(){

            return this.getisNeedIdCardHouse(this.$store.state.record.selectHouse);

        },

        //默认选择号码类型index

        telTypeIndex(){

            return this.telTypeSelected.value;

        },

        //客户号码提示

        telPlaceholder(){

            return this.telTypeSelected.value == 0 ? '请输入客户手机号码' : '请输入客户号码'

        },

        //到访人数

        visitorNum:{

            get(){

                return this.$store.state.record.visitorNum;

            },

            set(val){


                this.$store.commit('setRecordData',{key:'visitorNum',value:val});

            }

        }, 

        //带看人
        
        dkStaffName:{

            get(){

                return this.$store.state.record.dkStaffName;

            },

            set(val){


                this.$store.commit('setRecordData',{key:'dkStaffName',value:val});

            }

        },

        //带看人联系方式

        dkStaffPhone:{

            get(){

                return this.$store.state.record.dkStaffPhone;

            },

            set(val){


                this.$store.commit('setRecordData',{key:'dkStaffPhone',value:val});

            }

        },    
        
        reception:{

            get(){

                return this.$store.state.record.reception;

            },

            set(val){

                this.$store.commit('setRecordData',{key:'reception',value:val});

            }

        },

        houseId(){

            return this.$route.params?.id || '';

        }

    },

    methods:{

        //选择号码地区

        telTypeConfirm(value){

            this.telTypeSelected = value;

            this.telTypeShow=false;

        },


        //打开时间区间选择

        openPicker(){

            this.showPicker=true;

        },

        closePicker(){

            this.showPicker=false;

        },

        selectTime(val){

            let year = val.getFullYear();
            let month = val.getMonth() + 1;
            let day = val.getDate();
            let hour = val.getHours();

            let minute = val.getMinutes();

            if (month >= 1 && month <= 9) { month = `0${month}` }

            if (day >= 1 && day <= 9) { day = `0${day}` }

            if (hour >= 0 && hour <= 9) { hour = `0${hour}` }

            if (minute >= 0 && minute <= 9) { minute = `0${minute}` }

            let timeValue = `${year}-${month}-${day} ${hour}:${minute}`;

            this.$store.commit('setRecordData',{key:'visitingTime',value:timeValue});

            this.showPicker=false;

        },

        // 选项格式化函数

        formatter (type, value) {

            if (type === 'year') {

                return `${value}年`

            } else if (type === 'month') {

                return `${value}月`

            } else if (type === 'day') {

                return `${value}日`

            } else if (type === 'hour') {

                return `${value}时`

            } else if (type === 'minute') {

                return `${value}分`

            } else if (type === 'second') {

                return `${value}秒`

            }
            return value
        },

        //打开选择楼盘页面

        openSelectHouse(){

            this.$router.push({path:`/house/record/select/house`});

        },

        //打开选择客户界面

        openSelectCustomer(){

            this.$router.push({path:`/house/record/select/customer`});

        },

        //打开选择带看人界面

        openSelectStaff(){

            this.$router.push({path:`/house/record/select/staff`});

        },

        //打开添加用户界面

        clientAddDialogOpen($event,data){
            
            //修改数据

            if(data){

                let _data =cloneDeep(data);

                this.userForm = _data;

                //判断此用户的手机号码类型

                // if(this.isPhone(_data.phone)){

                //     this.telTypeSelected = {text:'大陆',value:0};

                // }else{

                //     this.telTypeSelected = {text:'其他',value:1}

                // }

                this.telTypeSelected = {text:'大陆',value:0};

            }else{
                
                //如果客户数量超过十个

                if(isArray(this.customerList) && this.customerList.length == 10){

                    this.$dialog.alert({

                        title:'客户数量限制',

                        message:'最多只能同时报备10个客户',

                        confirmButtonColor:'#00a0e4',

                        confirmButtonText:'好的'

                    }).then(()=>{});

                    return;

                }

                this.telTypeSelected = {text:'大陆',value:0};

            }

            this.clientAddDialogShow=true;

        },

        //删除楼盘

        closeHouse(index,name){

            this.$dialog.confirm({
            title: '提示',
            message: `确定要删除 ${name} 吗`,
            })
            .then(() => {
                this.$store.commit('recordArrayChange',{

                    isDelete:true,

                    key:'selectHouse',

                    index:index

                })
            })
            .catch(() => {
                // on cancel
            });

        },

        //删除客户

        closeClient(index){

            this.$store.commit('recordArrayChange',{

                isDelete:true,

                key:'customerList',

                index:index

            })

        },

        //选择性别

        sexChange(type){

            if(!type) return;

            this.userForm.sex = type;

            const name = this.userForm?.name.replace('先生','').replace('女士','');

            if(name && name?.length == 1){

                this.userForm.name = `${name}${this.userForm.sex == 1 ? '先生' : '女士'}`;

            }

        },

        //用户姓名处理

        changeUserName(){

            if(this.userForm?.name && this.userForm.name?.length == 1){

                this.userForm.name += this.userForm.sex == 1 ? '先生' : '女士';

            }

        },
        
        //用户性别提示
        selectUserSexTips(){

            this.addBlink = true;
            
            //节流
            if(this.addBlinkInvId) global.clearTimeout(this.addBlinkInvId);

            this.addBlinkInvId = global.setTimeout(()=>{
                this.addBlink=false;
            },1500)
        },

        //校验手机号码
        isPhone(val){
            let sVal = `${val}`;
            return isPhone(replaceBlank(val)) || (sVal.indexOf('*') > -1 && sVal.length == 11);
        },

        //添加客户
        addClient(action,done){

            if(action == 'confirm'){

                this.$set(this.userForm,'telType',this.telTypeSelected.value);

                //首先检查客户名称是否存在

                if(!this.userForm.name){

                    this.$notify({ type: 'danger', message: '请填写客户姓名' });

                    this.$refs.clientName.focus();

                    done(false);

                    return;

                }

                if(this.userForm.sex == 0){

                    this.$notify({ type: 'danger', message: '请选择客户性别'});

                    this.selectUserSexTips();

                    done(false);

                    return;

                }

                if(!this.userForm.phone){

                    this.$notify({ type: 'danger', message: '请填写客户手机号码' });

                    this.$refs.clientPhone.focus();

                    done(false);

                    return;

                }

                if(this.telTypeSelected.value == 0 && !this.isPhone(this.userForm.phone)){

                    this.$dialog.alert({

                        title:'手机号码格式错误',

                        message: '手机号码格式错误，如果手机号码为中国大陆以外地区，或座机号码，请选择 其他 ，并自行添加区号,如香港为:+852****。，选择其他后将不会验证号码格式，请确保输入的是正确的号码。' 

                    })

                    this.$refs.clientPhone.focus();

                    done(false);

                    return;

                }

                if(!this.userForm.time){

                    //检查是否存在相同号码的客户

                    let _check = findIndex(this.customerList,(el)=>{

                        return el.phone == this.userForm.phone;

                    });

                    if(_check !=-1){

                        this.$notify({ type: 'danger', message: '客户列表里已经存在此手机号码，请更换手机号码'});

                        done(false);

                        return;

                    }


                }

                //添加一个时间戳作为标示,存在则直接修改
                if(this.userForm.time){

                    //获取该用户的index

                    let index = findIndex(this.$store.state.record.customerList,(el)=>{

                        return el.time == this.userForm.time;

                    });

                    //添加选项

                    this.$store.commit('setArrayData',{

                        key:'customerList',

                        index,

                        value:this.userForm

                    });

                }else{

                    let date =new Date();

                    this.userForm.time = date.getTime();

                    //添加选项

                    this.$store.commit('recordArrayChange',{

                        key:'customerList',

                        item:this.userForm

                    });

                }
                

                done(true);

            }else{

                done(true);

            }
            

        },


        //重置表单

        resetForm(){

            this.userForm={

                name:'',

                sex:0,

                phone:'',

                customerPic:[]

            }

        },

        //提交表单

        submitForm(){
            
            //是否选择了楼盘

            if(this.selectHouse.length == 0){

                this.$toast({

                    message:'至少选择一个楼盘',

                    position:'bottom'

                });

                return;

            }

            //是否选择了到访时间

            if(!this.visitingTime){

                this.$toast({

                    message:'请选择到访时间',

                    position:'bottom'

                });

                return;

            }

            //是否填写到访人数

            if(!this.visitorNum){

                 this.$toast({

                    message:'请填写到访人数',

                    position:'bottom'

                });

                return;

            }

            if(this.dkStaffName && !this.dkStaffPhone){

                this.$toast({

                    message:'请填写带看人手机号码',

                    position:'bottom'

                });

                return;

            }

            if(!this.dkStaffName && this.dkStaffPhone){

                this.$toast({

                    message:'请填写带看人姓名',

                    position:'bottom'

                });

                return;

            }
            

            if(this.dkStaffPhone && !isPhone(replaceBlank(this.dkStaffPhone))){

                 this.$toast({

                    message:'带看人手机号码格式错误',

                    position:'bottom'

                });

                return;

            }

            if(parseInt(this.visitorNum) < 1){

                 this.$toast({

                    message:'到访人数最少一人，请修改',

                    position:'bottom'

                });

                return;

            }

            if(this.customerList.length == 0){
                 this.$toast({
                    message:'至少填写一个客户',
                    position:'bottom'
                });
                return;
            }

            if(this.checkCustomSex()){
                return false;
            }

            if(this.uploadCardlist.length>0){
                const _checkList = this.checkCustomerCustomerPicStatus(this.customerList);
                if(_checkList.status){
                    let message = '存在需要资质证明的楼盘,请为以下客户上传资质证明（身份证、户口本等）,\n点击用户头像即可上传：\n\n';
                    _checkList.customerList.forEach(el=>{
                        message+=`${el.name} (${el.phone})\n`;
                    })
                    this.$dialog.alert({
                        title:'缺少资质证明',
                        message,
                        messageAlign:'left',
                        confirmButtonColor:'#00a0e4',
                        confirmButtonText:'知道了'
                    }).then(()=>{})
                    return false;
                }
            }

            const submitToast =  this.$toast.loading({

                message: `正在提交...`,

                forbidClick: true,
                
                duration:0

            });

            const inv_id = global.setTimeout(()=>{

                submitToast.message = '生成报备单过多，\n请稍后...'

            },5000);


            const fromData = {

                visitorNum:this.visitorNum,

                visitingTime:this.visitingTime,

                remark:this.remark,

                dkStaffName:this.dkStaffName,

                dkStaffPhone:this.dkStaffPhone,

                reception:this.reception

            }

            fromData.selectHouse = this.selectHouse.map(el=>{

                return el.id;

            });

            if(this.dkStaffPhone){

                fromData.dkStaffPhone = keepNumber(this.dkStaffPhone);

            }

            fromData.customerList = this.customerList.map(el=>{

                //判断是否不允许上传用户资质

                if(this.uploadCardlist.length == 0 && el?.customerPic &&isArray(el.customerPic)){

                    el.customerPic = [];

                }

                if(!el.telType){

                    return Object.assign(el,{phone:el.phone});

                }else{

                    return el;

                }

            })

            api.submitRecordData({

                options:fromData,

                success:(res)=>{
                    
                    res.msg = res.msg.replace(/\\n/g,'\n');

                    if(res.type == 200){


                        this.$dialog.alert({

                            title: res.msgTitle ||  '提交成功',

                            message:  res.msg || '报备单提交成功',

                            confirmButtonColor:'#00a0e4'

                        }).then(() => {

                            //清除数据

                            this.$store.commit('resetRecordData');

                            this.$router.replace({name:'recordList'});
                      
                        });

                    }else{

                        this.$dialog.alert({

                        title: '提交失败',

                        message:res.msg || '报备数据提交失败，请稍后重试',

                        }).then(() => {});

                    }

                   

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title: '提交异常',

                        message:'报备数据提交失败，请检查网络或稍后重试，'+err,

                    }).then(() => {});

                },

                complete:()=> {

                    global.clearTimeout(inv_id);

                    this.$toast.clear();
                    
                }

            })

        },

        //提交智能识别

        getDemoMessage(){
            if(!this.demoMessage){
                this.$toast({
                    message:'识别内容不能为空',
                    position:'bottom'
                });
                return;
            }
            this.$toast.loading({
                message:'正在识别...',
                duration:0
            });
            let message = this.demoMessage.replace(/\n/g,"<br>");
            api.getDemoMessage({

                options:{
                    
                    message:message

                },

                success:(res)=>{

                    if(res.type == 200){

                        if(res?.data?.customer){

                            res.data.customer.forEach((el,index)=>{

                                let date = new Date();

                                el.time = date.getTime()+index;

                            });

                            this.$store.commit('setRecordData',{

                                key:'customerList',

                                value:res.data.customer

                            });

                        }

                        if(res?.data?.daikan){

                            this.$store.commit('setRecordData',{

                                key:'dkStaffName',

                                value:res.data.daikan?.name

                            });   
                            
                            this.$store.commit('setRecordData',{

                                key:'dkStaffPhone',

                                value:res.data.daikan?.phone

                            });

                        }

                        if(res?.data?.visitorNum){

                            this.$store.commit('setRecordData',{

                                key:'visitorNum',

                                value:res.data?.visitorNum

                            });

                        }

                        
                        this.$toast(`识别成功，已为您导入 ${res.data.customer.length} 个客户`);
                        

                    }else{

                        this.$dialog.alert({

                            title:'识别失败',

                            message:res.msg || '智能识别失败，请稍后重试'

                        })

                    }

                },

                error:(err)=>{

                    console.log(err);

                     this.$dialog.alert({

                        title:'识别失败',

                        message:`智能识别失败，请稍后重试，error:${err}`

                    })


                },

                complete: ()=> {

                    this.$toast.clear();
                    
                }

            })

        },

        //清空数据

        demoMessageClear(){

            this.demoMessage = '';

        },

        //弹出参考模板

        recognitionDemoOpen(){

            this.$dialog.alert({

                title:'参考模板',

                message:"客户姓名:何总\n客户电话:15211111111\n客户性别:男\n客户姓名:何总\n客户电话:15211111111\n客户性别:男\n带看人姓名:小李\n带看人手机号码:15211111111\n到访人数:5",

                messageAlign:'left',

                confirmButtonColor:"#00a0e4",

                confirmButtonText:"知道了"

            })

        },

        //对楼盘数据进行处理

        selectHouseHandle(houseData){

            let _houseData = cloneDeep(houseData);

            _houseData.forEach(el=>{

                //获取提示内容

                const houseTips = houseTipsObj.joinTips(el,'* ',true);

                //存在注意事项，填写

                if(houseTips.status){

                    el.houseRecordTips = houseTips.message;

                }

            });

            return _houseData;

        },

        //提取出需要填写资质证件的楼盘

        getisNeedIdCardHouse(houseList){

            houseList = isArray(houseList) ? houseList : [];

            let list = houseList.filter(el=>{

                return el.isNeedIdCard;

            });

            return list;

        },

        /**
         * 在存在需要上传资质证件的楼盘的情况下,提取出未上传相关资质的客户名称
         * 
         * @param {Array} customerList 传入一个用户列表
         * 
         * @return {Object} 返回一个对象， status 是否读取成功  
         * 
         */

        checkCustomerCustomerPicStatus(customerList){

            let list = {status:false,customerList:[]};

            if(!isArray(customerList)){

                return list;

            }

            list.customerList = customerList.filter(el=>{

                return !isArray(el.customerPic) || (isArray(el.customerPic) && el.customerPic.length == 0);

            });

            if(list.customerList.length>0){

                list.status = true;

            }


            return list;

        },
        
        /**
         * 判断客户列表中是否没有存在对应的性别
         * 
         */
        checkCustomSex(){
            let message = '以下客户未选择性别：\n\n';
            let sexNull = this.customerList.filter(el=>{
                return !el.sex;
            });
            //全部都存在性别
            if(sexNull.length == 0){
                return false;
            };

            sexNull.forEach((el,index)=>{
                message+=`<span style="color:red">${index == 0 ? '' : '、'}${el.name}</span>`;
            });
            message+='\n\n请先选择客户性别后，再进行提交。';
             this.$dialog.alert({
                title:'请选择客户性别',
                message,
                messageAlign:'left',
                confirmButtonColor:'#00a0e4',
                confirmButtonText:'知道了'
            }).then(()=>{})

            return message;

        },

        //上传图片

        afterRead(file){

            const _check = this.beforeThumbUpload(file.file);

            if(!_check) return;

            const formData = new FormData();         
            
            formData.append('file', file.file);

            api.upload({

                options:formData,

                success:(res)=>{

                    if(res.type == 200){

                        if(!this.userForm.customerPic){

                            this.$set(this.userForm,'customerPic',[]);

                        }

                        this.userForm.customerPic.push(res.data.url);

                    }else{

                        this.$notify({ type: 'danger', message: res.msg || '图片上传失败，请稍后重试' });

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$notify({ type: 'danger', message:'图片上传失败，请检查网络'});

                }

            })

        },

        //图片删除

        deleteImage(index){

            this.$dialog.confirm({

                title:"图片删除",

                message:'确定要删除此图片吗'

            }).then(()=>{

                this.userForm.customerPic.splice(index,1);

            }).catch(()=>{})

        },

        //报备数据恢复

        recoveryRecordData(){

            //检查是否存在需要恢复的数据

            let recordData=dataStorage.StorageGetter('record_data_cache');

            if(!recordData){

                return;

            }

            recordData = Decrypt(recordData);

            //如果存在，检查一下过期时间

            let date = new Date();

            let time = recordData.cacheTime;

            if(date.getTime()>time){

                this.$store.commit('resetRecordData');

                return;

            }

            //继续恢复

            this.$store.commit('recoveryRecordData',recordData);

        }

    },

    beforeDestroy(){

        //销毁vuex

        this.$store.commit('resetRecordData');

    },

    created(){

        this.recoveryRecordData();

    },

    mounted(){
    

        if(!this.houseId || this.selectHouse.length>0) return;

        //存在id的情况下默认获取对应楼盘的数据并添加
        api.getHouseData({

            options:{

                id:this.houseId
                
            },

            success:(res)=>{

                if(res.type == 200){

                    this.$store.commit('recordArrayChange',{

                        key:'selectHouse',

                        item:res.data

                    });

                }

            }

        })

    }

}
</script>

<style lang="less">
.house-record-wrapper{

    background:#f6f6f6;

    height:100%;

    .fixed-cover{

        position: fixed;

        top:0;

        bottom:0;

        left:0;

        right:0;

        overflow: hidden;

    }

    .sub-title{

        font-size:12px;

        color:#999999;

    }

     .order-images-cover{

         padding-top:5px;

                        .order-images-show{

                            display: flex;
                            
                            flex-wrap: wrap;

                        }

                        .order-image{

                            width: 80px;

                            height: 80px;

                            margin-bottom:10px;

                            margin-right:10px;

                            box-shadow:0 5px 16px rgba(0,0,0,.08);

                            position: relative;

                            overflow: hidden;

                            &:nth-child(3n){

                                margin-right:0;

                            }

                            img{

                                width: 100%;

                                height:100%;

                            }

                            .close-btn{

                                position: absolute;
                                width: 80px;
                                height: 40px;
                                right: -60px;
                                top: -25px;
                                background: rgba(0, 0, 0, 0.6);
                                text-align: center;
                                padding-top: 17px;
                                transform: translateY(10px) translateX(-26px) rotate(45deg);

                                color:#fff;

                                .delete-icon{

                                    font-size:15px;

                                    text-shadow: 0 0 16px rgba(0,0,0,.16);

                                }
                                
                                &:active .delete-icon{

                                    opacity: .6;

                                }

                            }

                        }

                        .van-uploader__upload{

                            width: 80px;

                            height:80px;
                            

                            .van-uploader__upload-icon{

                                font-size:24px;

                            }

                        }

                    }

    .house-tips{

        max-width: 70%;

        display: block;

        line-height:20px;

    }

    .ipt-icon{

        padding-left:5px;

        color:red;

    }

    .van-collapse-item__title--expanded::after{

        display: none;

    }

    .van-collapse-item__content{

        padding-top:0;

    }

    .recognition-btns{

        padding-top:10px;

        display: flex;

        justify-content: space-between;

        .btn{

            display: inline-flex;

            height:30px;

            line-height:32px;

            color:#777777;

            &:active{

                color:#444444;

            }

            &.recognition-start{

                align-self:flex-end;
                
                padding: 0 15px;

                color:#fff;

                background: #00a0e4;

                border-radius: 22px;;

                &:active{

                    color:#fff;

                    background: #0087c0;

                }

            }

            &.recognition-empty{

                margin-right:20px;

            }

        }

    }

    .paste-cover{

        width: 100%;

        border-radius: 4px;

        height:160px;

        overflow: hidden;

        textarea{

            width: 100%;

            border-radius: 4px;

            height:100%;

            resize:none;

            padding: 10px;

            border:1px solid #eee;

            overflow: hidden;

            overflow-y:auto;

            background: #f8f8f8;

            color:#666666;

            transition:border-color .5s;

            &:focus{

                border-color:#5aceff;

            }

        }

    }

    .house-record-cover{

        height:100%;

        .bd{

            flex:1;

            height:0;
            
            overflow: hidden;

            overflow-y:auto;

            padding-bottom:50px;

            .customer-cell{

                margin-bottom:10px;

            }

            .user-phone{

                padding-right:10px;

            }

            .customer-sex-icon{

                margin-right:10px;

            }

            .clinet-list{

                padding:0 15px 10px;
                
                .van-cell__label{

                    margin-top:0;

                }

            }

            .van-cell-group{

                margin-top:10px;

            }

            .house-cell{

                align-items: center;

            }

            .client-cell{

                background:#f6f6f6;

                border-radius:5px;

            }

            .p-num{

                width: 60px;

                text-align: right;

                padding-right:10px;

                &::-webkit-input-placeholder { /* WebKit browsers */
                color: #969799;
   
                }

                &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color:#969799;
  
                }

                &:-ms-input-placeholder { /* Internet Explorer 10+ */
                color: #969799;
          
                }   

            }

            .p-normal{

                text-align: right;

                width: 140px;

            }

            

            .delete-icon{

                width: 30px;

                height:30px;

                line-height:30px;

                text-align: center;

                font-size:16px;

                margin-left:10px;

                &.bold{

                    font-weight: bolder;

                    font-size:14px;

                    border-radius: 50%;

                    background:rgb(255, 56, 56);

                    width: 20px;

                    height:20px;

                    line-height:22px;

                }

            }

            .user-add-btn{

                padding:0 40px 15px;

            }

        }

    }

    
    .client-add-wrapper{

        padding:10px;

        .notes{

            text-align: center;

            font-size:11px;

            color:#999999;

            padding:15px 0px 0;

            &.s{

                padding:5px 10px 15px;

            }

        }

        .client-add-cover{

            background:#f6f6f6;

            padding: 0 10px;

            border-radius: 5px;

            .van-cell{

                background:#f6f6f6;

                padding:10px 6px;

                .van-field__control{

                    position: relative;

                    z-index:10;

                }

            }

            .van-field__button{

                position: relative;

                z-index:10;

            }

            .van-field__clear{

                position: relative;

                z-index:20;

            }

            .van-field__label{

                width: 40px;

                .phone{

                    width: 25px;

                    height:25px;

                    line-height:25px;

                    text-align: center;
                    
                    margin-left:6px;

                    margin-top:-2px;

                    &:after{

                        border-color:#ddd;

                    }

                    .van-icon{

                        font-size:16px;

                        vertical-align: middle;

                    }
                

                }

            }

            .client-add-item.selected{

                .van-field__label{

                    width: auto;

                }

                .phone{

                    margin-left:0;
                    
                    margin-right:10px;

                }

                .tel-area-select{

                    display: flex;

                }

                .area-selected{

                    display: flex;
                    
                    align-items: center;

                    color:#00a0e4;
                    
                    .van-icon{

                        padding-left:3px;

                    }
                }

            }

            .client-add-item .client-upload{

                padding:10px 0;

                .title{

                    font-size:12px;

                    color:#999999;

                }

                .upload-cover{

                    padding-top:10px;

                    .van-uploader__upload{

                        background: #e3effa;

                    }

                    .van-uploader__upload-icon{

                        color:#00a0e4;

                    }


                }

                

            }

            
            .sex-select{

                    display: flex;

                    border:1px solid #00a0e4;

                    border-radius:5px;

                    .sex-select-btn{

                        font-size:14px;

                        padding: 2px 4px 2px;

                        line-height:20px;

                        &.active{

                            background:#00a0e4;

                            color:#fff;

                        }

                    }

                    &.border-blink{

                        animation: borderBlink .5s infinite;

                    }

                }


        }

    }


    @keyframes borderBlink{
        
        0%{

            border-color:rgba(0, 160, 228,0)

        }
        50%{

            border-color:rgba(0, 160, 228,1)

        }
        100%{

            border-color:rgba(0, 160, 228,0)

        }

    }

}

</style>
