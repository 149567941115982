import { isArray } from "@/utils"

export default{


    data(){

        return{

            //是否显示预览

            fkyImagePreviewShow:false,

            //默认显示index

            fkyImagePreviewIndex:0,

            fkyImagePreviewList:[]

        }

    },

    methods: {
        
        fktImagePreviewShowOpen({index,list,item=''}){

            const _list =this[this.fkyImagesKey] ? this[this.fkyImagesKey] : list;

            if(!isArray(_list) && !item) return;

            //获取当前选择的图片

            if(item){

                this.fkyImagePreviewList = [this.$getImageUrl(item)];

            }else{

                
                this.fkyImagePreviewList = _list.map(el=>{

                    return this.$getImageUrl(el);

                });

            }
    
            if(this.fkyImagePreviewList.length == 0) return;
    
            this.fkyImagePreviewIndex = index || 0;
    
            this.fkyImagePreviewShow = true;

        }

    },


}