
const DataStorage =(()=>{

    let prefix = 'record_cache_';

    try{

            let StorageSetter = (key,val)=>{

                return localStorage.setItem(prefix+key,JSON.stringify(val));

            }
            
            let StorageGetter = (key)=>{

                let data=  localStorage.getItem(prefix+key)

                 return data ?  JSON.parse(data) : data;

            }

            let StorageRemove = (key)=>{

                return localStorage.removeItem(prefix+key);

            }
            
            return {
                        
                StorageSetter:StorageSetter,
                        
                StorageGetter:StorageGetter,
                        
                StorageRemove:StorageRemove
                    
            }

        }catch(err){

             console.log('暂不支持此功能');

        }

})()


export default DataStorage;