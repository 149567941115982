import axios from "axios";

//请求时间延迟15000
const $axios = axios.create({
  
    timeout: 300000
    
});
  

$axios.CancelToken = axios.CancelToken;

export default $axios;