/**
 * 楼盘提示全部写在此，
 * 
 * 此js 用于拼装楼盘选择提示语
 * 
 */



 const houseTipsObj = {

     /**
     * 
     * 判断提示用列表
     * 
     * @param code 用来判断的参数名称
     * 
     * @param title 提示标题
     * 
     * @param message 提示内容
     * 
     * 
     */


    houseTipsList: [

        //是否全名报备

        {

            code:'isFullName',

            title:'全名报备',

            message:'必须使用全名报备，不得使用 "先生","女士" 等称谓，请悉知!'

        },

        //是否姓氏报备

        {
            
            code:'isNotFullName',

            title:'姓氏报备',

            message:'必须使用姓氏报备，不得填写客户全名，只需要填写姓氏。'


        },

        //是否上传相关证件

        {

            code:'isNeedIdCard',

            title:'上传相关证件',

            message:"必须上传相关证件，如 客户身份证、户口本等证件，用于资质审核。"


        },

        //特殊提醒

        {

            code:'isAlert',

            title:"",

            message:""


        }


    ],


    /**
     * 拼接数据方法
     * 
     * @param houseData 楼盘数据集合
     * 
     * @param icon 用来标志 前面的icon 如 1 2 3
     * 
     * @param {Boolean} noBr 是否使用多重跨行
     * 
     * @return tipsData 返回一个提示集合 {title:'',message:'',status:true} status 表示此次是否拼接成功 Boolean
     * 
     * 
     */

    joinTips(houseData,icon,noBr){

        noBr = !!noBr;

        icon = icon ? icon : 'index';

        let tipsObj = {title:'',message:'',status:false};

        if(!houseData){

            return tipsObj;

        }
        
        //提取一下

        const tipsList = this.houseTipsList.filter(el=>{

            return houseData[el.code];

        });

        if(tipsList.length>0){

            tipsObj.status = true;

            if(!noBr){

                tipsObj.message=`${houseData.name}，报备需注意以下事项：\n\n`

            }

            //分为一个提示，和一个以上提示两种

            if(tipsList.length == 1){

                if(tipsList[0].code != 'isAlert'){

                    tipsObj.title = tipsList[0].title;

                    tipsObj.message = tipsList[0].message;

                }else{


                    tipsObj.title = houseData.specialHintTitle;

                    tipsObj.message = houseData.specialTip;

                }

            }else{

                tipsObj.title = '报备提示';

                tipsList.forEach((el,index)=>{

                    //特殊提示

                    if(el.code == 'isAlert'){

                        tipsObj.message+=`${icon == 'index' ? (index+1)+"、" : icon}${houseData.specialTip}${noBr ? '\n' : '\n\n'}`

                    }else{

                        tipsObj.message+=`${icon == 'index' ? (index+1)+"、" : icon}${el.message}${noBr ? '\n' : '\n\n'}`

                    }

                });

            }


        }



        return tipsObj;


    }



 }



 export default houseTipsObj;