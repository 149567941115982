
import { isObject,isArray } from "@/utils";

const sessionStorageData =(()=>{

    let prefix =  'record_cache_';

        try{

            let StorageSetter = (key,val)=>{

                if(isObject(val) || isArray(val)){

                    return sessionStorage.setItem(prefix+key,JSON.stringify(val));

                }else{

                    return sessionStorage.setItem(prefix+key,val);

                }

                

            }
            
            let StorageGetter = (key,isString)=>{

                let data=  sessionStorage.getItem(prefix+key);

                if(isString){

                    return data;

                }else{

                    return JSON.parse(data);

                }

            }

            let StorageRemove = (key)=>{

                return sessionStorage.removeItem(prefix+key);

            }

            let StorageClear = ()=>{

                return sessionStorage.clear();

            }
            
            return {
                        
                StorageSetter,
                        
                StorageGetter,
                        
                StorageRemove,

                StorageClear
                    
            }

        }catch(err){

             console.log('暂不支持此功能');

        }

})()


export default sessionStorageData;