import axios from "@/axios";
import dataUrl from "@/config/interface";
import eventBus from "@/eventBus"
import { isObjectNone } from "@/utils"
import store from "@/store"

 const apiList = {};

 if(!isObjectNone(dataUrl)){

    Object.keys(dataUrl).forEach(item=>{

        apiList[item] = ({success,error,complete,options})=>{


            if(dataUrl[item].type == 'get'){

                return axios.get(`${process.env?.VUE_APP_BASE_URL}${dataUrl[item].url}`,{

                    headers: item == 'login' ? {
                        'companyId':store.state.userInfo?.company?.companyId
                    } : {
    
                        'Authorization':store.state.userInfo?.token,

                        'companyId':store.state.userInfo?.company?.companyId
                    },

                    params:options
    
                }).then((res)=>{

                        complete && complete(res.data);
                        
                        //token过期
    
                        if(res.data.code == 401){
    
                            eventBus.$emit('tokenExpired');
    
                        }else{

                            success({type:res.data.code,data:item == 'upload' || item == 'externalUpload' ? res.data.url :res.data.data,msg:res.data.message,msgTitle:res.data.msgTitle});

                        }
        
                    }).catch(rej=>{

                        complete && complete();
        
                        error && error(rej);
                        
        
                    })


            }else{
                return axios.post(`${process.env?.VUE_APP_BASE_URL}${dataUrl[item].url}${dataUrl[item]?.list ? `?page=${options?.page}&pageSize=${options?.pageSize}` : ''}`,options,{

                    headers: item == 'login' ? {
                        'companyId':store.state.userInfo?.company?.companyId
                    } : {
    
                        'Authorization':store.state.userInfo?.token,

                        'companyId':store.state.userInfo?.company?.companyId
                    },
    
                }).then((res)=>{
                    complete && complete(res.data);
                    //成功获取到数据
                    if(res.data.code == 401){
                        eventBus.$emit('tokenExpired');
                    }else{
                        success({type:res.data.code,data:res.data.data,msg:res.data.message,msgTitle:res.data.msgTitle});
                    }
                }).catch(rej=>{
                    complete && complete();
                    error && error(rej);
                })


            }

            

        }

    })

 }
    
 export default apiList;