
import home from "@/views/common/home";
import userInfo from "@/views/common/userInfo";
import userInfoSet from "@/views/common/userInfoSet";
import errPage from "@/views/common/404";
import changePassword from "@/views/common/changePassword";
import retrievePassword from '@/views/common/retrievePassword'
import login from "@/views/common/login";
import help from "@/views/common/help";
import aboutUs from "@/views/common/aboutUs";
import userAgreement from "@/views/common/userAgreement";
import selectCity from "@/views/common/selectCity";
import favorite from "@/views/common/myFavorite";
import register from "@/views/common/register";
import calculator from "@/views/common/calculator";
import authorization from "@/views/common/authorization"
 
const router = [

    { 
        path: '*',

        name:'404',

        component:errPage,

        meta:{

            lv:0

        }

    },


    {

        //首页

        path:'/home',

        name:'home',

        component:home,

        meta:{

            lv:1,

            live:true

        }


    },{

        //用户信息

        path:'/user/info',

        name:'userInfo',

        component:userInfo,

        meta:{

            lv:2

        }


    },{

        //个人信息修改

        path:'/user/info/set/:type',

        name:'userInfoSet',

        component:userInfoSet,

        meta:{

            lv:3

        }


    },{

        //修改密码

        path:'/change/password',

        name:'changePassword',

        component:changePassword,

        meta:{

            lv:4

        }


    },{

        //找回密码

        path:'/retrieve/password',

        name:'retrievePassword',

        component:retrievePassword,

        meta:{

            lv:5,

            live:true

        }


    },{

        //登录

        path:"/login",

        name:'login',

        component:login

    },{

        //帮助

        path:'/help',

        name:"help",

        component:help,

        meta:{

            lv:3

        }

    },{

        //关于

        path:'/aboutUs',

        name:"aboutUs",

        component:aboutUs,

        meta:{

            lv:3,

            live:true

        }

    },{

        //用户协议

        path:'/user/agreement',

        name:"userAgreement",

        component:userAgreement,

        meta:{

            lv:4,

            live:true

        }

    },{

        //城市选择

        path:'/select/city',

        name:'selectCity',

        component:selectCity,

        meta:{

            lv:3,

            live:true

        }


    },{

        //我的收藏

        path:"/my/favorite",

        name:"favorite",

        component:favorite,

        meta:{

            lv:3

        }


    },{

        //分销商注册

        path:"/register",

        name:"register",

        component:register,

        meta:{

            lv:3,
            
            live:true

        }

    },{

        //分销商选择渠道列表

        path:"/register/select/channel",

        name:'register/select/channel',

        component:() => import('@/views/common/selectChannel'),

        meta:{

            module:true,

            lv:4,

            live:true

        }


    },{

        //分销商选择公司渠道

        path:"/register/select/company",

        name:'register/select/company',

        component:() => import('@/views/common/selectCompany'),

        meta:{

            module:true,

            lv:4,

            live:true

        }


    },{

        //房贷计算器

        path:'/calculator',

        name:'calculator',

        component:calculator,

        meta:{

            lv:4,

            live:true

        }


    },{

        //我的分享

        path:"/my/share",

        name:"myShare",

        component:()=>import('@/views/common/share'),

        meta:{

            lv:3

        }


    },{

        //分享详情

        path:'/my/share/detail/:id',

        name:'myShareDetail',

        component:()=>import('@/views/common/share_detail'),

        props:true,

        meta:{

            lv:4

        }

    },{

        //分享详情

        path:'/authorization',

        name:'authorization',

        component:()=>import('@/views/common/authorization'),

        props:true,

        meta:{

            lv:0,

            live:true

        }

    }

]




export default router;