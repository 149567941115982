<template>
    <div class="house-commission-item u-border-top">
        <div class="house-commission-item__row u-flex">
            <div class="house-commission-item_label">生效日期区间：</div>
            <div class="house-commission-item_value">{{ prop.policyStartDate }} ~ {{ !!prop.longTerm ? '长期' : prop.policyEndDate }}</div>
        </div>
        <div class="house-commission-item__row u-flex">
            <div class="house-commission-item_label">房屋类型：</div>
            <div class="house-commission-item_value ipt" style="color:#3c9cff">{{ prop.buildingPropertyCn }}</div>
        </div>
        <div class="house-commission-item__row u-flex">
            <div class="house-commission-item_label">二级点位：</div>
            <div class="house-commission-item_value ipt">{{ prop.secondaryPoint }}</div>
        </div>
        <div class="house-commission-item__row u-flex">
            <div class="house-commission-item_label">二级成交奖：</div>
            <div class="house-commission-item_value ipt">{{ prop.secondaryAward }}</div>
        </div>
        <div class="house-commission-item__row u-flex" v-if="prop.secondaryRemark">
            <div class="house-commission-item_label">备注：</div>
            <div class="house-commission-item_value ipt">{{ prop.secondaryRemark }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        prop: {
            type: Object,
            default() {
                return {}
            }
        },
        name:{
            type:String,
            default:''
        }
    }
}
</script>

<style lang="less">
.house-commission-item {
    padding: 5px 11px 10px;
    font-size: 13px;
    line-height: 20px;

    &__row {
        padding-top: 5px;
    }

    &_label {
        color: #999999;
    }

    &_value {
        color: #666666;
        flex:1 1 auto;
        width: 0;
    }
}
</style>