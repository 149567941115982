<template>
   <div class="index-wrapper">
    <div class="flex-box">
        <div class="hd" @click="goLink({name:'houseList',params:{focus:true}})">
            <van-search v-model="value" placeholder="请输入楼盘名称" :readonly="true" />
            <div class="select-city" v-if="currentCity" @click.stop="goLink({name:'selectCity'})">{{currentCity.text}}<i class="van-hairline--left"></i></div>
        </div>
        <div class="bd" ref="indexScrollView">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <transition name="van-fade">
                <div class="bd-scroll-view" v-if="indexData">
                    <div class="index-banner">
                        <van-swipe :autoplay="8000" indicator-color="#fff" ref="indexBanner" :options="swiperOptionBanner">
                            <van-swipe-item v-for="(image, index) in indexData.banners" :key="index" @click="goLink({path:'/house/detail/'+image.id})">
                                <img v-lazy="$getImageUrl(image.url)">
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                    <nav class="index-nav">
                        <van-grid :border="false">
                            <van-grid-item :icon="item.icon" :text="item.text" @click="goLink({path:'/house/list',query:{feature:item.feature}})" v-for="(item,index) in indexData.navList" :key="index" />
                        </van-grid>
                    </nav>
                    <template v-for="(item,index) in indexData.columnList">
                        <section class="index-column" :key="index" v-if="(item.rectype != 4 && item.rectype !=2) || (item.rectype == 4 && item.list.length>0) || (item.rectype == 2 && item.list.length>0)">
                            <div class="index-column__hd">
                                <p class="index-column__hd-title" @click="goLink({path:'/house/list',query:{feature:item.morelink}})"><span>{{item.title}}</span><van-icon name="arrow" class="index-column__hd-title__more"/></p>
                                <p class="index-column__hd-title_sub">{{item.des}}</p>
                            </div>
                            <div class="index-column__bd free-swiper">
                                <div class="house-list">
                                    <swiper :options="swiperOption">
                                        <swiper-slide v-for="(_item,_index) in item.list" :key="_index">
                                        <div class="item" :class="{videoCover:item.rectype ==2 || item.rectype == 4}" @click="goLink({path:'/house/detail/'+_item.id})">
                                                <div class="item-thumb-cover">
                                                    <img :src="_item.logo|$imageUrl" alt="">
                                                    <div class="video-icon p-a-m-c" v-if="item.rectype ==2"><van-icon name="system-icon_play" /></div>
                                                    <div class="vr-icon p-a-m-c" v-if="item.rectype == 4"><van-icon name="system-vr-2" /></div>
                                                    </div>
                                                    <div class="item-content">
                                                        <p class="name van-ellipsis">{{_item.name}}</p>
                                                        <p class="tags" v-if="item.rectype !=2 && item.rectype !=4">
                                                            <span v-for="(tag,__index) in _item.tags" :key="__index">{{tag}}</span>
                                                        </p>
                                                        <p class="price" v-if="item.rectype !=2 && item.rectype !=4">{{_item|totalPrice}}</p>
                                                    </div>
                                            </div>
                                        </swiper-slide>        
                                    </swiper>
                                </div>
                            </div>
                        </section> 
                    </template>    
            </div>
            </transition>
            <div class="skeleton bd-scroll-view" v-if="!indexData">
               <div class="index-banner"></div>
               <nav class="index-nav">
                    <van-grid :border="false" column-num="4">
                        <van-grid-item icon="system-house"   />
                        <van-grid-item icon="system-home"  />
                        <van-grid-item icon="system-sp"  />
                        <van-grid-item icon="system-tourism" class="big"  />
                    </van-grid>
                </nav>
                <div class="index-column">
                    <van-skeleton title :row="3" />
                </div>    
                <div class="index-column">
                    <van-skeleton title :row="3" />
                </div>
            </div>
            </van-pull-refresh>
        </div>
    </div>
   </div>
</template>

<script>
//滑动组件
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import api from "@/api";
import 'swiper/swiper-bundle.css';
export default {

    components: {

        Swiper,

        SwiperSlide

    },

    directives: {

        swiper: directive

    },

    filters:{
        totalPrice(item){
            return parseFloat(item.packagePrice) ? item.packagePrice +'万/套' : item.averagePrice ? item.averagePrice+'元/㎡' : '暂无';
        }
    },

    data(){

        return{

            isLoading:false,

            value:'',

            indexData:null,

            swiperOption: {

                freeMode:true,

                slidesPerView:"auto",
                
            },

            swiperOptionBanner:{

                // 修改swiper自己或子元素时，自动初始化swiper
                observer: true,
                // 修改swiper的父元素时，自动初始化swiper
                observeParents: true,

                autoplay:8000

            },


            scrollTop:0,

            lock:false

        }

    },

    computed:{

        currentCity(){

            return this.$store.state.userInfo.currentCity || {};

        }
    },

    methods:{

        init(){

            if(this.indexData) return;

            if(this.lock) return;

            this.lock=true;

            global.setTimeout(()=>{

                this.getIndexData();

            },300)
        },

        goLink(route){

            this.scrollTop = this.$refs.indexScrollView.scrollTop;

            this.$nextTick(()=>{

                this.$router.push(route);

            })

        },

        onRefresh() {

           this.getIndexData(true);

        },

        //获取首页初始化数据

        getIndexData(refresh){

            if(!refresh){

                this.$toast.loading({

                    forbidClick:true,

                    duration:0,

                    message:'加载中...'

                });

            }

            let options ={};

            if(this.currentCity){

                options.cityId = this.currentCity.value;

            }

            api.getIndexData({

                options,

                success:(res)=>{

                    if(res.type == 200){

                        this.$nextTick(()=>{

                            this.indexData=res.data;

                        })

                        if(refresh){

                            this.$toast({

                                message:"首页数据已更新",

                                position:"top"

                            })

                        }

                    }else{

                        this.$toast(res.msg);
                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$toast(err);

                },

                complete:()=>{

                    this.$toast.clear();

                    this.isLoading=false;

                    this.lock=false;

                    this.$store.commit('setRouterData',{

                        key:'indexRefresh',

                        value:false

                    });

                }

            })
            

        }

    },

    activated(){
        this.$nextTick(()=>{

            if(this.scrollTop){

                this.$refs.indexScrollView.scrollTop=this.scrollTop;

            }

            if(this.$store.state.routerStore.indexRefresh){

                //添加一个延迟，让过渡更加平滑

                global.setTimeout(()=>{
                    this.getIndexData();

                },300)

            }else{

                
                this.$nextTick(()=>{    

                    this.init();

                })

            }

        })

    }

}
</script>

<style lang="less">
.index-wrapper{

.md-activity-indicator-rolling .rolling-container{

    top:1px;

}

.flex-box{

    display: flex;

    flex-direction: column;
    
    height:100%;

    overflow: hidden;

    .hd{

        box-shadow:0 2px 5px rgba(0,0,0,.06);

        padding: 0 6px;

        position: relative;

        .select-city{

            position: absolute;

            right:20px;

            top:0;

            font-size:14px;

            height:34px;

            line-height:36px;

            top:10px;

            padding: 0 10px;

            color:#666;

            i{

                position: absolute;

                left:0;

                width: 100%;

                height:40%;

                top:50%;

                transform: translateY(-50%);

                &::after{

                    border-color:#bbb;

                }

            }

        }

    }

    .bd{

        height:0;

        flex:1;

        overflow: hidden;

        overflow-y: auto;

        -webkit-overflow-scrolling:touch;

        .bd-scroll-view{

            min-height:100%;

            padding:15px 18px 20px;

        }


        .index-banner{

            width: 100%;

            height:181px;

            border-radius: 5px;

            overflow: hidden;

            img{

                width: 100%;

                height:100%;

            }

            .van-swipe{

                height:100%;

            }

        }

        .index-nav{

            margin-bottom:10px;

            .van-grid-item{

                .van-grid-item__content{

                    padding-bottom:10px;

                }

                &:nth-child(4n+1){

                    .van-grid-item__icon{


                        background-image: linear-gradient(to right,#f8b369 , #ff9e2c);

                    }

                }                
                
                &:nth-child(4n+2){

                    .van-grid-item__icon{


                        background-image: linear-gradient(to right,#5ae489  , #39cb7d);

                    } 

                }               
                
                &:nth-child(4n+3){

                    .van-grid-item__icon{


                        background-image: linear-gradient(to right,#6bc6f5  , #3cb4fb);

                    } 

                }                
                
                &:nth-child(4n+4){

                    .van-grid-item__icon{

                        background-image: linear-gradient(to right,#fea389  , #f98976);

                    } 

                }


                &.big{

                    .van-grid-item__icon{

                        font-size:24px;

                    }

                }

                .van-grid-item__icon{

                    color:#fff;

                    display: block;

                    width: 50px;

                    height:50px;

                    border-radius:50%;

                    overflow: hidden;

                    box-shadow: 0 0 3px rgba(0,0,0,.1);

                    text-align: center;

                    line-height:50px;

                    font-size:20px;

                }

                .van-grid-item__text{

                    color:#6c6c6c;

                    font-size:13px;

                }


            }

        }

        .index-column{

            margin-bottom:30px;

            .index-column__hd{

                .index-column__hd-title{

                    color:#333333;

                    font-size:18px;

                    font-weight: bold;

                    display: flex;

                    justify-content: space-between;
                    
                    align-items: center;

                    .index-column__hd-title__more{

                        font-size:18px;

                        font-weight: bold;

                        color:#666666;

                    }

                }

                .index-column__hd-title_sub{

                    line-height:28px;

                    color:#888888;

                }

            }

            .index-column__bd{

                padding-top:10px;

                &.free-swiper{

                    .swiper-container{

                        overflow: visible;

                    }

                    .swiper-slide{

                        width: auto;

                        margin-right:10px;

                        .item{

                            width: 156px;  
                            
                            &.videoCover{

                                .item-thumb-cover{

                                    border-radius:3px;

                                      background: rgba(0,0,0,.1);

                                    img{

                                        position: relative;

                                        z-index:-1;
                                      

                                    }

                                }

                                .item-content p.name{

                                    font-size:14px;

                                    color:#888888;

                                }

                            }

                            .item-thumb-cover{

                                width: 100%;    

                                height:101px;

                                overflow: hidden;

                                 border-radius: 5px;

                                 position: relative;
                               

                                img{

                                    width: 100%;

                                    min-height:100%;

                                }

                                .video-icon,.vr-icon{

                                    width: 30px;

                                    height:30px;

                                    line-height:32px;

                                    background: rgba(0,0,0,.4);

                                    border-radius: 50%;

                                    text-align: center;

                                    color:#fff;

                                    .van-icon{

                                        font-size:14px;

                                    }

                                }

                                .vr-icon{

                                    background: transparent;

                                    .van-icon{

                                        font-size:30px;

                                        text-shadow:0 0 1px rgba(0,0,0,.4);

                                    }

                                }

                            }

                            .item-content{

                                padding-top:10px;

                                p{

                                    line-height:20px;

                                    &.name{

                                        font-size:15px;

                                    }

                                    &.tags{

                                        padding-top:3px;

                                        display: flex;

                                        min-height:20px;

                                        span{

                                            padding-right:4px;

                                            margin-right:4px;

                                            color:#888;

                                            position: relative;

                                            &:after{

                                                content:'';

                                                position: absolute;

                                                right:0;

                                                top:50%;

                                                width: 1px;

                                                height:50%;

                                                transform: translateY(-60%);

                                                background:#888;

                                            }

                                            &:last-child:after{

                                                display: none;

                                            }

                                        }

                                    }

                                    &.price{

                                        color:#f58d3d;

                                        font-size:15px;

                                        padding-top:3px;

                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

        .skeleton{

            .index-banner{

                background: #f6f6f6;

            }

            .index-nav{

                .van-grid-item .van-grid-item__icon{

                    background:#f6f6f6;

                    color:#f6f6f6;

                }

            }

        }

    }

}
    

}
</style>
