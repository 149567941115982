/**
 * 保证先获取对应的公司信息
 */
import axios from "axios";
import dataUrl from "@/config/interface";
import sessionStorage from "@/utils/sessionStorage";
//动态创建主题样式
const createThemeStyle = (color,favicon)=>{
    var style = document.createElement('style');
    style.type = 'text/css'
    style.innerHTML=`.house-tabs .van-tab--active{color:${color}!important}.house-tabs .van-tabs__line{width: 33.33vw;background-color:${color}!important;height:1px;}.house-tabs .tab-item.base-info .base-info-column .base-info-column__sub-title,.tab-item.y-rules .y-rules-item .y-rules-item__title{color:${color}!important;}.house-tabs .van-button--plain.van-button--info{color:${color}!important;border-color:${color}!important;}`
    document.getElementsByTagName("HEAD").item(0).appendChild(style);
    if(favicon){
        document.getElementById('favicon-x').href=favicon;
        document.getElementById('favicon').href=favicon;
    }
}
const getCompanyInfo = (store)=>{
    return new Promise((_res,_rej)=>{
        //获取当前缓存数据
        let company = sessionStorage.StorageGetter('company');
        //存在则直接初始化，否则拉取数据
        if(company){
            store.commit('setUserData',{
                key:'company',value:company
            })
            createThemeStyle(company?.color || '#00a0e4',company?.favicon);
            _res();
        }else{
            axios.get(`${process.env?.VUE_APP_BASE_URL}${dataUrl.getCompanyInfo.url}`,{params:{host:process.env.NODE_ENV === 'development' ? 'test.fky.fxyos.com' : window.location.host}}).then(res=>{
                if(res.data.code == 200){
                    //保存
                    store.commit('setUserData',{
                        key:'company',value:res.data?.data
                    })
                    sessionStorage.StorageSetter('company',res.data?.data);
                    createThemeStyle(company?.color || '#00a0e4',company?.favicon);
                    _res();
                }else{
                    document.getElementById('app').innerHTML = `<p style="text-align:center;padding:40px 20px 20px;color:#999999">获取公司数据失败，报备系统打开异常，请刷新后重试。</p>`
                }
            }).catch(err=>{
                console.log(err)
                document.getElementById('app').innerHTML = `<p style="text-align:center;padding:40px 20px 20px;color:#999999">获取公司数据失败，报备系统打开异常，请刷新后重试。</p>`
                _rej();
            })
        }
    })
}

export default getCompanyInfo;