
import recordList from "@/views/management/recordList";

import recordDetail from "@/views/management/recordDetail";

import recordAudit from "@/views/management/recordAudit";

import customerList from "@/views/management/customerList";

import staffList from "@/views/management/staffList";

import rapidRecord from "@/views/house/record";

const router = [


    {

        path:'/record/list',

        name:'recordList',

        component:recordList,

        meta:{

            lv:5

        }


    },{

        path:'/subDistributor/record/list',

        name:'subDistributorRecordList',

        component:recordList,

        meta:{

            lv:5

        }


    },{

        path:'/record/detail/:id',

        name:'recordDetail',

        component:recordDetail,

        meta:{

            lv:5,

            parent:'/record/list'

        }


    },{

        path:'/record/audit/:id',

        name:'recordAudit',

        component:recordAudit,

        meta:{

            lv:7,

            parent:'/record/list'

        }


    },{

        path:'/customer/list',

        name:'customerList',

        component:customerList,

        meta:{

            lv:3

        }


    },{

        path:'/staff/list',

        name:'staffList',

        component:staffList,

        meta:{

            lv:3

        }

    },
    {
        path:'/staff/batch/list',

        name:'staffBatchList',

        component:() => import('@/views/management/batchStaffList'),

        meta:{

            lv:4,

            module:true

        }
        
    },{

        path:'/rapid/record',

        name:'rapidRecord',

        component:rapidRecord,

        meta:{

            lv:4

        }

    },{

        path:"/subDistributor/statistics",

        name:'statistics',

        component:() => import('@/views/management/statistics_index'),

        meta:{

            lv:3,

            module:true

        }

    },{

        path:"/subDistributor/statistics/detail/:type",

        name:'statisticsDetail',

        component:() => import('@/views/management/statistics_detail'),

        meta:{

            lv:4,

            //标记为懒加载组件

            module:true,

        }

    },{

        path:"/external/record/list",

        name:'externalRecordList',

        component:() => import('@/views/management/externalRecordList'),

        meta:{

            lv:4,

            //标记为懒加载组件

            module:true,

            live:true

        }

    }

]




export default router;