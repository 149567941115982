<template>
  <div class="aboutus">
    <div class="aboutus-cover flex-box-column">
      <div class="bd">
        <div class="aboutus-cover__title">
          <div
            class="logo"
            :style="
              'background-image:url(' +
              logoBg +
              ');background-color:' +
              mainColor +
              ';'
            "
          ></div>
          <div class="title">{{ mainTitle }}</div>
        </div>
        <div class="aboutus-cover__content">
          <div class="tips">
            为了更好的为您提供服务，请点击授权按钮，进行{{ title }}操作。
          </div>
          <div class="sq-btn">
            <van-button block type="primary" @click="wechatLogin"
              >授权登录</van-button
            >
            <div class="back" @click="back">取消并返回</div>
          </div>
        </div>
      </div>
      <div class="footer">{{ mainTitle }} 版权所有</div>
    </div>
  </div>
</template>

<script>
import api from "@/api"
export default {
  data() {
    return {
      logo: require("@/assets/images/aboutLogo.png"),

      logoHm: require("@/assets/images/aboutLogo_hm.png"),
    };
  },
  methods: {
    back(){
        uni.navigateBack({
          delta: 1,
        });
    },
    getAuth(code) {
      uni.postMessage({
        data: {
          action: code,
        },
      });
      this.$nextTick(() => {
        uni.navigateBack({
          delta: 1,
        });
      });
    },
    //微信登录
    wechatLogin() {
      this.$toast.loading({
        message: "正在获取授权...",
        duration: 0,
        forbidClick: true,
      });

      api.wxCode({
        options: {
          url: "https://" + window.location.host + this.$route.path,
        },

        success: (res) => {
          if (res.type == 200) {
            window.location.href = res.data;
          } else {
            this.$dialog
              .alert({
                title: "授权失败",

                message: res.msg || "微信授权失败，请求异常，请稍后重试",
              })
              .then(() => {});
          }
        },

        error: (err) => {
          console.log(err);

          this.$dialog
            .alert({
              title: "授权失败",

              message: "微信授权失败，请求异常，请稍后重试",
            })
            .then(() => {});
        },

        complete: () => {
          this.$toast.clear();
        },
      });
    },
  },
  computed: {
    title() {
      return this.$route.query?.title || "";
    },

    kfPhone() {
      return this.$store.state.userInfo.kefPhone;
    },

    mainTitle() {
      return this.$store.state.userInfo?.company?.title;
    },

    logoBg() {
      return this.$getImageUrl(this.$store.state.userInfo?.company?.subLogo);
    },
    mainColor() {
      return this.$store.state.userInfo?.company?.color;
    },
  },

  created() {
    document.addEventListener("UniAppJSBridgeReady", function () {
      uni.getEnv(function (res) {
        console.log("当前环境：" + JSON.stringify(res));
      });
    });
  },
  activated() {
    //获取参数
    if (this.$route.query.code) {
      this.getAuth(this.$route.query.code);
    }
  },
};
</script>

<style scoped lang="less">
.aboutus {
  height: 100%;

  .aboutus-cover {
    height: 100%;

    justify-content: space-between;

    .bd {
      flex: 1;

      height: 0;

      overflow: hidden;

      overflow-y: auto;

      padding: 40px 15px 10px;

      .tips {
        font-size: 13px;
        color: #999999;
        line-height: 20px;
        padding: 0 30px;
      }

      .sq-btn {
        padding: 30px 30px;
        .back {
          padding: 10px;
          text-align: center;
          font-size: 14px;
          color: #999999;
          &:active {
            opacity: 0.6;
          }
        }
      }

      .aboutus-cover__title {
        display: flex;

        flex-direction: column;

        justify-content: center;

        align-items: center;

        min-height: 160px;

        padding-bottom: 10px;

        padding-top: 10px;

        .logo {
          width: 60px;

          height: 60px;

          margin-bottom: 10px;

          border-radius: 5px;

          background: #00a0e4 url("../../assets/images/aboutLogo.png") no-repeat
            center center / 80% 80%;

          &.hmBg {
            background-color: #ffae00;
          }
        }

        .title {
          font-size: 16px;

          color: #777;
        }
      }
    }

    .footer {
      font-size: 13px;

      text-align: center;

      line-height: 24px;

      padding-bottom: 5px;
    }
  }
}
</style>
