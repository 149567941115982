<template>
   <div class="select-city-wrapper">
       <div class="select-city-cover flex-box-column">
            <div class="hd"><van-nav-bar left-arrow title="选择城市" @click-left="back"></van-nav-bar></div>
            <div class="bd">
                <section class="column now">
                    <div class="column__title">当前城市</div>
                    <div class="column__content clearfix">
                        <div class="item current-city van-hairline--surround">{{city.text}}</div>
                    </div>
                </section> 
                <div class="p-title">全部城市</div>                   
                <template v-for="(item,index) in list">
                    <section class="column" :key="index">
                        <div class="column__content clearfix">
                            <div class="column__title">{{item.text}}</div>
                            <template v-for="(_item,_index) in item.children">
                                <div class="item van-hairline--surround" @click="selected(_item,_index)" :key="_index" v-if="item.value !=_item.value">
                                    <p>{{_item.text}}</p>
                                </div>
                            </template>
                        </div>
                    </section>
                </template>
            </div>
       </div>
   </div>
</template>

<script>
/**
 * 
 * 选择城市
 * 
 */

import backMixin from "@/mixins/back";

import {cloneDeep} from "@/utils";

import dataStorage from "@/utils/dataStorage";

export default {

    mixins:[backMixin],

    data(){

        return{

            list:[],

            city:{}

        }

    },


    methods:{

        selected(item,index){

            let currentCity = cloneDeep(item);

            let date =new Date();

            currentCity.areaIndex = index;

            currentCity.time = date.getTime();

            this.$store.commit('setUserData',{

                key:'currentCity',

                value:currentCity

            });

            this.$store.commit('setRouterData',{

                key:'indexRefresh',

                value:true

            });

            //记录当前用户选择的城市

            dataStorage.StorageSetter('city',currentCity);

            this.$router.back();

        }

    },

    activated(){

        this.list = this.$store.state.userInfo.cityList || [];

        this.city = this.$store.state.userInfo.currentCity || {};

    }

}
</script>

<style scoped lang="less">
.select-city-wrapper{

    height:100%;

    .select-city-cover{

        height:100%;

        .bd{

            padding: 15px 0;

            height:0;

            background: #ffffff;

            flex:1;

            overflow: hidden;

            overflow-y:auto;

            .p-title{

                font-size:16px;

                color:#999;

                background: #f6f6f6;

                padding:10px 16px;

                margin-bottom:20px;

            }

            .column{

                margin-bottom:20px;

                padding: 0 16px 4px;


                .column__title{

                    font-size:16px;

                    color:#999;

                    height:82px;

                    float:left;

                    width: 30%;

                    margin-right:11px;

                    text-align: center;

                    background: #f6f6f6;
                    
                    display: flex;

                    align-items: center;
                    justify-content: center;
                }

                &.now .column__title{

                    font-size:16px;

                    width: 100%;

                    float:none;

                    height:auto;

                    background: #fff;

                    text-align: left;

                    line-height:normal;
                    display: block;

                }

                .column__content{

                    padding-top:15px;
                    
                    width: 354px;

                    .item{

                        float:left;

                        height:35px;

                        line-height:37px;

                        font-size:15px;

                        width: 30%;

                        text-align: center;

                        margin-bottom:11px;

                        background: #fff;

                        color:#666;

                        margin-right:11px;

                        padding: 0 10px;

                        p{
                            overflow: hidden;
                            white-space:nowrap;
                            text-overflow: ellipsis;
                            text-align: center;
                        }

                        &:active{

                            opacity:0.6;

                        }

                        &.current-city{

                            background: transparent;

                            min-width: 30%;

                            padding: 0 15px;

                            text-align: center;

                            margin-bottom:0;

                            color:#00a0e4;

                            &:after{

                                border-color:#00a0e4;

                            }

                            &:active{

                                opacity:1;

                            }

                        }

                    }


                }

            }

        }

    }

}
</style>
