export default{

    methods: {

        back(){

            // if(this.$store.state.routerStore.lastPage && this.$route.query['stack-key'] == this.$store.state.routerStore.lastPage){

            //     this.$router.replace({name:"home"});

            // }else{

            
            // }

            this.$router.back();

        },

    },
    


}