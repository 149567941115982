<template>
  <div class="my-favorite-wrapper">
    <div class="my-favorite-cover flex-box-column">
      <div class="hd">
        <van-nav-bar
          left-arrow
          safe-area-inset-top
          title="我的收藏"
          @click-left="back"
        ></van-nav-bar>
      </div>
      <div class="bd" ref="favoriteScrollView">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :offset="100"
          @load="onLoad"
          :error.sync="isErr"
          error-text="请求失败，点击重新加载"
        >
          <div
            class="house-item border_bottom"
            v-for="(item, index) in houseList"
            :key="index"
            @click="gotoDetail(item.houseId)"
          >
            <div class="house-item-thumb">
              <img
                :alt="item.houseName"
                v-lazy="$getImageUrl(item.buildingDiagram)"
              />
              <div class="video-icon p-a-m-c" v-if="item.buildingVideo">
                <van-icon name="system-icon_play" />
              </div>
              <div class="addr" v-if="item.regionName">
                {{ item.regionName }}
              </div>
              <div class="vr-icon" v-if="item.buildingVr">
                <van-icon name="system-vr-2" />
              </div>
            </div>
            <div class="house-item-ctn">
              <p class="house-name">{{ item.houseName }}</p>
              <p class="house-price">{{ item | totalPrice }}</p>
              <p class="house-type" v-if="item.tags && item.tags.length > 0">
                <span v-for="(_item, _index) in item.tags" :key="_index">{{
                  _item
                }}</span>
              </p>
              <p class="house-record-text" v-if="item.incentiveTimeStr">
                <span>{{ item.incentiveTimeStr }}</span>
              </p>
              <p class="house-brokerage-text">
                {{
                  parseInt(item.commissionPoint)
                    ? housePointTeset(item.commissionPoint)
                      ? item.commissionPoint
                      : "佣金 " + item.commissionPoint + "%"
                    : ""
                }}
              </p>
              <p
                class="house-brokerage-text"
                v-if="item.reportProtect && loginReady"
              >
                报备保护期：{{ item.reportProtect | protectValue }}
              </p>
              <p
                class="house-brokerage-text"
                v-if="item.takeLookProtect && loginReady"
              >
                带看保护期：{{ item.takeLookProtect | protectValue }}
              </p>
              <p
                class="house-brokerage-text"
                v-if="item.chengjiaoDesc && loginReady"
              >
                报备渠道：{{ item.chengjiaoDesc }}
              </p>
            </div>
          </div>
        </van-list>
        <van-empty
          :description="
            isErr ? '获取收藏楼盘失败,点我重新获取' : '没有收藏的楼盘'
          "
          @click.native="getFavoriteData"
          v-if="(!houseList || houseList.length == 0) && inited"
        />
      </div>
    </div>
  </div>
</template>

<script>
import backMixin from "@/mixins/back";
import api from "@/api";
export default {
  mixins: [backMixin],

  data() {
    return {
      houseList: [],

      scrollTop: 0,

      inited: false,

      isErr: false,

      lock: false,
      loading:false,
      //是否到底
      finished:false,
      //页码
      page:1,
      //一次获取的数量
      size:8,
    };
  },

  filters: {
    totalPrice(item) {
      return item.packagePrice && parseFloat(item.packagePrice)
        ? item.packagePrice.replace(".00", "") + "万/套"
        : item.averagePrice
        ? item.averagePrice + "元/㎡"
        : "暂无";
    },
    protectValue(val) {
      if (!val) return "";
      if (val > 24) {
        return `${Math.floor(val / 24)} 天 ${Math.round(val % 24)} 小时`;
      } else {
        return `${val} 小时`;
      }
    },
  },

  methods: {
    onLoad() {
        this.getFavoriteData();
    },
    gotoDetail(id) {
      this.scrollTop = this.$refs.favoriteScrollView.scrollTop;

      this.$nextTick(() => {
        this.$router.push({ path: `/house/detail/${id}` });
      });
    },
    housePointTeset(data){
        let reg = /%/g;
        return reg.test(data);
    },
    getFavoriteData() {
      if (this.lock) return;

      this.lock = true;

      this.$toast.loading({
        duration: 0,

        message: "",

        overlay: true,
      });

      api.favoriteHouseList({
        options:{
            page:this.page,
            pageSize:this.size
        },
        success: (res) => {
          if (res.type == 200) {
            this.houseList = res.data.list;
            this.isErr = false;
            this.error=false;
            let last = this.$listLastCheck(res.data.list,this.size);
            if(!last){
               this.page++;
            }
            this.finished=last;
          } else {
            this.$toast({
              message: res.msg || "获取收藏楼盘列表失败",
              position: "bottom",
            });

            this.isErr = true;
          }
        },

        error: (err) => {
          console.log(err);
          this.isErr = true;
          this.$toast({
            message: "获取收藏楼盘列表失败",
            position: "bottom",
          });
        },

        complete: () => {
          this.$toast.clear();
          this.loading=false;
          this.inited = true;
          this.lock = false;
          this.$store.commit("setRouterData", {
            key: "favoriteListRefresh",
            value: false,
          });
        },
      });
    },
  },

  activated() {
    if (this.scrollTop) {
      this.$refs.favoriteScrollView.scrollTop = this.scrollTop;
    }

    if (this.$store.state.routerStore.favoriteListRefresh) {
      this.getFavoriteData();
    }
  },

  mounted() {
    this.getFavoriteData();
  },
};
</script>

<style scoped lang="less">
.my-favorite-wrapper {
  height: 100%;

  .my-favorite-cover {
    height: 100%;

    .bd {
      flex: auto;

      overflow: hidden;

      overflow-y: auto;

      padding-bottom: 30px;

      .house-item {
        padding: 20px 15px;

        display: flex;

        .house-item-thumb{

                width: 35%;

                height:90px;

                margin-right:10px;

                position: relative;

                .addr{

                    position: absolute;

                    left:0;

                    bottom:0;

                    padding: 2px 10px 3px;

                    background:rgba(0,0,0,.7);

                    color:#fff;

                }

                img{

                    width: 100%;

                    height:100%;

                }

                .video-icon{

                    width: 30px;

                    height:30px;

                    line-height:32px;

                    background: rgba(0,0,0,.4);

                    border-radius: 50%;

                    text-align: center;

                    color:#fff;

                    .van-icon{

                        font-size:14px;

                    }

                }

                .vr-icon{

                    position: absolute;

                    right:3px;

                    bottom:3px;

                    color:#fff;



                    .van-icon{

                        font-size:16px;

                        text-shadow:0 0 1px rgba(0,0,0,.4);

                    }

                }

            }


        .house-item-ctn{

                line-height:22px;

                color:#555555;

                flex:1;

                width: 0;

                display: flex;

                flex-direction: column;

                .house-name{

                    font-size:16px;

                }

                .house-price{

                    font-size:13px;

                }

                .house-type{

                    span{

                        padding-right:5px;

                        margin-left:5px;

                        position: relative;

                        &:after{

                            content:'';

                            position: absolute;

                            right:0;

                            top:45%;

                            width: 1px;

                            height:60%;

                            background: #eee;

                            transform: translateY(-50%);

                        }

                        &:first-child{

                            margin-left:0;

                        }

                        &:last-child:after{

                            display: none;

                        }

                    }

                }

                .house-record-text{
                    
                    padding: 5px 0;

                   span{

                       display: inline-block;

                        line-height:20px;

                    padding: 3px 5px 1px;

                    background: #f6f6f6;

                    width: auto;

                    font-size:12px;

                    color:#999999;

                   }


                }

                .house-brokerage-text{

                    padding-top:5px;
                    
                    color:#00a0e4;

                    font-size:14px;

                    letter-spacing: 1px;

                }


                

            }
      }
    }
  }
}
</style>
