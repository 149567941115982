<template>
   <div class="staff-list-wrapper">
       <div class="flex-box-column staff-list-cover">
            <div class="hd">
                 <van-nav-bar left-arrow safe-area-inset-top title="员工管理"  right-text="添加" class="search" @click-left="back" @click-right="openStaffAddDialog">
                </van-nav-bar>
                <div class="search-cover">
                    <van-search v-model="keyword" class="sm border" shape="round" placeholder="搜索员工" @input="onSearch" ref="recordListSearch"/>
                </div>
            </div>
            <div class="bd" ref="staffListScrollView">
                <van-empty image="error" description="获取数据失败,点击重试" @click.native="getStaffList" v-if="pageErr" />
                <van-index-bar :sticky="false" highlight-color="#333">
                    <template v-for="(item,index) in renderList">
                        <div :key="index">
                            <van-index-anchor :index="item.anchor" v-if="item.anchor"/>
                            <van-swipe-cell v-for="(_item,_index) in item.list" :key="_index">
                                <van-cell title-class="p-b-10" @click.stop="staffRecordListShow(_item)">
                                    <template #icon>
                                        <div class="avatar" :class="{man:_item.sex==1 || !_item.sex,woman:_item.sex==2}"></div>
                                    </template> 
                                    <template #title>
                                        <span>{{_item.name}}</span>
                                        <span class="phone">{{_item.phone}}</span>
                                    </template>  
                                    <template #label>
                                        <span>{{_item.shopname}}</span>
                                    </template>
                                </van-cell>
                                <template #right>
                                    <a :href="'tel:'+_item.phone" class="tel">拨打</a>
                                    <van-button square text="修改信息" color="#00a0e4" @click="staffChangeDialogOpen(_item)" />
                                    <van-button square text="解绑公司" color="#ddd" @click="deleteStaff(_item)" />
                                </template>
                            </van-swipe-cell>
                            <van-empty image="search" description="没有对应员工信息" v-if="item.empty"/>
                        </div> 
                    </template>
                <van-empty description="没有员工信息" v-if="renderList.length == 0 && !pageErr"/>
                </van-index-bar>
            </div>
       </div>
        <!-- 员工添加修改 -->
       <van-dialog v-model="staffAddDialogShow" title="添加员工" :beforeClose="staffAddConfirm" show-cancel-button confirmButtonColor="#00a0e4">
            <div class="record-change-dialog">
                <van-form label-width="4.5em">
                    <van-field
                    v-model="form.name"
                    autosize
                    label="姓名"
                    :placeholder="`请填写${setStaffType}姓名`"
                    /> 
                    <van-field
                    v-if="distributorControl"
                    v-model="form.shopname"
                    autosize
                    label="门店名称"
                    placeholder="请填写门店名称"
                    />
                    <van-field
                    v-model="form.phone"
                    autosize
                    label="手机号码"
                    type="tel"
                    :placeholder="`请填写${setStaffType}手机号码`"
                    />
                    <van-field name="switch" label="性别">
                        <template #input>
                            <van-radio-group v-model="form.sex" direction="horizontal">
                            <van-radio name="1">男</van-radio>
                            <van-radio name="2">女</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <div class="tips p-bottom-none text-center"><van-button color="linear-gradient(to right, #44c8ff, #00a0e4)" size="small"  @click="batchAddDialogOpen">批量添加{{setStaffType}}数据</van-button>
                    </div>
                    <div class="tips">添加规则：<span class="important">总账号</span>添加的为<span class="important">店长(经理)账号</span>，<span class="important">店长(经理)账号</span>添加的账号为<span class="important">店长所属业务员账号</span>，请根据员工类型选择账号添加。<br>每个店长对应一个门店，总账号可看到所有店长的数据，店长账号可看到对应的下属业务员的数据。<br>您当前的账号类型为：<span class="important">{{distributorControlCn}}</span> 账号<br>可添加的账号类型为：<span class="important">{{setStaffType}}</span> 账号</div>
                </van-form>
            </div>
       </van-dialog>
       <!-- 店长删除确认 -->
       <van-dialog v-model="removeDialogShow" title="店长解绑操作" show-cancel-button confirmButtonText="转移员工" confirmButtonColor="#00a0e4" @confirm="staffMoveStart">
           <p class="remove-tips">解绑店长时，需要转移其下属员工到其他店长账号，也可以点击下面的按钮连同下属一并删除<span class="danger">(此操作不可恢复)</span>。</p>
           <div class="remove-button"><span class="danger-bg" @click="deleteManageAll"><van-icon name="info-o" />连同下属一并删除</span></div>
       </van-dialog>
       <!-- 员工转移弹窗 -->
        <van-dialog v-model="moveDialogShow" title="员工转移" show-cancel-button confirmButtonText="确定转移" confirmButtonColor="#00a0e4" @close="resetManageData" @confirm="staffMoveConfirm">
           <div class="tips">
               <van-cell title="转移至" size="large" label="请选择对应的店长" is-link @click="manageShowPicker=true" :value="activeMoveManage.name" />
           </div>
       </van-dialog>
       <!-- 智能识别窗口 -->
       <van-dialog v-model="batchAddDialogShow" :title="'批量添加'+setStaffType" show-cancel-button confirmButtonText="识别" confirmButtonColor="#00a0e4" @close="resetbatchAddData" @confirm="batchAddConfirm">
           <div class="batch-add-dialog-cover">
               <div class="paste-cover">
                    <textarea placeholder="请将员工信息，按照规定格式复制到识别框内" v-model="batchAddMessage"></textarea>
                    <div class="toolbar flex-box-row-space-between">
                        <div class="tips p-left-none btn" @click="batchDemoShow">参考格式</div>
                        <div class="tips p-right-none btn" @click="resetbatchAddData">清空</div>
                    </div>
                </div>
           </div>
       </van-dialog>
       <!-- 选择要转移的店长 -->
       <van-popup v-model="manageShowPicker" round position="bottom">
        <van-picker
            show-toolbar
            :columns="manageColumns"
            @cancel="manageShowPicker = false"
            @confirm="manageConfirm"
        />
       </van-popup>
       <!-- 员工信息修改 -->
       <van-dialog v-model="staffChangeDialogShow" title="员工信息修改" :beforeClose="staffChangeConfirm" show-cancel-button confirmButtonColor="#00a0e4">
            <div class="record-change-dialog">
                <van-form label-width="4.5em">
                    <van-field
                    v-model="changeForm.name"
                    autosize
                    label="姓名"
                    :placeholder="`请填写员工姓名`"
                    /> 
                    <van-field
                    v-if="distributorControl"
                    v-model="changeForm.shopname"
                    autosize
                    label="门店名称"
                    placeholder="请填写门店名称"
                    />
                    <van-field
                    v-model="changeForm.phone"
                    autosize
                    label="手机号码"
                    type="tel"
                    :placeholder="`请填写员工手机号码`"
                    />
                    <van-field name="switch" label="性别">
                        <template #input>
                            <van-radio-group v-model="changeForm.sex" direction="horizontal">
                            <van-radio :name="1">男</van-radio>
                            <van-radio :name="2">女</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <div class="tips">该员工类型为：<span class="main-color">{{changeForm.distributorStaffType == 'DIANZHANG' ? '店长(经理)' : changeForm.distributorStaffType=='YUEWUYUAN' ? '经纪人' : ''}}</span></div>
                </van-form>
            </div>
       </van-dialog>
   </div>
</template>

<script>
import backMixin from '@/mixins/back';
import permissionCheckMixin from "@/mixins/permissionCheck";
import api from "@/api";
import {isPhone,isArray,cloneDeep} from "@/utils";
export default {

    mixins:[backMixin,permissionCheckMixin],


    data(){

        return {

            //搜索关键字

            keyword:'',

            //员工列表

            staffList:[],

            //员工搜索列表

            searchList:[],

            //店长列表原始数组

            manageList:[],

            //页面错误

            pageErr:false,

            //选择删除manageId

            activeManageId:'',

            //选择要转移的店长

            activeMoveManage:{},

            //员工添加弹窗

            staffAddDialogShow:false,

            //店长删除弹窗

            removeDialogShow:false,

            //员工移动弹窗

            moveDialogShow:false,

            //对应店长下拉展示

            manageShowPicker:false,

            //用户添加提交表单

            form:{

                sex:'1'

            },

            //选择用店长列表

            manageColumns:[],

            //批量添加窗口

            batchAddDialogShow:false,

            //记录滚动

            scrollTop:0,

            batchAddMessage:'',

            


            //员工信息修改

            staffChangeDialogShow:false,

            //员工修改提交表单

            changeForm:{}

        }

    },

    computed:{
        renderList(){
            return this.searchList.length>0 ? this.searchList : this.staffList;
        },

        distributorControl(){
            return this.$store.state.userInfo.distributorControl;
        },

        //是否为开发商总账号
        distributorControlCn(){
            return this.$store.state.userInfo.distributorStaffType == 'DIANZHANG' ? this.distributorControl ? '管理员' : '店长(经理)' : '店长(经理)';
        },
        setStaffType(){
            return this.$store.state.userInfo.distributorStaffType == 'DIANZHANG' ? this.distributorControl ?  '店长(经理)' : '业务员' : '业务员';
        }

    },


    methods:{

        onSearch(keyword){

            let searchList= [{list:[]}];

            this.scrollTop = this.$refs.staffListScrollView.scrollTop =0;

            if(keyword){

                //搜索用户

                this.staffList.forEach(el=>{

                    el.list && el.list.forEach(_el=>{

                        if(_el.name.indexOf(keyword) !=-1 || _el.phone.indexOf(keyword) != -1){

                            searchList[0].list.push(_el);

                        }

                    })

                });

                if(searchList[0].list.length == 0){

                    searchList[0].empty = true;

                }

            }else{

                searchList = [];

            }

            this.searchList = searchList;
            
        },

        init(){

            this.getStaffList();

        },

        //获取用户列表

        getStaffList(callback){

            this.$toast.loading({

                overlay:true,

                duration:0,

                message:"加载中..."

            });

            api.getStaffList({

                success:(res)=>{

                    if(res.type == 200){

                        this.staffList = res.data;

                        this.pageErr=false;

                        this.manageList = [];
                        
                        //序列化店长列表
                        
                       isArray(res.data) && res.data.forEach(el=>{

                           isArray(el.list) && el.list.forEach(_el=>{

                               if(_el.distributorStaffType == 'DIANZHANG'){

                                   _el.text = `${_el.name}(${_el.shopname})`

                                   this.manageList.push(_el);

                               }

                           })
                           
                       });

                       this.$refs.staffListScrollView.scrollTop=0;

                       this.$store.commit('setRouterData',{key:'isStaffListRefresh',value:false});

                       callback && callback();

                    }else{

                        this.$toast({

                            type:'fail',

                            message:res.msg || '获取员工数据失败'

                        })
                        this.pageErr=true;

                    }

                    

                },

                error:(err)=>{

                    console.log(err);

                    this.pageErr=true;

                      this.$toast({

                            type:'fail',

                            message: err || '获取员工数据失败'

                        })

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },

        //删除用户

        deleteStaff(item){

            const isManage = item.distributorStaffType == 'DIANZHANG'  ? true : false;

            //判断是否为店长账号

            if(isManage){

                 this.$dialog.confirm({

                    title:'解绑店长',

                    message:`${item.name} 为店长账号，确定要解绑吗？`,

                    confirmButtonColor:"#00a0e4"

                }).then(()=>{

                    //设置id

                    this.activeManageId = item.uid;
                    
                    //触发弹窗事件

                    this.removeDialogShow=true;

                }).catch(()=>{

                    this.activeManageId = '';

                })


            }else{

                this.$dialog.confirm({

                    title:'解绑员工',

                    message:`确定要解绑 ${item.name} 吗?\n解绑员工后不可恢复`,

                    confirmButtonColor:"#00a0e4"

                    }).then(()=>{

                        
                    this.deleteStaffPost(item.uid);
            


                }).catch(()=>{})

            }

         

        },

        /**
         * @param id 用户id
         * 
         * @param newUid 用户需要转移到的店长id
         * 
         * 
         */

        deleteStaffPost(uid,newUid){

                  this.$toast.loading({

                    duration:0,

                    message:"正在解绑...",

                    overlay:true

                });

                api.removeStaff({

                    options:{uid,newUid},

                    success:(res)=>{

                        if(res.type == 200){

                            this.$notify({ type: 'success', message: '员工解绑成功' });

                            this.getStaffList(()=>{

                                this.onSearch(this.keyword);

                            });

                            
                        

                        }else{

                            this.$dialog.alert({

                                title:'删除失败',

                                message:res.msg || '员工解绑失败，请稍后重试',

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{});

                            
                        }

                    },


                    error:(err)=>{

                        console.log(err);

                        this.$dialog.alert({

                            title:'删除失败',

                            message:'员工解绑失败,发生异常，请稍后重试',

                            confirmButtonColor:"#00a0e4"

                        }).then(()=>{});

                    },

                    complete: ()=> {

                        this.$toast.clear();

                        //清空

                        this.activeManageId = '';

                        this.activeMoveManage = {};
                        
                    }

                })

        },

        //店长连带员工一并删除

        deleteManageAll(){

            //显示确认按钮

            this.$dialog.confirm({

                title:"解绑店长及下属员工",

                message:'确认要解绑店长及下属员工吗？此操作不可恢复',

            }).then(()=>{

                this.removeDialogShow = false;

                this.deleteStaffPost(this.activeManageId);

            })

        },

        //打开员工添加弹窗

        openStaffAddDialog(){

            this.staffAddDialogShow = true;

        },

        //打开员工转移弹窗

        staffMoveStart(){

            //生成对应的列表(剔除当前的店长);

            this.manageColumns = this.manageList.filter(el=>{

                return el.uid != this.activeManageId;

            })

            this.moveDialogShow=true;

        },

        //打开员工修改弹窗

        staffChangeDialogOpen(item){

            this.changeForm = item;

            this.staffChangeDialogShow=true;

        },

        //确认转移员工

        staffMoveConfirm(){

            this.deleteStaffPost(this.activeManageId,this.activeMoveManage.uid);

        },

        //确认添加员工

        staffAddConfirm(action,done){

            if(action == 'confirm'){

                //检查一下样式

                if(!this.form.name){

                    this.$notify({ type: 'warning', message: `请填写${this.setStaffType}姓名` });

                    done(false);

                    return false;

                }
                
                if(!this.form.shopname && this.distributorControl){

                    this.$notify({ type: 'warning', message: `请填写门店名称` });

                    done(false);

                    return false;

                }else if(!this.distributorControl){

                    this.form.shopname = this.$store.state.userInfo.shopname;

                }

                if(!this.form.phone){

                    this.$notify({ type: 'warning', message: `请填写${this.setStaffType}手机号码` });

                    done(false);

                    return false;

                }

                if(!isPhone(this.form.phone)){

                    this.$notify({ type: 'warning', message: '手机号码格式错误' });

                    done(false);

                    return false;

                }


                api.addStaff({

                    options:this.form,

                    success:(res)=>{

                        if(res.type == 200){
                            
                            this.$dialog.alert({

                                title:`${this.setStaffType}添加成功`,

                                message:`${this.setStaffType}添加成功，登录密码为手机后六位，可以关注公众号后直接登录，登录后请立即更改密码，以保证数据安全。`,

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{

                                this.searchList=[];

                                this.keyword ='';

                                this.form = {

                                    sex:'1'

                                }

                                //更新经纪人列表,记住此处必须重新获取一次经纪人列表

                                this.getStaffList();
                                
                            })

                        }else{

                            this.$notify({ type: 'danger', message:res.msg ||  `${this.setStaffType}添加失败，请稍后重试` });

                        }


                    },

                    error:(err)=>{

                        console.log(err);

                        this.$notify({ type: 'danger', message:`${this.setStaffType}添加失败, 请求异常，请稍后重试` });

                    },

                    complete:()=>{

                        done(true);

                    }

                })

            }else{

                done(true);

            }

        },

        //确认修改员工信息

        staffChangeConfirm(action,done){

            if(action == 'cancel'){

                this.changeForm = {};

                done(true);

            }else{

                let options = cloneDeep(this.changeForm);
                
                api.updateStaff({

                    options:options,

                    success:(res)=>{

                        if(res.type == 200){

                            done(true);
                            
                            this.$dialog.alert({

                                title:'修改成功',

                                message:'员工信息修改成功',

                                confirmButtonColor:'#00a0e4'

                            })

                            this.getStaffList();

                        }else{

                            done(false);

                            this.$dialog.alert({

                                title:'提交失败',

                                message:res.msg || '提交员工数据失败，请稍后重试'

                            })

                        }

                    },

                    error:(err)=>{

                        done(false);

                        console.log(err);

                        this.$dialog.alert({

                            title:'提交失败',

                            message:'提交员工数据失败，'+err

                        })

                    }

                })


            }

        },

        //选择对应店长

        manageConfirm(val){

            this.activeMoveManage = val;

            this.manageShowPicker=false;

        },

        //清除掉对应的选择店长

        resetManageData(){

            this.activeManageId = '';

            this.activeMoveManage = {};

        },

        //跳转到对应员工的报备列表

        staffRecordListShow(item){

            this.scrollTop = this.$refs.staffListScrollView.scrollTop;

            this.$nextTick(()=>{

                this.$router.push({name:'subDistributorRecordList',params:{name:item.name,id:item.uid}});

            })

        },

        //打开员工批量界面

        batchAddDialogOpen(){

            this.batchAddMessage= '';

            this.staffAddDialogShow=false;

            this.$nextTick(()=>{

                this.batchAddDialogShow=true;

            })

        },

        //关闭员工批量界面

        resetbatchAddData(){

            this.batchAddMessage='';
            
        },

        batchDemoShow(){

            this.$dialog.alert({

                title:'参考格式',

                message: this.distributorControl ?  '姓名，手机号码，性别，门店名称,例:\n\n小李，13333333331，男，分组一\n小李，13333333331，男，分组一' : '姓名，手机号码，性别,例:\n\n小李，13333333331，男\n小李，13333333331，男',

                confirmButtonText:'我知道了',

                confirmButtonColor:'#44c8ff',

                messageAlign:'left'

            })

        },

        //确认提交

        batchAddConfirm(){

            if(!this.batchAddMessage){

                this.$toast({

                    message:'识别内容不能为空',

                    position:'bottom'

                });

                return;

            }

            this.$toast.loading({

                message:'正在识别...',

                forbidClick:true,

                duration:0

            });

            let message = this.batchAddMessage.replace(/\n/g,"<br>");

             api.getDemoUserData({

                options:{
                    
                    message:message

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.batchAddDialogShow = false;

                        this.$nextTick(()=>{

                            this.$router.push({name:'staffBatchList',params:{list:res}});

                        })
                        
                    }else{

                        this.$dialog.alert({

                            title:'识别失败',

                            message:res.msg || '识别失败，请稍后重试'

                        })

                    }

                },

                error:(err)=>{

                    console.log(err);

                     this.$dialog.alert({

                        title:'识别失败',

                        message:`识别失败，请稍后重试，error:${err}`

                    })


                },

                complete: ()=> {

                    this.$toast.clear();
                    
                }

            })

        }

    },

    activated(){

        if(this.scrollTop){

            this.$refs.staffListScrollView.scrollTop = this.scrollTop;

        }

        if(this.$store.state.routerStore.isStaffListRefresh){

            this.getStaffList();

        }
        

    },


    created(){

        this.$nextTick(()=>{

            //检查一下使用权限

            let check = this.permissionCheck();

            if(check){

                this.init();

            }


        })

    }

}
</script>

<style lang="less">

 .staff-list-wrapper{

     height:100%;

        .tips{

            padding: 10px 15px;

            font-size:12px;

            color:#999;

            line-height:20px;

            &.p-top-none{

                padding-top:0;

            }
            
            &.p-bottom-none{

                padding-bottom:0;

            }

            &.p-right-none{

                padding-right:0;

            }
            
            &.p-left-none{

                padding-left:0;

            }

            &.btn{

                &:active{

                    opacity: .8;

                } 

            }

        }

        .record-change-dialog{

            .van-dialog__content{

                max-width: 70vh;

                overflow: hidden;

                overflow-y:auto;

            }

        }

        .remove-button{

            text-align: center;

            padding: 10px 0 20px;

            font-size:12px;

            span{

                display: inline-block;

                padding: 5px 10px;

                border-radius:4px;

                line-height:20px;

                .van-icon{

                    vertical-align: middle;

                    padding-right:5px;

                    font-size:14px;

                    margin-top:-2px;

                }

            }

        }

        .remove-tips{

            padding: 15px;

            font-size:14px;

            color:#666666;

            line-height:24px;

        }

    .staff-list-cover{

        height:100%;

   

        .search-cover{

            width: 65%;

            margin: 0 auto;

 

        }



        .bd{

            flex:1;

            height:0;

            position: relative;

            overflow: hidden;

            overflow-y:auto;

            background: #f6f6f6;

            padding-bottom:50px;

            .van-empty{

                background: #fff;

            }

            .van-button{

                height:100%;

            }

            .van-index-anchor{

                padding: 0 30px;

            }

            .van-swipe-cell__wrapper{

                background: #fff;

                .tel{

                    width: 60px;

                    display: inline-flex;

                    align-items: center;

                    justify-content: center;

                    background: #09dd70;

                    color:#fff;

                    height:100%;

                    vertical-align: top;

                    font-size:15px;

                    line-height: 1.2;

                }

            }


            .van-cell{

                align-items: center;

                padding: 5px 0 5px 16px;

                .phone{

                    font-size:12px;
                    padding-left:5px;

                    color:#999;

                }

                &:after{

                    right:0;

                    left:55px;

                }

                .avatar{

                    width: 38px;

                    height:38px;

                }

                .van-cell__title{

                    padding-left:15px;

                    padding-bottom:0;

                }

            }
               
        }
        

    }

    .batch-add-dialog-cover{

        .paste-cover{

            width: 100%;

            border-radius: 4px;

            overflow: hidden;

            padding: 15px 10px;

            textarea{

                width: 100%;

                border-radius: 4px;

                           height:200px;

                resize:none;

                padding: 10px;

                border:1px solid #eee;

                overflow: hidden;

                overflow-y:auto;

                background: #f8f8f8;

                color:#666666;

                transition:border-color .5s;

                &:focus{

                    border-color:#5aceff;

                }

            }

    }

    }
    

 }

</style>
