<template>
   <div class="aboutus">
       <div class="aboutus-cover flex-box-column">
           <div class="hd">
               <van-nav-bar left-arrow safe-area-inset-top :title="'关于'+mainTitle" @click-left="back"></van-nav-bar>
           </div>
           <div class="bd">
                <div class="aboutus-cover__title">
                    <div class="logo" 
                    :style="'background-image:url('+logoBg+');background-color:'+mainColor+';'"></div>
                    <div class="title">{{mainTitle}}</div>
                </div>
                <div class="aboutus-cover__content">
                    <van-cell title="用户协议及隐私政策" is-link :to="{name:'userAgreement'}"/>
                    <van-cell title="客服电话" :url="kfPhone ? 'tel:'+kfPhone : 'javascript:'" is-link/>
                </div>
           </div>
           <div class="footer">{{mainTitle}} 版权所有</div>
       </div>
   </div>
</template>

<script>
import backMixin from "@/mixins/back"
export default {

    mixins:[backMixin],
    
    data(){

        return{

            logo:require('@/assets/images/aboutLogo.png'),

            logoHm:require('@/assets/images/aboutLogo_hm.png')

        }

    },

    computed:{

        kfPhone(){

            return this.$store.state.userInfo.kefPhone;

        },
        
        mainTitle(){

            return this.$store.state.userInfo?.company?.title;

        },

        logoBg(){

            return this.$getImageUrl(this.$store.state.userInfo?.company?.subLogo);

        },
        mainColor(){
            return this.$store.state.userInfo?.company?.color;
        },


    }

}
</script>

<style scoped lang="less">
.aboutus{

    height:100%;

    .aboutus-cover{

        height:100%;

        justify-content: space-between;
        
        .bd{

            flex:1;

            height:0;

            overflow: hidden;

            overflow-y: auto;

            padding: 10px 15px;

            .aboutus-cover__title{

                display: flex;

                flex-direction: column;

                justify-content: center;
                
                align-items: center;

                min-height:160px;

                padding-bottom:30px;

                padding-top:10px;

                .logo{

                    width: 100px;

                    height:100px;


                    margin-bottom:10px;

                    border-radius:5px;

                    background:#00a0e4 url('../../assets/images/aboutLogo.png') no-repeat center center / 80% 80%;

                    &.hmBg{

                        background-color:#ffae00;

                    }

                }

                .title{

                    font-size:20px;

                    color:#777;

                }

            }

        }

        .footer{

            font-size:13px;

            text-align: center;

            line-height:24px;

            padding-bottom:5px;

        }

    }

}
</style>
