<template>
   <div class="calculator-wrapper">
      <div class="flex-cover">
         <div class="hd">
            <van-nav-bar left-arrow safe-area-inset-top title="房贷计算器" @click-left="back"></van-nav-bar>
         </div>
         <div class="bd">
            <iframe src="https://www.fxyos.com/computer/?hide=1" frameborder="0" class="iframe-cover"></iframe>
         </div>
      </div>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
export default {

   mixins:[backMixin]

}
</script>

<style scoped lang="less">
.calculator-wrapper{

   width: 100%;

   height:100%;

   .flex-cover{

      height:100%;

      flex-direction: column;

   }

   .bd{

      flex:1 1 auto;

        .iframe-cover{

            width: 100%;

            height:100%;

      }

   }

}
</style>
