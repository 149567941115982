<template>
    <div class="user-info-wrapper">
       <div class="user-info-cover flex-box-column">
           <div class="hd">
               <van-nav-bar left-arrow safe-area-inset-top title="帮助与反馈" @click-left="back" right-text="提交" @click-right="updateData">
                </van-nav-bar>
           </div>
           <div class="bd">
               <div class="form-item">
                    <van-field v-model="value" placeholder="请输入需要反馈的信息，我们会及时响应" type="textarea" rows="5" autosize/>
                    <div class="tips">如需反馈系统使用问题<br>
                        请先尝试升级微信版本或浏览器版本，如果还是不行，请填写遇到的问题，并附带使用的手机型号。</div>
               </div>
           </div>
       </div>
   </div>
</template>

<script>
import api from "@/api";
import backMixin from "@/mixins/back";
export default {

    mixins:[backMixin],

    data(){

        return {

            value:''

        }

    },

    methods:{

        updateData(){

            if(!this.value){

                this.$toast({

                    message:"反馈内容不能为空",

                    position:"bottom"

                })

                return;

            }
            
            
            this.$toast.loading({

                message:'提交中...',

                duration:0

            });


            api.help({

                options:{

                    content:this.value

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.$store.commit('setUserData',{

                            key:this.$route.params.type,

                            value:res.data[this.$route.params.type]

                        });

                        this.$dialog.alert({

                            title:'感谢使用',

                            message:'我们已经收到您提交的信息，感谢您提出的需求或建议'

                        }).then(()=>{

                            this.$nextTick(()=>{

                                this.$router.back();

                            })


                        })






                    }else{

                        this.$dialog.alert({

                            title:'提交失败',

                            message:res.msg || '提交失败，请稍后重试'

                        }).then(()=>{})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'提交失败',

                        message:'提交失败，提交异常，请稍后重试'

                    }).then(()=>{})

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        }

    }



}
</script>

<style scoped lang="less">
.bd{

    padding-top:10px;

    .tips{

        padding: 10px 15px;

        color:#999;

        line-height:24px;

    }

}
</style>
