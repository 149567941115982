export default{

    data(){

        return {

            loadingImage:require('@/assets/images/loading.png')

        }

    }

}