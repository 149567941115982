import vue from "vue";

import dataStorage from "@/utils/dataStorage";

import { Encrypt } from "@/utils/crypto";

import { isObject } from "@/utils"

const state = {

    //当前的选择的报备楼盘

    selectHouse:[],

    //备注

    remark:'',

    //到访人数

    visitorNum:null,

    //到访时间

    visitingTime:'',

    //客户列表

    customerList:[],

    //带看人名称

    dkStaffName:'',

    //带看人手机号码

    dkStaffPhone:'',

    //缓存时间(分钟)
    
    expires:20,

    //时间戳

    cacheTime:0,

    //指定接待

    reception:''
  

}

const mutations = {


    //记录当前数据状态，记录在localStorage
    
    cacheRecordData(state){

        let date = new Date();

        date.setMinutes(date.getMinutes()+state.expires);

        state.cacheTime = date.getTime();

        dataStorage.StorageSetter('record_data_cache',Encrypt(state));

    },

    //设置数据

    setRecordData(state,{key,value}){
        
        vue.set(state,key,value);

        this.commit('cacheRecordData');

    },


    /**
     * 
     * 添加数组，删除数组
     * 
     * 
     * @param key 需要更改变量名
     * 
     * @param isDelete true 为删除，否则为添加
     * 
     * @param item 需要添加的元素
     * 
     * @param index 需要删除的下标
     * 
     */

    recordArrayChange(state,{key,isDelete,item,index}){

        if(isDelete){

            state[key].splice(index,1); 

        }else{

            state[key].push(item);

        }

        this.commit('cacheRecordData');

    },

    //设置数组参数

    setArrayData(state,{key,index,value}){

        vue.set(state[key],index,value);

        this.commit('cacheRecordData');

    },

    /**
     * 合并数组
     */

    concatArrayChange(state,{key,item}){

        state[key].push(...item);

        this.commit('cacheRecordData');

    },

    //重置数组

    resetRecordData(state){

        //当前的选择的报备楼盘

        state.selectHouse=[];

        //备注

        state.remark='';

        //到访人数

        state.visitorNum=null;

        //到访时间

        state.visitingTime='';

        //客户列表

        state.customerList=[];

        //带看人名称

        state.dkStaffName = '';

        //带看人手机号码

        state.dkStaffPhone= '';

        //指定接待

        state.reception = '';

        dataStorage.StorageRemove('record_data_cache');

    },

    recoveryRecordData(state,data){

        if(!isObject(data)) return;
        
        Object.keys(data).forEach(el=>{

            vue.set(state,el,data[el]);

        })  
    
        this.commit('cacheRecordData');

    }


}

const actions = {

  

}

const getters = {

  getRecordState: (state) => (key) => {
    return state[key];
  }

}


 export default {

    state:state,
    mutations:mutations,
    actions:actions,
    getters:getters

 }