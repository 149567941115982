<template>
   <div class="record-detail-wrapper">
    <div class="record-detail-cover flex-box-column">
           <div class="hd">
            <van-nav-bar 
            left-arrow 
            safe-area-inset-top 
            title="订单详情" 
            :right-text="recordChangeTitle"
            @click-left="back"
            @click-right="recordChange">
            </van-nav-bar>
      </div>
      <div class="bd" ref="bd">
        <van-pull-refresh v-model="loading" @refresh="onRefresh">
          <div class="customer-info van-hairline--bottom" :class="{'steps-show':stepsShow}">
              <div class="customer-info-cover">
                <div class="customer-avater avatar" :class="{man:customerSex==1,woman:customerSex==2}"></div>
                <div class="ctn">
                    <div class="des van-hairline--bottom">
                        <div class="name">{{customerName}}<span class="phone f-12">{{customerPhone}}</span></div>
                        <div class="call">
                            <a v-if="customerPhone" :href="role == 'CHANNEL' || role=='CHANNELJINGLI' ? 'javascript:' : 'tel:'+customerPhone" @click.stop><van-icon v-if="role != 'CHANNEL' && role !='CHANNELJINGLI'" name="phone" class="call-icon"></van-icon></a>
                        </div>
                    </div>
                    <div class="house-name">
                        <span>{{recordData.houseName}}</span>
                        <span class="customerPic" v-if="customerPic.length>0" @click="customerPicShow"><van-icon name="photo"/>购房资质证明</span>
                    </div>
                </div>
              </div>
          </div>
          <!-- 报备进度条 -->
          <div class="steps-wrapper" v-if="stepsShow">
              <steps :list="statusList" :active="stepsIndex"></steps>
          </div>
          <div class="tabs">
            <van-tabs v-model="tabsActive" swipeable animated sticky offset-top="12.26667vw">
                <van-tab title="订单信息">
                    <div class="order-cards tabs-item" :style="getMinHeight">
                        <template v-for="(item,index) in recordData.list">
                            <div class="order-card" :key="index" v-if="recordData.list && recordData.list.length>0">
                            <div class="order-card__title">{{
                                item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE' 
                                ? '报备' : getApprovalStatusText(item.approvalStatus)
                                }}<span :class="{
                                    success:item.approvalStatus == 'RECORDCOMPLETE',
                                    faild:item.approvalStatus == 'RECORDCLOSE'
                                }" v-if="item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE'">{{item.approvalStatus == 'RECORDCLOSE' ? '无效' : '有效'}}</span></div>
                            <div class="order-card__content">
                                <div class="ocder-crad_content-cell">
                                    <span class="cell-name">审核人：</span>
                                    <a :href="'tel:'+item.userPhone"><span class="auditorNickName">{{item.userName}}</span><van-icon name="phone" class="main-color p-lr-5 record-mobile ft-16"/></a>
                                </div>    
                                <div class="ocder-crad_content-cell"
                                v-if="item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE'">
                                    <span class="cell-name">审核时间：</span>
                                    <span>{{item.createdAt || '暂无'}}</span>
                                </div>   
                                <div class="ocder-crad_content-cell"
                                v-if="item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE'">
                                    <span class="cell-name">报备时间：</span>
                                    <span>{{createdAt || '暂无'}}</span>
                                </div>   
                                <div class="ocder-crad_content-cell" v-if="item.approvalStatus !='MAKEBARGAIN'">
                                    <span class="cell-name">{{item.approvalStatus == 'VISIT' ? '' : '预计'}}到访时间：</span>
                                    <span>{{
                                        item.approvalStatus == 'VISIT' 
                                        ? item.visitTime 
                                        : item.estimatedVisitTime}}</span>
                                </div>  
                                <div class="ocder-crad_content-cell" v-if="item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE'">
                                    <span class="cell-name">到访人数：</span>
                                    <span>{{item.visitorNum}}</span>
                                </div>
                                <div class="ocder-crad_content-cell">
                                    <span class="cell-name">审核意见：</span>
                                    <span>{{item.approvalResult || '暂无'}}</span>
                                </div>       
                                <!-- <div class="ocder-crad_content-cell"
                                v-if="item.approvalStatus == 'RECORDCOMPLETE' || item.approvalStatus == 'RECORDCLOSE'">
                                    <span class="cell-name">指定接待：</span>
                                    <span>{{item.reception || '暂无'}}</span>
                                </div>   -->
                            </div>
                        </div>
                        </template>
                        <div class="order-card none" v-if="!recordData.list || recordData.list == 0">暂无对应信息</div>
                    </div> 
                </van-tab> 
                <van-tab title="二维码获取">
                    <div class="order-images tabs-item" :style="getMinHeight">
                        <div class="order-images-cover">
                            <div class="order-images-show">
                                <van-uploader 
                                accept="image/*"
                                :after-read="afterRead"/>
                                <div class="order-image" v-for="(item,index) in recordImages" :key="index" @click="imageShowOpen(index)">
                                    <img :src="item.url|$imageUrl" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="客户动态">
                    <div class="order-cards tabs-item" :style="getMinHeight">
                        <template v-for="(item,index) in recordData.userRecordList">
                        <div class="order-card" :key="index" v-if="recordData.userRecordList && recordData.userRecordList.length > 0">
                            <div class="order-card__title">{{item.createdAt || '暂无'}}</div>
                            <div class="order-card__content">
                                <div class="ocder-crad_content-cell">
                                    <span class="cell-name">记录人：</span>
                                    <span class="auditorNickName">{{item.userName}}</span>
                                </div>    
                                <!-- <div class="ocder-crad_content-cell">
                                    <span class="cell-name">记录时间：</span>
                                    <span>{{item.createdAt || '暂无'}}</span>
                                </div>           -->
                                <div class="ocder-crad_content-cell">
                                    <span class="cell-name">备注：</span>
                                    <span>{{item.remark || '暂无'}}</span>
                                </div>  
                            </div>
                        </div>  
                        </template>
                        <div class="order-card none" v-if="!recordData.userRecordList || recordData.userRecordList.length == 0">
                            暂无客户动态
                        </div>
                    </div> 
                </van-tab>
            </van-tabs>
        </div>
        </van-pull-refresh>
      </div>
       </div>
       <!-- 图片预览 -->
       <van-image-preview 
       v-model="imageShow" 
       :images="images" 
       :start-position="imageIndex"
       :max-zoom="3"></van-image-preview>
       <!-- 报备单状态修改 -->
       <van-dialog v-model="recordDialogShow" title="添加客户动态" :beforeClose="recordDialogConfirm" show-cancel-button confirmButtonColor="#00a0e4" confirmButtonText="提交">
            <div class="record-change-dialog border_top">
                <van-form label-width="8em">
                <van-field
                v-model="recordAddForm.remark"
                label-width="3em"
                rows="3"
                autosize
                label="内容"
                type="textarea"
                placeholder="内容请写在此处"
                />
                </van-form>
            </div>
       </van-dialog>
        <!-- 操作选择 -->
       <van-popup v-model="toolbarShow" round position="bottom">
        <van-picker
            show-toolbar
            title="选择操作"
            :columns="toolbarColumns"
            @cancel="toolbarShow = false"
            @confirm="toolbarConfirm"
        />
        </van-popup>
        <!-- 重新报备按钮 -->
        <div class="record-again" @click="recordAgain" v-if="recordData.isRecordAgain">
            <div class="flex-cover"><span>重新报备</span></div>
        </div>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
import imageUploadMixin from "@/mixins/imageUpload"
import { vwToPx,isObjectNone,cloneDeep } from "@/utils";
import api from "@/api";
import getStatusTextMixin from "@/mixins/getStatusText";
import steps from "@/components/common/steps";
export default {

    mixins:[backMixin,imageUploadMixin,getStatusTextMixin],

    components:{

        steps

    },

    data(){

        return {

            loading:false,

            tabsActive:0,

            minHeight:0,

            images:[],

            imageShow:false,

            imageIndex:0,

            recordDialogShow:false,

            recordAddForm:{},

            showRecordPicker:false,

            //渠道操作栏显隐

            toolbarShow:false,

            recordData:{

                list:[],

                userRecordList:[]
            },

           
        }

    },

    computed:{

        getMinHeight(){

            return {minHeight:this.minHeight+'px'};

        },

        approvalStatusPermission(){

            return !!this.recordData?.approvalStatusPermission;

        },

        /**
         * 
         * 报备单状态显示。
         * 
         * 报备专员在报备单不为关闭时显示审核，否则显示查看，则无法更改报备单
         * 
         * 报备经理全部状态都能修改所以是审核
         * 
         * 渠道可以添加动态和修改信息
         * 
         */

        recordChangeTitle(){

            return this.recordData?.permission 
            
            ? this.$store.state.userInfo.role=='AUDITOR'
            
            ? this.recordData.approvalStatus =='RECORDCLOSE' || this.recordData.approvalStatus == 'MAKEBARGAINCLOSE' 
            
            ? '查看' 
            
            : '审核' 
            
            :  this.$store.state.userInfo.role=='AUDITORADMIN' 
            
            ? '审核' 
            
            : this.$store.state.userInfo.role == 'CHANNEL' || this.$store.state.userInfo.role=="CHANNELJINGLI" || this.approvalStatusPermission
            
            ? '报备单操作' 
            
            : '添加客户动态' : '';

        },

        recordImages(){

            return this.recordData?.images || [];

        },

        //用户名称

        customerName(){

            return this.recordData?.customerInfo?.name || '';

        },

        customerPhone(){

            return this.recordData?.customerInfo?.phone || '';

        },  

        //用户相关资质列表

        customerPic(){

            return this.recordData?.customerInfo?.customerPic || [];

        },
        
        customerRuleTel(){

            return this.recordData?.customerInfo?.ruleTel || '';

        },

        customerSex(){

            return this.recordData?.customerInfo?.sex || '';

        },

        approvalStatus(){

            return this.recordData.approvalStatus || '';

        },

        role(){

            return this.$store.state.userInfo.distributorStaffType || '';

        },

        //是否显示步骤条

        stepsShow(){

            return this.recordData.approvalStatus != 'RECORDSTART' && this.recordData.approvalStatus !='MAKEBARGAINCLOSE' && this.recordData.approvalStatus !='RECORDCLOSE' && this.recordData.approvalStatus;

        },

        stepsIndex(){

            return this.statusIndex[this.recordData.approvalStatus] || this.statusIndex[this.recordData.approvalStatus] == 0 ? this.statusIndex[this.recordData.approvalStatus] : -1;

        },

        toolbarColumns(){

            return (this.approvalStatus == 'RECORDSTART' || this.approvalStatus == 'RECORDSTANDBY' ||  this.approvalStatus == 'MAKEBARGAINCLOSE' || this.approvalStatus=='RECORDCLOSE') ?  [

                {text:"添加客户动态",value:0}

            ] : this.$has('RECORDEDIT',true) || this.approvalStatusPermission ? [
                
                {text:"更改报备单状态",value:1},

                {text:"添加客户动态",value:0}

            ] : 

            [{text:"添加客户动态",value:0} ]

        },

        //步骤条进度显示内容

        statusList(){

            return this.role == 'DIANZHANG' || this.role=='YUEWUYUAN' ? ['等待开发商审核','报备有效']  : ['处理中','已报备','未到访','已到访','成交','确认成交']

        },

        //状态对应序号

        statusIndex(){

            return this.role == 'DIANZHANG' || this.role=='YUEWUYUAN' ? {

                //处理中

                'RECORDSTANDBY':0,

                //报备完成

                'RECORDCOMPLETE':1,

                //未到访

                'UNVISIT':1,

                //已到访

                'VISIT':1,

                //成交

                'MAKEBARGAIN':2,

                //已成交

                'MAKEBARGAINCOMPLETE':3

            } : {

                
                //处理中

                'RECORDSTANDBY':0,

                //报备完成

                'RECORDCOMPLETE':1,

                //未到访

                'UNVISIT':2,

                //已到访

                'VISIT':3,

                //成交

                'MAKEBARGAIN':4,

                //已成交

                'MAKEBARGAINCOMPLETE':5

            }

        },

        createdAt(){
            return this.recordData?.createdAt;
        }

    },

    methods:{

        //上传图片

        afterRead(file) {

            const _check = this.beforeThumbUpload(file.file);

            if(!_check) return;

            const formData = new FormData();         
            
            formData.append('file',file.file);

            api.upload({

                options:formData,

                success:(res)=>{

                    if(res.type == 200){

                        api.uploadRecordImage({

                            options:{

                               url:res.data.url,

                               id:this.$route.params.id

                            },

                            success:(_res)=>{

                                if(_res.type == 200){

                                    this.recordImages.push(_res.data);

                                }else{

                                     this.$notify({ type: 'danger', message: _res.msg || '图片上传失败，请稍后重试' });

                                }


                            },

                            error:(err)=>{

                                console.log(err);

                                this.$notify({ type: 'danger', message: '图片上传失败，请稍后重试' });

                            }

                        })
            
                       

                    }else{

                        this.$notify({ type: 'danger', message: res.msg || '图片上传失败，请稍后重试' });

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$notify({ type: 'danger', message:'图片上传失败，请检查网络'});

                }

            })
        },

        //图片删除

        deleteImage(item){

            this.$dialog.confirm({

                title:'删除图片',

                message:'是否删除此图片，此操作不可逆',

                confirmButtonColor:"#00a0e4"

            }).then(()=>{

                this.$toast.loading({

                    duration:0,

                    message:'正在删除...'

                })


                api.removeRecordImage({

                    options:{

                        id:this.$route.params.id,

                        imageId:item.id,

                    },

                    success:(res)=>{

                        if(res.type == 200){

                            this.$notify({ 
                                type: 'success', 
                                message: '删除成功' 
                            });

                            this.recordData.images = res.data;

                        }else{

                            this.$notify({ 

                                type: 'danger', 

                                message:res.msg || '删除失败，请重试' 
                                
                            })

                        }

                    },

                    error:(err)=>{

                        console.log(err);

                         this.$notify({ 
                            type: 'danger', 
                            message:'删除失败，请检查网络，或稍后重试' 
                         })

                    },

                    complete:()=>{

                        this.$toast.clear();

                    }

                })

            }).catch(()=>{})

        },

        //图片预览

        imageShowOpen(index){

            this.images = this.recordImages.map(el=>{

                return this.$getImageUrl(el.url);

            });

            this.imageIndex=index;

            this.imageShow=true;

        },


        //报备单更改

        recordChange(){

            //判断是啥状态

            if(this.$store.state.userInfo.role=="AUDITOR" || this.$store.state.userInfo.role=='AUDITORADMIN'){

                //跳转到审核页面

                this.$router.push({path:`/record/audit/${this.$route.params.id}`});

            }else if(this.recordData.permission == 1){

                //渠道专员另说

                if(this.$store.state.userInfo.role == 'CHANNEL' || this.$store.state.userInfo.role == 'CHANNELJINGLI' || this.approvalStatusPermission){

                    this.toolbarShow=true;

                }else{

                    this.recordDialogShow=true;

                }


            }

        },


        //上传报备动态

        recordDialogConfirm(action,done){

            if(action == 'confirm'){

                if(!this.recordAddForm.remark){

                    this.$notify({type:"danger",message:"请填写内容"});

                    done(false);

                    return false;

                }

                api.updateRecordCustomerInfo({

                    options:{id:this.$route.params.id,...this.recordAddForm},

                    success:(res)=>{

                        if(res.type == 200){

                            this.$dialog.alert({

                                title:'提交成功',

                                message:`动态添加成功`,

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{});

                            this.getRecordData(false)
                                
                            this.recordAddForm = {};

                        }else{

                            this.$dialog.alert({

                                title:'提交失败',

                                message:res.msg || '提交失败，请稍后重试',

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{})

                        }

                    },

                    error:(error)=>{

                        console.log(error);

                        this.$dialog.alert({

                                title:'提交失败',

                                message:'提交异常，请检查网络或稍后重试',

                                confirmButtonColor:"#00a0e4"

                        }).then(()=>{})

                    },

                    complete:()=>{

                        done(true);

                    }

                })

        


            }else{

                done(true);

            }

           

        },

        //获取报备单信息

        getRecordData(isLoading){

            if(!isLoading){

                this.$toast.loading({

                    message:'加载中...',

                    duration:0,

                    overlay:true

                });

            }

            api.getRecordData({

                options:{id:this.$route.params.id},

                success:(res)=>{

                    if(res.type == 200){

                        this.recordData = res.data;

                    }else{

                        this.$dialog.confirm({

                            title:'订单数据获取失败',

                            message:res.msg || `订单数据获取失败${res.type !=700 ? '，点击确认进行重新加载' :''}`,

                            confirmButtonColor:"#00a0e4"

                        }).then(()=>{

                            if(res.type != 700){

                                this.getRecordData();

                            }else{

                                this.$router.back();

                            }

                        }).catch(()=>{this.$router.back();})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.confirm({

                        title:'订单数据获取异常',

                        message: '订单数据获取异常，点击确认进行重新加载',

                        confirmButtonColor:"#00a0e4"

                    }).then(()=>{

                            this.getRecordData();

                    }).catch(()=>{})

                },

                complete: ()=>{

                    this.$toast.clear();

                    this.loading=false;
                    
                }

            })

        },

        init(){

            this.getRecordData();

        },

        //选择操作

        toolbarConfirm(data){

            this.toolbarShow =false;

            this.$nextTick(()=>{

                if(data.value == 0){

                this.recordDialogShow=true;

                }else if(data.value == 1){

                    this.$router.push({path:`/record/audit/${this.$route.params.id}`});

                }

            })

        },


        //下拉刷新

        onRefresh() {

           this.getRecordData(true);

        },

        //打开客户购房资质证明

        customerPicShow(){

            this.images = this.customerPic.map(el=>{

                return this.$getImageUrl(el);

            });

            this.imageIndex=0;

            this.imageShow=true;

        },

        //重新报备

        recordAgain(){

            let customerInfo = cloneDeep(this.recordData?.customerInfo || null);
            //加上一个时间戳，以便修改用
            customerInfo.time = (new Date()).getTime();
            //设置客户列表
            this.$store.commit('setRecordData',{

                key:"customerList",
                
                value:customerInfo ? [customerInfo] : []

            });

            //设置到访人数

            this.$store.commit('setRecordData',{

                key:"visitorNum",
                
                value:this.recordData?.visitorNum || ''

            });

            //设置带看人姓名
            
            this.$store.commit('setRecordData',{

                key:"dkStaffName",
                
                value:this.recordData?.dkName || ''

            });

            //设置带看人联系方式
            
            this.$store.commit('setRecordData',{

                key:"dkStaffPhone",
                
                value:this.recordData?.dkPhone || ''

            });

            this.$router.push({path:'/house/record/'+this.recordData.houseId});

        },


    },

    mounted(){
        this.$nextTick(()=>{

            this.minHeight = this.$refs.bd.offsetHeight-vwToPx(10.26667);

        })

    },

    activated(){

        if(!this.$store.state.routerStore.recordListRefresh || isObjectNone(this.recordData) || this.$store.state.routerStore.recordListRefresh != this.$route.params.id) return;

        this.getRecordData();

    },

    created(){

        this.$nextTick(()=>{

            this.getRecordData();

        })

    }

}
</script>

<style lang="less">
.record-detail-wrapper{

    height:100%;

    background: #f6f6f6;

    .record-again{

        position: fixed;

        bottom:50px;

        right:16px;

        width: 50px;

        height:50px;

        border-radius: 50%;

        background: #00a0e4;
        
        color:#fff;

        box-shadow:0 0 16px 0  rgba(0, 160, 228,.2);

        user-select:none;

        &:active{

            opacity: .6;

        }

        .flex-cover{

            height:100%;

            padding: 0 10px;

            flex-direction:column;
            
            justify-content: center;

            span{

                height:auto;

                text-align: center;

                line-height: 17px;

                font-size:13px;

            }

        }

    }

    .toolbar-wrapper{

        height:100%;

        display: flex;

        justify-content: center;

        align-items: center;

        .toolbar-cover{

            width: 200px;

            height:200px;

            background:#fff;

            border-radius:5px;

        }

    }

    .record-detail-cover{

        height:100%;

    }

    .bd{

        padding-top:10px;

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y:auto;

        height:100%;

        .record-mobile{

            position: relative;

            top:2px;

        }

        .steps-wrapper{

            padding-bottom:15px;

        }

        .customer-info{

            padding: 0 10px 25px;

            &.steps-show{

                padding-bottom:5px;

            }

            .customer-info-cover{

                background: #00a0e4;

                color:#fff;

                padding: 15px;

                border-radius:5px;

                display: flex;

                align-items: center;

                .ctn{

                    margin-left:15px;

                    flex:1;

                    width: 0;

                    .des{

                        display: flex;

                        justify-content: space-between;

                        width: 100%;

                        .name{

                            font-size:15px;

                            .phone{

                                padding-left:5px;

                            }

                        }

                        .call{

                            a{

                                display: block;

                                height:30px;

                                width: 30px;

                                text-align: center;

                                line-height:30px;
                                
                                position: relative;

                                z-index:10;

                            }

                            .call-icon{

                                color:#fff;

                                font-size:18px;

                            }

                        }

                    }

                    .house-name{

                        padding-top:8px;

                        font-size:13px;

                        display: flex;

                        justify-content: space-between;

                        .customerPic{

                            position: relative;

                            z-index:20;

                            .van-icon{

                                font-size:16px;

                                vertical-align:middle;

                                position: relative;

                                top:-1px;

                                padding-right:3px;

                            }

                            &:active{

                                opacity: .8;

                            }

                        }

                    }

                }

            }

        }

        .tabs{

            .van-tab--active{

                color:#00a0e4;

            }

            .van-tabs__line{

                width: 125px;

                background-color:#00a0e4;

                height:1px;

            }

            .tabs-item{

                padding: 15px 15px 30px;

                &.order-cards{

            

                    .order-card{

                        background: #fff;

                        padding: 15px;

                        border-radius:5px;

                        margin-bottom:15px;

                        &.none{

                            background: transparent;

                            color:#999999;

                            text-align: center;

                            font-size:14px;

                        }

                        .order-card__title{

                            font-size:15px;

                            padding-bottom:12px;

                            display: flex;

                            align-items: center;
                            
                            span{

                                &.faild{

                                    color:rgb(252, 54, 54);

                                }

                                &.success{

                                    color:rgb(3, 187, 3);

                                }

                            }

                        }

                        .ocder-crad_content-cell{

                            font-size:14px;

                            line-height:28px;

                            .cell-name{

                                color:#999999;

                            }

                            .call-icon{

                                color:#00a0e4;

                                margin-left:5px;

                                font-size:17px;

                                position: relative;

                                top:2px;

                            }

                        }

                    }

                }

                &.order-images{

                    .order-images-cover{

                        padding: 15px;

                        background:#fff;

                        min-height:120px;

                        border-radius:5px;

                        overflow: hidden;

                        .order-images-show{

                            display: flex;
                            
                            flex-wrap: wrap;

                            justify-content: space-between;

                        }

                        .order-image{

                            width: 150px;

                            height: 150px;

                            margin-bottom:10px;

                            box-shadow:0 5px 16px rgba(0,0,0,.16);

                            position: relative;

                            overflow: hidden;

                            img{

                                width: 100%;

                                height:100%;

                            }

                            .close-btn{

                                position: absolute;
                                width: 80px;
                                height: 40px;
                                right: -54px;
                                top: -18px;
                                background: rgba(0, 0, 0, 0.6);
                                text-align: center;
                                padding-top: 15px;
                                transform: translateY(10px) translateX(-26px) rotate(45deg);

                                color:#fff;

                                .delete-icon{

                                    font-size:18px;

                                    text-shadow: 0 0 16px rgba(0,0,0,.16);

                                }
                                
                                &:active .delete-icon{

                                    opacity: .6;

                                }

                            }

                        }

                        .van-uploader__upload{

                            width: 150px;

                            height:150px;
                            

                            .van-uploader__upload-icon{

                                font-size:30px;

                            }

                        }

                    }

                }

            }

            

        }

    }

    .record-change-dialog{

        padding: 15px;

        z-index:15;

        margin-top:15px;

        .van-form{

            .van-cell{

                z-index:20;

            }

        }

    }

}
</style>
