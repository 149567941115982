import AMapLoader from '@amap/amap-jsapi-loader';

const mapLoad = (plugins)=>{

    return new Promise((res,rej)=>{
        window._AMapSecurityConfig = {
            securityJsCode:'d28fb419089697be16c6f2c58a5b10ce',
        }
        AMapLoader.load({    
            "key": process.env.NODE_ENV !== "production" ? "9827c5d2ea0b671dd41750531aca8490" : '56c6b521939cf6d789499f0648c44687',
            "version": "2.0",                                    // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": plugins || [],                                          // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            "AMapUI": {                                             // 是否加载 AMapUI，缺省不加载
                "version": '1.1',                                   // AMapUI 缺省 1.1
                "plugins":[],                                       // 需要加载的 AMapUI ui插件
            },
        }).then((AMap)=>{
               
            res(AMap)

        }).catch(e => {
            console.log(e);
            rej(e);

        })


    })


}

export default mapLoad;