import api from "@/api";

import dataStorage from "@/utils/dataStorage";

import sessionStorage from '@/utils/sessionStorage';

import {isArray,initCityList,getCityData} from "@/utils";

import {Decrypt,Encrypt} from "@/utils/crypto";

import { isString } from "@/utils";

const state = {

    ready:false,

    show:false,

    name:'',

    phone:'',

    sex:0,

    resume:'',

    distributorName:'',

    uid:null,

    avatar:'',

    //DISTRIBUTOR 表示业务员中的管理人员
    //STAFF 经纪人
    //AUDITOR 报备专员
    //CHANNEL 渠道专员
    //AUDITORADMIN 报备专员管理员

    role:"",

    //此分销公司顶级管理员

    isDistributorAdmin:false,

    //门店名称

    shopname:'',

    //以下的数据全都是用来选择的数据,地区

    cityList:null,

    //检查用城市列表

    checkCityList:[],

    //list价格区间

    priceList:null,

    //楼盘特色

    houseTeseList:null,

    //经纪人列表

    staffList:null,

    //状态列表

    recordStatusList:null,

    //状态名称对象

    recordStatusTextObj:{},

    //当前城市

    currentCity:null,

    //客服电话

    kefPhone:null,

    //是否绑定微信

    bindWx:0,

    token:null,

    //是否已经提示过需要绑定微信

    bindWxTips:false,

    //对接渠道列表

    channelList:[],

    //报备值班

    vacation:1,
    
    //权限列表 
    permissionList:[],

    //公司信息
    company:{},

    //角色类型

    distributorStaffType:'',

    distributorControl:0
    
  
}

const mutations = {

    //设置数据

    setUserData(state,{key,value,save}){
        
        state[key] = value;

        if(save){

            let options = {
                cityList:state.cityList,
                houseTeseList:state.houseTeseList,
                priceList:state.priceList,
                moreList:state.moreList,
                kfPhone:state.kefPhone,
                recordStatusTextObj:state.recordStatusTextObj,
                permissionOperations:state.permissionList,
                distributorStaffType:state.distributorStaffType,
                userInfo:{
                    //uid
                    "uid":state.uid,
                    //名字
                    "name":state.name,
                    //性别
                    "sex":state.sex,
                    //手机号码
                    "phone":state.phone,
                    "resume":state.resume,
                    "avatar":state.avatar,
                    'role':state.role,
                    "bindWx":state.bindWx,
                    "channelList":state.channelList,
                    "shopname":state.shopname,
                    "distributorControl":!!state.distributorControl,
                    "distributorID":state.distributorID,
                    "distributorName":state.distributorName,
                    'distributorStaffType':state.distributorStaffType
                },
                token:state.token
            }

            //值班

            if(state.role == 'AUDITOR'){

                options.userInfo.vacation = state.vacation;

            }

            dataStorage.StorageSetter('user_common_data',{token:state.token});

            sessionStorage.StorageSetter('user_common_data',options);

        }

    },

    //数据初始化

    initData(state,data,update){

        /**
         * 
         * 判断data 是否为字符串
         * 
         * 是的话解密一下
         * 
        */

        if(isString(data)){

            data = Decrypt(data);

        }

        if(!update){

            //设置城市列表

            state.cityList = data?.cityList;

            state.houseTeseList = data?.houseTeseList;

            state.priceList = data?.priceList;

            state.staffList = data?.staffList;

            state.recordStatusList = data?.recordStatusList;

            state.permissionList = data?.permissionOperations || [];

            //序列化状态列表，以便直接提取对应的名称

            if(data.recordStatusList && isArray(data.recordStatusList)){

                data.recordStatusList.forEach(el=>{

                    if(el.value){

                        if(el.value == 'RECORDSTANDBY'){

                            state.recordStatusTextObj[el.value] = '等待开发商审核';

                        }else{

                            state.recordStatusTextObj[el.value] = el.text;

                        }

                        
                    }

                })

            }

            //设置选择城市

            let city = dataStorage.StorageGetter('city');
            if(isArray(state.cityList) && state.cityList.length>0){
                //处理一下城市列表,处理成一列数组
                state.checkCityList = initCityList(state.cityList);
                //清除上个版本遗留，即判断是否存在时间戳
                if(!city || !city?.time){
                    //不存在就删除掉时间，然后选择海口
                    dataStorage.StorageRemove('city');
                    city = getCityData(this,{name:'海口市'});
                }
                state.currentCity=city;
            }
            if(data.userInfo){ 
                
                //设置用户数据
                state.name = data.userInfo.name;
                state.phone = data.userInfo.phone;
                state.sex = data.userInfo.sex;
                state.resume = data.userInfo.resume;
                state.distributorName = data.userInfo.distributorName;
                //是否为分销商总账号
                state.distributorControl = !!data.userInfo.distributorControl;
                state.shopname = data.userInfo.shopname;
                state.uid = data.userInfo.uid;
                state.avatar = data.userInfo.avatar;
                state.role=data.userInfo.role;
                state.kefPhone = data.kefPhone;
                state.bindWx = !!data.userInfo.bindWx;
                state.channelList =data.userInfo.channelList || [];
                state.distributorStaffType = data.userInfo?.distributorStaffType;
                //设置token
                state.token = data.token;

                //保存数据

                dataStorage.StorageSetter('user_common_data',{token:data.token});

                state.ready = true;

            }


        }else{

            state.role=data.userInfo.role;

        }

        //储存临时数据

        sessionStorage.StorageSetter('user_common_data',data);



    },

    //重置数据

    resetUserData(state){

        //准备状态取消

        state.ready = false;

        state.token = null;

        state.bindWxTips = false;

        dataStorage.StorageRemove('user_common_data');

        sessionStorage.StorageRemove("user_common_data");

    }



}

const actions = {

    /**
     *   获取用户数据 , 数据获取成功后，即表示用户登录成功后，将数据储存在session storage 中
     *   刷新时检查此数据是否存在 如果存在就直接刷新，不存在就进行登录
     *   储存格式应当为 user-state 
     * 
     *   将常用的数据储存起来
     * 
     */

    getInitData({commit},{callback,path,isLive,nowPath}){


        try{

            //首先映带判断是否存在原始数据
            const userCommonData = dataStorage.StorageGetter('user_common_data');  
            let sessionCommonData = sessionStorage.StorageGetter('user_common_data');
            //设置token去进行数据请求,当存在临时数据的时候优先使用
            if(sessionCommonData){
                commit('initData',sessionCommonData);
                callback && callback({path:path},'success');
            }else{
                if(userCommonData){
                    commit('setUserData',{
                        key:'token',
                        value:userCommonData.token
                    });
                    api.getInitConfig({
                        success:(res)=>{
                            if(res.type == 200){
                                if(path && path !='/'){
                                    callback && callback({path:path},'success');
                                }else{
                                    callback && callback({name:"home"},'success');
                                }
                                commit('initData',res.data);
                            }else{
                                callback && callback({name:'login'},'timeout');
                            }
                        },
                        error:(err)=>{
                            console.log(err);
                            callback && callback({name:'login'},'error',err);
                        },
                        complete: (res)=>{
                            if(res.data.code == 401){
                                callback && callback({name:"home"},'success');
                            }
                        }
                    })
                }else{
                    //不存在缓存数据的时候进行跳转登录
                    if(isLive || (!nowPath || nowPath == '/')){
                        api.getCommonInitData({
                            success:(res)=>{
                                if(res.type == 200){
                                    if(path && path !='/'){
                                        callback && callback({path:path},'success');
                                    }else{
                                        callback && callback({name:"home"},'success');
                                    }
                                    commit('initData',res.data);
                                }else{
                                    callback && callback({name:'login'},'timeout');
                                }
                            },
            
                            error:(err)=>{
                
                                console.log(err);
                
                                callback && callback({name:'login'},'error',err);
                
                            }
                
                        })

                    }else{
                        
                        callback && callback({name:'login'},'error');

                    }
        

        
                }


            }


        }catch(err){

            callback && callback({name:"home"},'success');

        }
        
    }

}

const getters = {}


 export default {

    state:state,
    mutations:mutations,
    actions:actions,
    getters:getters

 }