<template>
   <div class="customer-list-wrapper">
       <div class="flex-box-column customer-list-cover">
            <div class="hd">
                 <van-nav-bar left-arrow safe-area-inset-top title="导入客户" right-text="导入"  class="search" @click-left="back" @click-right="selectedCustomer">
                </van-nav-bar>
                <div class="search-cover">
                    <van-search v-model="keyword" class="sm border" shape="round" placeholder="搜索客户" @input="onSearch" ref="recordListSearch"/>
                </div>
                <div class="select-list">
                    <div class="item" v-for="(item,index) in selected" :key="index">
                        <span>{{item.name}}({{item.phone}})</span>
                        <span @click="close(index,item)"><van-icon name="cross"/></span>
                    </div>  
                </div>
            </div>
            <div class="bd">
                <van-empty image="error" description="获取数据失败,点击重试" @click.native="getCustomerList" v-if="pageErr" />
                <van-index-bar :sticky="false" z-index="50">
                    <template v-for="(item,index) in renderList">
                        <div :key="index">
                            <van-index-anchor :index="item.anchor" v-if="item.anchor"/>
                               <van-cell title-class="p-b-10" v-for="(_item,_index) in item.list" :key="_index" @click="selectCustomer(_item)" is-link>
                                    <template #icon> 
                                        <div class="avatar" :class="{man:_item.sex==1,woman:_item.sex==2}"></div>
                                    </template> 
                                    <template #title>
                                        <span>{{_item.name}}</span>
                                    </template>  
                                    <template #label>
                                        <span>{{_item.phone}}</span>
                                    </template>
                                    <template #right-icon>
                                        <van-icon name="success" class="selected-icon" v-if="_item.isSelected" color="#00a0e4" />
                                    </template>
                                </van-cell>
                            <van-empty image="search" description="没有对应客户信息" v-if="item.empty"/>
                        </div> 
                    </template>
                    <van-empty description="没有客户信息" v-if="customerList.length == 0 && !pageErr"/>
                </van-index-bar>
            </div>
       </div>
   </div>
</template>

<script>
import backMixin from '@/mixins/back';
import api from "@/api";
import {findIndex,intersection} from "@/utils";
export default {

    mixins:[backMixin],


    data(){

        return {

            keyword:'',

            customerList:[],

            searchList:[],

            pageErr:false,

            selected:[],

            //初始化时获取到的个数

            oldSelectedLength:0

        }

    },

    computed:{

        renderList(){

            return this.searchList.length>0 ? this.searchList : this.customerList;

        },

        //当前选中的客户列表
        
        selectCustomerList(){

            return this.$store.state.record?.customerList || [];

        }

    },


    methods:{

        onSearch(keyword){

            let searchList= [{list:[]}];

            if(keyword){

                //搜索用户

                this.customerList.forEach(el=>{

                    el.list && el.list.forEach(_el=>{

                        if(_el.name.indexOf(keyword) !=-1 || _el.phone.indexOf(keyword) != -1){

                            searchList[0].list.push(_el);

                        }

                    })

                });

                if(searchList[0].list.length == 0){

                    searchList[0].empty = true;

                }

            }else{

                searchList = [];

            }

            this.searchList = searchList;
            

        },

        init(){

            this.getCustomerList();

        },

        //获取用户列表

        getCustomerList(){

            this.$toast.loading({

                overlay:true,

                duration:0,

                message:"加载中..."

            });

            api.getCustomerList({

                success:(res)=>{

                    if(res.type == 200){

                        res.data.forEach(el=>{

                            el.list && el.list.forEach(_el=>{

                                let _index = findIndex(this.selected,(item)=>{

                                    return _el.id == item.id;

                                });

                                if(_index !=-1){

                                    _el.isSelected = true;

                                }else{

                                    _el.isSelected = false;

                                }

                            })

                        })

                        this.customerList = res.data;

                        this.pageErr=false;

                    }else{

                        this.$dialog.confirm({

                            title:'获取数据失败',

                            message:res.msg || "获取客户列表失败，请稍后重试",

                            confirmButtonText:'重试',

                            confirmButtonColor:"#00a0e4"

                        }).then(()=>{

                            this.getCustomerList();

                        }).catch(()=>{})

                        this.pageErr=true;

                    }

                    

                },

                error:(err)=>{

                    console.log(err);

                    this.pageErr=true;

                    this.$dialog.confirm({

                        title:'获取数据失败',

                        message:"获取客户列表失败，请求异常，请稍后重试",

                        confirmButtonText:'重试',

                        confirmButtonColor:"#00a0e4"

                    }).then(()=>{

                            this.getCustomerList();

                    }).catch(()=>{})

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },

        //选择用户

        selectCustomer(item){

            let isSelected = item.isSelected;

            if(!isSelected){

                if(this.oldSelectedLength >= 5){

                    this.$toast(`最多同时报备5个客户，报备客户列表里已有 ${this.selectCustomerList.length} 个客户`)

                    return;

                }

                this.$set(item,'isSelected',true);

                this.selected.push(item);

                ++this.oldSelectedLength;

            }else{

                this.$set(item,'isSelected',false);

                let index= findIndex(this.selected,(el)=>{

                    return el.id == item.id;

                })

                if(index != -1){

                    this.selected.splice(index,1);

                }

                --this.oldSelectedLength;

            }

        },

        //导入客户

        selectedCustomer(){

            this.$dialog.confirm({

                title:'导入客户',

                message:'确认要导入这些客户吗？',

                confirmButtonColor:'#00a0e4'

            }).then(()=>{

                let selected = [];

                if(this.selectCustomerList.length == 0){

                    selected = this.selected;

                }else{

                    //找出相同的元素

                    let _intersection = intersection([this.selected,this.selectCustomerList]);

                    if(_intersection.length<this.selected.length){

                        _intersection = this.selected;

                    }

                    //找出不存在id的元素

                    let _noId = this.selectCustomerList.filter(el=>{

                        return !el.id

                    });

                    selected = [..._intersection,..._noId];

                }

                //给锁选择的元素循环添加time

                selected.forEach((el,index)=>{

                    let date = new Date();

                    el.time = date.getTime()+index;

                });


                this.$store.commit('setRecordData',{

                    key:"customerList",

                    value:selected

                });

                this.$router.back();

            }).catch(()=>{})

        },

        //取消选择

        close(index,item){

            this.selected.splice(index,1);

            --this.oldSelectedLength;

            if(this.searchList.length>0){

                let _index= findIndex(this.searchList[0].list,el=>{


                    return el.id == item.id;

                });

                if(_index !=-1){

                    this.$set(this.searchList[0].list[_index],'isSelected',false);

                }
                
            }else{
                    
                let _item;

                this.customerList.forEach(el=>{

                    let _index = findIndex(el.list,(_el)=>{

                        return _el.id == item.id;

                    });

                    if(_index !=-1){

                        _item = el.list[_index];

                    }
                    
                });

                if(_item){
                    
                    _item.isSelected = false;

                }


            }

        }
    },

    activated(){

        this.selected = this.$store.state.record.customerList.filter(el=>{

            return el.id;

        });

        this.oldSelectedLength = this.selectCustomerList.length;

    },

    created(){

        this.$nextTick(()=>{

            this.init();

        })

    }

}
</script>

<style lang="less">

 .customer-list-wrapper{

     height:100%;

     .van-index-bar__index--active{

         color:#333;

     }

    .customer-list-cover{

                     .select-list{

                padding:5px 20px 0;

                padding-right:0;

                display: flex;

                flex-wrap: wrap;

                background: #fff;

                .item{

                    background: #f3f9ff;

                    color:#00a0e4;
                    
                    width: 30%;

                    padding: 3px 10px;

                    margin-right:8px;

                    margin-bottom:8px;

                    border-radius: 22px;

                    font-size:12px;

                    display: flex;

                    align-items: center;

                    justify-content: space-between;

                    border: 1px solid #00a0e4;

                    span:first-child{

                        max-width: 80%;

                        overflow: hidden;

                        white-space: nowrap;

                        text-overflow: ellipsis;

                    }

                    span:nth-child(2){

                        height:100%;

                        i{

                            font-size:14px;

                            position: relative;

                            top:1px;

                        }

                    }

                }
                
            }


        height:100%;

        .search-cover{

            width: 65%;

            margin: 0 auto;

 

        }

        .bd{

            flex:1;

            height:0;

            position: relative;

            overflow: hidden;

            overflow-y:auto;

            background: #f6f6f6;

            padding-bottom:50px;

            .selected-icon{

                padding-right:50px;

                font-size:18px;

            }


            .van-empty{

                background: #fff;

            }

            .van-button{

                height:100%;

            }

            .van-index-anchor{

                padding: 0 30px;

            }

            .van-swipe-cell__wrapper{

                background: #fff;

                .tel{

                    width: 60px;

                    display: inline-flex;

                    align-items: center;

                    justify-content: center;

                    background: #09dd70;

                    color:#fff;

                    height:100%;

                    vertical-align: top;

                    font-size:15px;

                    line-height: 1.2;

                }

            }


            .van-cell{

                align-items: center;

                padding: 5px 0 5px 16px;

                z-index:20;

                &:after{

                    right:0;

                    left:55px;

                }

                .avatar{

                    width: 38px;

                    height:38px;

                }

                .van-cell__title{

                    padding-left:15px;

                    padding-bottom:0;

                }

            }
               
        }
        

    }
    

 }

</style>
