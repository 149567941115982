export default{


    methods: {
        
        fkyDisabled(){

            this.$toast({

                message:'该功能正在准备中...',

                position:'bottom'

            })

        }

    },

}