<template>
   <div class="house-detail-wrapper">
    <div class="hd">
        <van-nav-bar left-arrow title="楼盘详情" :border="false" @click-left="back" @click-right="showShare=true">
            <template #right>
                <div class="main-color share-btn" :style="{color:mainColor}" v-if="isReady"><span>分享</span><van-icon name="system-share" :color="mainColor"/></div>
            </template>
        </van-nav-bar>
    </div>
    <div class="bd" ref="bd">
        <!-- 顶部banner -->
        <div class="house-banner" @click="openImageView({findex:imageIndex})">
            <div class="thumb-cover">
                <img :src="houseData.buildingDiagram|bannerImage(that)" alt="">
            </div>
            <div class="tag" v-if="houseImagesNumber>0" :style="{'background-color':mainColor}">
                <div class="num">+{{houseImagesNumber}}</div>
            </div>
            <div class="vr-icon" v-if="houseData.buildingVr" @click.stop="goLink({path:'/house/vr/'+$route.params.id,query:{name:houseData.name ? encodeURI(houseData.name) : ''}})">
                <van-icon name="system-vr-2" />
            </div>
            <!-- 图片预览 -->
            <van-image-preview 
            v-model="imageShow" 
            :images="images" 
            :start-position="imageDefaultIndex"
            @change="imageChange"
            :loop="false"
            ref="houseDetailImageView"
            :max-zoom="5">
                <template v-slot:index>
                <div class="photo-type">
                    <div class="photo-type-item" 
                    v-for="(item,index) in houseData.albums"  @click="imageTabChange(item.typeIndex)" :class="{active:item.typeIndex == typeIndex}" :key="index"><span>{{item.name}} ({{item.list.length}})</span></div>
                </div>
                </template>
                <template v-slot:cover>
                    <div class="image-name">{{activeImage.picTypeCn}}</div> 
                    <div class="image-des">{{activeImage.desc}}</div> 
                    <div class="image-index">{{activeImage.index}}/{{activeImage.total}}</div> 
                </template>
            </van-image-preview>
        </div>
        <!-- 楼盘信息简述 -->
        <div class="house-info">
            <div class="house-info-item">
                <div class="house-name">{{houseData.name}}</div>
                <div class="house-price">{{totalPrice}}
                </div>
            </div>
            <div class="house-info-item tag" v-if="houseData.tags && houseData.tags.length>0">
                <span v-for="(item,index) in houseData.tags" :key="index">{{item}}</span>
            </div>
        </div>
        <!-- 报备提示 -->
        <div class="house-record-text" v-if="getRecordTime">
            <span>{{getRecordTime}}报备</span>
        </div>
        <!-- 激励政策 -->
        <div class="incentive" v-if="houseData.incentivePolicy" @click="incentiveShow = true">
                <div class="incentive-title">奖励政策</div>
                <div class="incentive-ctn van-multi-ellipsis--l2">
                {{houseData.incentivePolicy}}
                </div>
                <div class="open"><van-icon name="arrow"/></div>
            <van-overlay :show="incentiveShow" z-index="50" @click.stop="incentiveShow = false">
                <div class="incentive-overlay-wrapper" @click.stop>
                    <p class="incentive-overlay__title">奖励政策</p>
                    <p class="incentive-overlay__content">{{houseData.incentivePolicy}}</p>
                </div>
            </van-overlay>
        </div>
        <!-- 视频 -->
        <div class="house-record-video" v-if="houseData.buildingVideo">
            <div class="house-record-video__title">视频看房</div>
            <div class="house-record-video__content" ref="videoContent" :style="{height:this.videoHeight+'px'}">
                <video 
                @canplay="setVideoHeight"
                id="videoPlay" 
                webkitplaysinline="true"
                playsinline="true"
                :src="houseData.buildingVideo" 
                v-show="videoPlayover" 
                @pause="videoPause" 
                @play="videoPlayStart" 
                controls
                @playing="videoPlaying"
                x5-video-orientation="landscape"
                ></video>
                <div class="video-play" v-if="!videoPlayover" @click="videoPlay"> 
                    <div class="video-poster"><img :src="videoPoster|bannerImage(that)" alt=""></div>
                    <div class="video-icon p-a-m-c"><van-icon name="system-icon_play" /></div>
                </div>
            </div>
        </div>
        <!-- tabs -->
        <div class="tabs house-tabs">
            <van-tabs v-model="tabsActive" swipeable animated sticky offset-top="12.26667vw" 
            @change="tabsChange" 
            :line-width="lineWidth">
                <van-tab title="基本信息">
                    <div class="base-info tab-item" :style="getMinHeight">  
                        <div class="base-info-column">
                            <div class="base-info-column-title">
                                楼盘详情
                            </div>
                            <div class="base-info-column-ctn">
                                <div class="base-info-column__sub-title">简介</div>
                                <p v-if="houseData.buildingAddress">楼盘地址：{{houseData.buildingAddress || '暂无'}}</p>
                                <p v-if="houseData.plannedArea && houseData.plannedArea!=0">占地面积：{{isNaN(houseData.plannedArea) ? houseData.plannedArea : houseData.plannedArea+'㎡' || '暂无'}}</p>
                                <p v-if="houseData.buildingArea && houseData.buildingArea!=0">建筑面积：{{isNaN(houseData.buildingArea) ? houseData.buildingArea : houseData.buildingArea +'㎡'}}</p>
                                <p v-if="houseData.plannedHousehold && houseData.plannedHousehold!=0">规划户数：{{isNaN(houseData.plannedHousehold) ? houseData.plannedHousehold : houseData.plannedHousehold+'户'}}</p>
                                <p v-if="houseData.plotRatio && houseData.plotRatio!=0">容积率：{{isNaN(houseData.plotRatio)? houseData.plotRatio : parseFloat(houseData.plotRatio)+'%' || '暂无'}}</p>
                                <p v-if="houseData.greeningRate && houseData.greeningRate!=0">绿化率：{{isNaN(houseData.greeningRate)? houseData.greeningRate : parseFloat(houseData.greeningRate) +'%'}}</p>
                                <p v-if="houseData.propertyFee && houseData.propertyFee!=0">物业费：{{houseData.propertyFee}}元/㎡</p>
                                <p v-if="houseData.propertyCompany">物业公司：{{houseData.propertyCompany || '暂无'}}</p>
                                <p v-if="houseData.deliverTime">交房时间：{{houseData.deliverTime || '暂无'}}</p>  
                                <div class="base-info-column__sub-title">项目介绍</div>
                                <p class="parse-cover" v-html="houseData.projectIntroduction || '暂无'"></p>
                                <div class="base-info-column__sub-title">项目特点</div>
                                <p>{{houseData.buildingTrait || '暂无'}}</p>   
                                <div class="base-info-column__sub-title">项目配套</div>
                                <p>{{houseData.projectSupporting || '暂无'}}</p>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="佣金政策" v-if="isReady">
                    <div class="y-rules tab-item" :style="getMinHeight">
                        <van-tabs v-model="commissionActive" swipeable animated @change="commissionTabsChange" line-width="3em" v-if="commissionTabs.length>0">
                            <van-tab v-for="item in commissionTabs" :key="item.value" :title="item.name">
                                <commission-item :prop="commissionItems[item.value]"></commission-item>
                            </van-tab>
                        </van-tabs>
                        <div v-else style="padding: 30px 0;text-align: center;color:#999999;font-size:14px;">暂无佣金政策</div>
                    </div>
                </van-tab>
                <van-tab title="主力户型">
                    <div class="house-type-main tab-item" :style="getMinHeight">
                        <div class="house-type-main-cover">
                            <div class="house-type-main-item none" v-if="houseTypeList.length==0">暂无主力户型</div>
                            <div 
                            class="house-type-main-item border_bottom"
                            @click="openHouseTypeList(item)"
                            v-for="(item,index) in houseTypeList" :key="index">
                                <div class="thumb"><img :src="item.typeThumb | bannerImage(that)" alt=""></div>
                                <div class="ctn">
                                    <div class="house-type-name item">{{item.typeName || '暂无命名'}}</div>
                                    <div class="house-type-des item" v-if="item.typeTheme">{{item.typeTheme}}</div>
                                    <div class="house-type-yh item" v-if="item.discountPolicy">{{item.discountPolicy}}</div>
                                    <div class="house-type-area item">建筑面积：{{item.floorArea|areaFilter}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="位置信息" v-if="isLngLat">
                    <div class="map tab-item" :style="getMinHeight">
                        <div class="map-cover">
                            <van-cell title="地图详情" size="large" is-link :border="false" :to="{name:'houseNavigation',query:{type:2,point:point,title:houseData.name}}"></van-cell>
                            <van-cell :border="false">
                                <template #title>
                                    <p class="house-addr">{{houseData.buildingAddress}}</p>
                                </template>
                                <template>
                                    <van-button plain type="info" icon="location-o" size="small" @click="navigationStart">路线规划</van-button>
                                </template>
                            </van-cell>
                            <div class="map-container" id="container"><span v-if="!point" class="tips">正在加载...</span></div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
    <div class="toolbar van-hairline--top" :style="{color:mainColor}" v-if="!isSharePage">
        <div class="toolbar-item star" :style="{color:houseData.isFavorite ? mainColor : ''}" @click="favoriteHouse">
            <p><van-icon :name="houseData.isFavorite ? 'star' : 'star-o'" :color="houseData.isFavorite ? mainColor : ''" class="icon"/></p>
            <p>{{ houseData.isFavorite ? '已收藏' : '收藏'}}</p>
        </div>
        <div class="toolbar-item tel" @click="telListShow">
            <p><van-icon name="phone-o" class="icon" /></p>
            <p>咨询</p>
        </div>
        <div class="toolbar-item record" :style="{background:mainColor}" @click="goLink({path:'/house/record/'+$route.params.id},'record')">
            <p v-if="getRecordTime">{{getRecordTime}}</p>
            <p>报备客户</p>
        </div>
    </div>    
    <div class="toolbar van-hairline--top share-cover" :style="{color:mainColor}" v-else>
        <div class="toolbar-item staff">
            <div class="staff-avatar avatar" :class="{secret:!shareUserAvatar}">
                <img :src="shareUserAvatar" v-if="shareUserAvatar" alt="">
            </div>
            <div class="staff-info">
                <p>{{shareUserName}}</p>
                <p>经纪人</p>
            </div>
        </div>
        <div class="toolbar-item icon" @click="telListShow">
            <div class="toolbar-cover van-hairline--left">
                <p><van-icon name="phone-o" class="icon" /></p>
                <p>打电话</p>
            </div>
        </div>
        <div class="toolbar-item icon" @click="showShare=true">
            <div class="toolbar-cover van-hairline--left">
                <p><van-icon name="share-o" /></p>
                <p>分享</p>
            </div>
        </div>
    </div>
    <van-action-sheet
        v-model="telShow"
        cancel-text="取消"
        close-on-click-action
        @cancel="telShow=false"
    >
        <van-cell :title="item.label || item.name" :value="item.phone || item.mobile" size="large" v-for="item in onSiteTel" :key="item.phone || item.mobile" :url="'tel:'+(item.phone || item.mobile)" clickable safe-area-inset-bottom/>
    </van-action-sheet>
    <van-share-sheet
    v-model="showShare"
    title="定制分享信息"
    :options="shareOptions"
    @select="onSelectShare"
    />
    <!-- 分享用弹窗 -->
    <van-dialog v-model="shareDialogShow" 
    title="定制分享内容" 
    show-cancel-button
    confirmButtonColor="#00a0e4"
    :beforeClose="setShareContent">
         <van-field label-width="5em" v-model="shareForm.title" label="分享标题" :placeholder="houseShareTitle" />
            <van-field
            label-width="5em"
            v-model="shareForm.desc"
            rows="2"
            autosize
            label="分享描述"
            type="textarea"
            />
            <p class="main-color f-12 p-all-10">此处仅为设置分享标题及描述，设置成功后，请点击右上角按照正常流程进行分享</p>
    </van-dialog>
   </div>
</template>

<script>
import lazyloadMixin from "@/mixins/lazyload";
import {vwToPx,isArray,isObjectNone,findIndex,cloneDeep,isWeex} from "@/utils";
import { ShareSheet } from 'vant';
import mapLoad from "@/utils/Amap";
import backMixin from "@/mixins/back";
import api from "@/api";
import houseTipsObj from "@/config/houseTips";
import wxConfig from "@/utils/wxconfig";
import dataStorage from "@/utils/dataStorage";
import {Decrypt,Encrypt} from "@/utils/crypto";
import commissionItem from "@/components/house/commission-item";
export default {
    mixins:[backMixin,lazyloadMixin],
    components:{
        [ShareSheet.name]:ShareSheet,
        commissionItem
    },
    data(){
        return{
            tabsActive:0,
            minHeight:0,
            houseData:{},
            houseImagesNumber:0,
            imageShow:false,
            typeIndexImages:[],
            //图片index,初始图片的地址
            imageDefaultIndex:0,
            //实际的图片index
            imageIndex:0,
            mainTypeImages:[],
            //类型第一页需要
            typeIndex:0,
            imageLength:0,
            //奖励遮罩
            incentiveShow:false,
            //地图初始化
            map:null,
            point:null,
            //视频首屏图
            videoPoster:'',
            videoPlayover:false,
            //视频计算高度
            videoHeight:180,
            //联系方式展示
            telShow:false,
            //设置分享
            showShare:false,
            //设置分享弹窗
            shareDialogShow:false,
            shareOptions: [
                { name: '微信', icon: 'wechat',type:1}
            ],
            shareForm:{
                title:'',
                desc:''
            },
            shareData:{},
            that:this,
            commissionTabs:[],
            commissionItems:{},
            commissionActive:0
        }
    },

    filters:{
        bannerImage(val,that){
            if(!val) return that.loadingImage;
            return that.$getImageUrl(val);
        },
        areaFilter(val){
            if(!val) return '暂无面积';
            if(val.indexOf('㎡') != -1){
                return val;
            }else{
                return `${val}㎡`
            }
        }
    },

    methods:{
        init(){
            this.getHouseData();
            this.policyPropertyGroup();
        },
        //获取楼盘图片列表
        getHouseImageNumber(){
            if(isObjectNone(this.houseData) || !isArray(this.houseData.albums)) return;
            const _index = this.houseData.albums.length-1;
            this.houseData.albums.forEach((el,index)=>{
                //拉取视频首图
                if(index == _index && this.houseData.buildingVideo){
                    this.videoPoster = isArray(el.list) 
                    ? el.list[1] 
                    ? el.list[1].picUrl
                    : el.list[0] ? el.list[0].picUrl
                    : this.houseData.buildingDiagram  : this.houseData.buildingDiagram;
                }
                this.houseImagesNumber+= isArray(el.list) ? el.list.length :0;
                //添加图片
                if(isArray(el.list)){
                    this.typeIndexImages.push(...el.list);
                }
            })
        },
        
        //获取楼盘数据
        getHouseData(){
            this.$toast.loading({
                message:'加载中...',
                duration:0,
                overlay:true
            });
            let options = {
                id:this.$route.params.id
            };
            //当存在分享人id且用户处于未登录状态的情况下，才传参
            if(this.isSharePage){
                options.shareUser = this.isSharePage;
                let userShareOpenId =  dataStorage.StorageGetter('share');
                //当存在持久化openId的情况下，发起记录
                if(userShareOpenId){
                    this.shareData = Decrypt(userShareOpenId);
                }
                if(!this.$route.query.code && !this.shareData?.openid && this.isSharePage != this.shareUserId){
                    this.$dialog.confirm({
                        title: '获取授权',
                        message: '为了方便您的后续使用\n我们将获取您的头像和昵称',
                        confirmButtonColor:"#00a0e4"
                    }).then(() => {
                        this.getWxOpenId();
                    }).catch(()=>{});
                }
            }
            api.getHouseData({
                options,
                success:(res)=>{
                    if(res.type==200){
                        if(this.isSharePage){
                           document.title =res.data?.name || '';
                        }else{
                            document.title = `${this.$store.state.userInfo?.company?.title}报备系统`;
                        }
                        let _index=0;
                        //处理图片,将主力户型并入户型图中，单独提出户型图，其他的放在细节里
                        if(isArray(res.data.albums) && res.data.albums.length > 0){
                            let images=[];
                            let detail = {name:'细节',list:[]};
                            res.data.albums.forEach((el)=>{
                                if(el.picType == 'houseType'){
                                    el.name = '户型';
                                    images.push(el);
                                }else{
                                    detail.list.push(...el.list);
                                }
                            });
                            images.push(detail);
                            res.data.albums = images;
                        }
                        //楼盘户型列表
                        if(isArray(res.data.buildingType) && res.data.buildingType.length>0){
                            //检查是否存在户型
                            let check = findIndex(res.data.albums,el=>{
                                return el.picType == 'houseType';
                            });
                            let arr = res.data.buildingType.filter(el=>{
                                return el.typeThumb;
                            });   
                            let arr1 = arr.map(el=>{
                                return {
                                    picUrl:el.typeThumb,
                                    picTypeCn:el.typeName,
                                    desc:el.typeDescription
                                }
                            });
                            if(arr1.length>0){
                                //存在,添加
                                if(check !=-1){
                                    res.data.houseImages[check].list.push(...arr1);
                                }else{
                                    res.data.albums.splice(0,0,{name:'户型',list:arr1});
                                }
                            }
                        }
                        //杂图列表
                        if(isArray(res.data.albums) && res.data.albums.length>0){
                            res.data.albums.forEach((el)=>{
                                el.typeIndex= _index;
                                el.list.forEach((_el,__index)=>{
                                    _el.typeIndex= _index;
                                    _el.index= __index+1;
                                    _el.total = el.list.length;
                                });
                                _index+=el.list.length;
                            })
                        }
                        this.houseData = res.data;
                        this.getHouseImageNumber();
                        //初始化一下微信分享
                        this.wxInit({});
                        //直接触发记录
                        this.addShareToSharer();
                    }else{

                        this.$dialog.alert({

                            title:'获取数据失败',

                            message:res.msg || '获取对应的楼盘数据失败，请稍后重试'

                        }).then(()=>{})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'获取数据失败',

                        message:'获取对应的楼盘数据失败,请求异常，请稍后重试'

                    }).then(()=>{})
                    

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },


        //视频播放

        videoPlay(){

            document.getElementById('videoPlay').play();

        },

        //视频暂停

        videoPause(){

            this.videoPlayover = false;

        },

        //视频开始播放

        videoPlayStart(){

            this.videoPlayover = true;

        },

        videoPlaying(){

            this.videoPlayover = true;

        },

        //展示联系方式列表

        telListShow(){

            if(this.onSiteTel.length == 0){

                this.$toast({

                    position:'bottom',

                    message:'暂无联系人'

                });

                return;
            }

            this.telShow=true;

        },

        //对渠道负责人进行处理

        setChannelList(list){

            let _list = isArray(list) ? cloneDeep(list) : [];

            _list.forEach(el=>{

                el.name = el.name+"(渠道对接人)";

            });

            return _list;

        },
        
        //选择的分享方式

        onSelectShare(option){

            //微信分享

            if(option?.type == 1){

                //打开弹窗

                this.shareDialogShow= true;

            };

            this.showShare=false;

        },

        //设置定制分享的内容

        setShareContent(action,done){

            if(action == 'confirm'){

                this.wxInit({

                    title:this.shareForm.title,

                    desc:this.shareForm.desc,

                    success:()=>{

                        done();

                        this.$toast.success('设置分享内容成功，使用微信进行分享');

                    },

                    error:()=>{

                        done();

                        if(!isWeex()){

                            this.$toast.fail("请在微信浏览器进行设置");

                        }else{

                            this.$toast.fail("设置失败，请稍后重试");

                        }

                    }

                })

            }else{

                done();

            }

  

        },












        //打开图片预览

        openImageView({findex}){

            if(this.typeIndexImages.length == 0) return;
            if(findex || findex === 0){

                this.imageDefaultIndex = findex;

            }

            this.imageShow=true;

        },

        //主力户型预览

        openHouseTypeList(item){

            if(!item.typeThumb) return;

            let index= findIndex(this.typeIndexImages,el=>{
                return el.url == item.typeThumb;
            })

            if(index !=-1){
                this.openImageView({
                    findex:index
                })
            }


        },

        //图片切换

        imageChange(index){

            this.imageIndex = index;

            this.typeIndex = this.typeIndexImages[index].typeIndex;

        },

        //点击切换

        imageTabChange(index){

            this.typeIndex = index;

            this.$refs.houseDetailImageView.swipeTo(index);

        },


        //收藏楼盘

        favoriteHouse(){

            //查看是否为登录状态

            if(!this.$store.state.userInfo.ready){

                this.$router.push({name:'login'});

                return;

            };

            if(!this.houseData.id) return;

            this.$toast.loading({

                message:'提交中...',

                duration:0,

                forbidClick:true

            })

            api.favoriteHouse({

                options:{

                    id:this.houseData.id,

                    status:!this.houseData.isFavorite,

                    name:this.houseData.name

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.houseData.isFavorite = !this.houseData.isFavorite;

                        if(this.houseData.isFavorite){

                            this.$toast.success('收藏成功');

                        }


                        this.$store.commit('setRouterData',{

                            key:'favoriteListRefresh',

                            value:true

                        })

                    }else{

                        this.$notify({type:"danger",message:res.msg || '请求失败，请稍后重试'});

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$notify({type:"danger",message:'请求异常，请稍后重试'});

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },

        //跳转

        goLink(route,type){
            if(!this.houseData.id) return;
            this.$store.commit('resetRecordData');
            if(type == 'record' && this.isReady){
                //将当前楼盘添加进去
                this.$store.commit('recordArrayChange',{
                    key:'selectHouse',
                    item:this.houseData
                });
                //获取提示内容
                const houseTips = houseTipsObj.joinTips(this.houseData);
                //个别提醒
                if(houseTips.status){

                    this.$dialog.alert({

                        title:houseTips.title,

                        message:houseTips.message,

                        confirmButtonColor:'#00a0e4',

                        confirmButtonText:'我知道了',

                        messageAlign:"left"

                        
                    }).then(()=>{

                        this.$nextTick(()=>{

                            this.$router.push(route);

                        })

                    })

                }else{

                    this.$nextTick(()=>{

                        this.$router.push(route);

                    })

                }
                
            }else{

                this.$nextTick(()=>{

                    this.$router.push(route);

                })

            }


        },

        //切换tabs

        tabsChange(index){

            let activeIndex = this.$store.state.userInfo.ready ? 3 : 2;

            if(index == activeIndex && !this.map && this.houseData?.longitude && this.houseData?.latitude){

                let point = [this.houseData?.longitude,this.houseData?.latitude];

                global.setTimeout(()=>{

                     mapLoad(['AMap.ToolBar']).then((AMap)=>{

                        AMap.convertFrom(point, 'baidu', (status, result)=>{

                            if (result.info === 'ok') {

                                let lnglats = result.locations;
                                
                                if(lnglats.length == 0) return;

                                this.point = lnglats[0];

                                let marker = new AMap.Marker({

                                    position:this.point,

                                    title: this.houseData.name

                                });

                                this.map = new AMap.Map('container', {

                                        zoom:13,//级别
                                        center: this.point,//中心点坐标
                                        resizeEnable: true,
                                        scrollWheel:false,
                                        keyboardEnable:false,
                                        doubleClickZoom:false,
                                        touchZoom :false,
                                        dragEnable:false
                    
                                });

                                this.map.addControl(new AMap.ToolBar({

                                    liteStyle: true

                                }));

                                this.map.add(marker);

                            }
                        });

                    })
                    .catch(err=>{console.log(err)})
                            

                },300)

            }

        },

        //开始导航

        navigationStart(){

            this.$dialog.alert({

                title:'路线规划',

                message:'为了保证定位的准确度，请您开启手机上的定位功能，否则定位可能会失败。',

                theme: 'round-button',

                confirmButtonText:'知道了',

                confirmButtonColor:this.mainColor

            }).then(()=>{

                this.goLink({name:'houseNavigation',query:{type:1,point:this.point,title:this.houseData.name}})

            })

            

        },

        //获取视频的高度

        setVideoHeight(){

            const videoWidth = document.getElementById('videoPlay').videoWidth;

            const videoHeight = document.getElementById('videoPlay').videoHeight;

            //计算高度

            this.videoHeight = (videoHeight/videoWidth) * (this.$refs.videoContent.clientWidth);

        },

        //记录转发人

        addShareToVisitor(){
            
            if(!this.shareData?.openid) return;

            api.addShareToVisitor({

                options:{

                    openid:this.shareData?.openid,

                    shareUser:this.isSharePage,

                    houseid:this.houseData.id

                },

                success:()=>{},

                error:(err)=>{

                    console.log(err);

                }

            })

        },

        //记录查看人

        addShareToSharer(){

            if(!this.shareData?.openid) return;
            
            api.addShareToSharer({

                options:{

                    openid:this.shareData?.openid,

                    shareUser:this.isSharePage,

                    houseid:this.houseData.id

                },

                success:()=>{},

                error:(err)=>{

                    console.log(err);

                }

            })

        },

         //获取微信授权

        getWxOpenId(){

                this.$toast.loading({

                    message:"正在请求授权...",

                    duration:0,

                    forbidClick: true,

                });


                api.bindWx({

                    options:{

                        url:this.$route.path

                    },

                    success:(res)=>{

                        if(res.type == 200){

                            window.location.href=res.data.url;

                        }else{

                            this.$dialog.alert({

                                title:'绑定失败',

                                message:res.msg || "微信绑定失败，请求异常，请稍后重试"

                            }).then(()=>{})


                        }

                    },

                    error:(err)=>{

                        console.log(err);

                        this.$dialog.alert({

                            title:'授权失败',

                            message:"微信授权失败，请求异常，请稍后重试"

                        }).then(()=>{})

                    },

                    complete:()=>{

                        this.$toast.clear();

                    }

                })

        },

        //获取并保存对应的openid

        getShareUserData(code){

            this.$toast.loading({

                message:"正在授权...",

                duration:0,

                forbidClick: true,

            });

            api.getShareUserCode({

                options:{

                    code,

                    shareUser:this.isSharePage

                },

                success:(res)=>{

                    if(res.type == 200){

                        dataStorage.StorageSetter('share',Encrypt(res?.data));
                        
                        this.$nextTick(()=>{

                            this.$router.replace({path:this.$route.path});

                        })


                    }else{

                        this.$dialog.alert({

                            title:'授权失败',

                            message:res.msg || "微信授权失败，请稍后重试"

                        }).then(()=>{

                            
                            this.$nextTick(()=>{

                                this.$router.replace({path:this.$route.path});

                            })


                        });

                    }


                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'授权失败',

                        message:"微信绑定失败，请求异常，请稍后重试，"+err

                    }).then(()=>{

                        
                        this.$nextTick(()=>{

                            this.$router.replace({path:this.$route.path});

                        })


                    });

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },


        //开始分享

        wxInit(options){

            let _path = this.$route.path;

            let _this = this;

            //当为分享页面时,替换一下对应的分享

            if(this.isSharePage){

                //判断当前用户是否为会员，如果是那就替换掉分享id,如果不是那就zhao

                if(this.shareUserId){
                        
                    let __path = _path.split('/');

                    _path = `${__path[1]}/${__path[2]}/${__path[3]}`

                    _path+=`/${this.shareUserId}`

                }

            }else{

                //当不是分享页面又是会员的时候添加对应的值

                if(this.shareUserId){

                    _path+=`/${this.shareUserId}`

                }
                

            }

            wxConfig.init().then(wx=>{

                wx.onMenuShareAppMessage({ 

                    title: options?.title || this.houseData?.name, // 分享标题

                    desc: options?.desc || this.houseData?.projectIntroduction || '', // 分享描述

                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致

                    link: 'https://'+window.location.host+_path, 

                    imgUrl: this.houseData?.buildingDiagram || '', // 分享图标

                    success: function(){
                        //分享成功记录一下

                        if(_this.isSharePage){

                            _this.addShareToVisitor();

                        }

                    }

                });

                wx.onMenuShareTimeline({

                    title: options?.title || this.houseData?.name, // 分享标题

                    desc: options?.desc || this.houseData?.projectIntroduction || '', // 分享描述

                    // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致

                    link: 'https://'+window.location.host+_path, 

                    imgUrl: this.houseData?.buildingDiagram, // 分享图标
                    success: function(){

                        //分享成功记录一下

                        if(_this.isSharePage){

                            _this.addShareToVisitor();

                        }

                    }

                });

                options?.success && options?.success();
                

            }).catch(err=>{

                console.log('分享失效',err);

                options?.error && options?.error();

            })


        },

        //获取佣金政策项
        policyPropertyGroup(){
            if(!this.isReady) return;
            api.policyPropertyGroup({
                options:{buildingId:this.$route.params.id},
                success:(res)=>{
                    if(res.type==200){
                        this.commissionTabs = res.data.filter(el=>{
                        return el.count && el.value;
                        }).map(el=>{
                        return {name:`${el.label}`,value:el.value}
                        });

                        if(this.commissionTabs.length == 0) return;
                        this.getCommissionPolicyItem(this.commissionTabs[0].value);
                    }
                },
                error:(err)=>{
                    console.log(err)
                }
            })
        },
        getCommissionPolicyItem(type){
            if(this.commissionItems[type]) return;
            api.commissionPolicyList({
                options:{
                    buildingId:this.$route.params.id,
                    buildingProperty:type,
                    page:1,
                    pageSize:1
                },
                success:(res)=>{
                    if(res.type == 200){
                        if(res.data.list.length>0){
                            this.$set(this.commissionItems,type,res.data.list[0])
                        }
                    }
                }
            })
        },
        commissionTabsChange(val){
            this.getCommissionPolicyItem(this.commissionTabs[val].value);
        }


     
    },
    
    computed:{
        //主题颜色
        mainColor(){
            return this.$store.state.userInfo?.company?.color;
        },
        //结佣规则
        commissionRule(){
            return this.houseData?.commission?.commissionRule || '暂无'
        },

        commissionPolicy(){
            return this.houseData?.commission?.commissionPolicy || '暂无'
        },

        totalPrice(){
            return parseFloat(this.houseData.packagePrice) ? this.houseData.packagePrice +'万/套' : this.houseData.averagePrice ? this.houseData.averagePrice+'元/㎡' : '暂无';
        },

        reportLeadTimeCn(){
            return this.houseData.commission?.incentiveTimeStr;
        },

        //获取显示区域高度

        getMinHeight(){

            return {minHeight:this.minHeight+'px'};

        },

        //获取对应的时间转化

        getRecordTime(){

            return this.houseData?.reportLeadTime ? this.houseData?.reportLeadTime ? parseInt(this.houseData?.reportLeadTime) < 60 ? `提前${this.houseData?.reportLeadTime}分钟` : `提前${(this.houseData?.reportLeadTime/60).toFixed(1)}小时` : '' : ''

        },

        images(){
            return this.typeIndexImages.map(el=>{

                return this.$getImageUrl(el.picUrl);

            })

        },

        activeImage(){

            return this.typeIndexImages[this.imageIndex] || {};

        },

        houseTypeList(){
            //有图片的才展示
            return this.houseData?.buildingType || [];

        },

        //是否登录了

        isReady(){

            return this.$store.state.userInfo.ready;

        },

        //验证是否存在地图坐标

        isLngLat(){

            return this.houseData?.latitude && this.houseData?.longitude;

        },

        //电话列表,前面添加已绑定的渠道电话

        onSiteTel(){

            return [...this.setChannelList(this.$store.state.userInfo?.channelList),...(this.houseData?.onSiteTel || [])];

        },

        lineWidth(){

            return this.isReady && this.isLngLat ? '25vw' : '33.33vw'

        },


        //预设分享头部

        houseShareTitle(){

            return this.houseData?.name || ''

        },

        //预设分享内容

        houseShareDesc(){

            return this.houseData?.projectIntro || '';

        },

        //分享者id、存在则为分享页面

        isSharePage(){

            return this.$route.params?.shareId || '';

        },

        //分享人名称

        shareUserName(){

            return this?.houseData?.shareUser?.label;

        },

        //分享人头像

        shareUserAvatar(){

            return this?.houseData?.shareUser?.avatar || '';

        },

        //用户转发的用户id

        shareUserId(){

            return this.$store.state?.userInfo?.uid || this.shareData?.userid || '';

        }


    },
    

    mounted(){

        this.minHeight = this.$refs.bd.offsetHeight-vwToPx(16);

    },

    activated(){

        //获取参数

        if(this.$route.query.code){

            this.getShareUserData(this.$route.query.code);

        }else{

            this.wxInit({});

        }

    },

    created(){

        this.$nextTick(()=>{

            this.$fky_callback(this.init,200);

        })

    }

}
</script>

<style lang="less">
.house-detail-wrapper{

    display: flex;

    width: 100%;

    height:100%;

    overflow: hidden;

    flex-direction: column;

    .van-image-preview__index{

        top:15px;

        left:0;

        right:0;

        transform: translate(0,0);

    }

    .photo-type{

            display: flex;

            width: 100%;

            .photo-type-item{

                flex:1;

                text-align: center;

                line-height:40px;

                font-size:13px;

                color:#aaa;

                &.active{

                    color:#fff;

                    span{

                        position: relative;

                        display: inline-block;

                        line-height:40px;

                        &:after{

                            content:'';

                            position: absolute;

                            left:0;

                            right:0;

                            background: #00a0e4;

                            width: 60%;

                            margin:0 auto;

                            height:2px;
                            
                            bottom:5px;

                        }

                    }

                }

            }

        }

    .van-image-preview__cover{

        top:auto;

        bottom:0;

    }

    .image-name,.image-des,.image-index{

        position: absolute;

        bottom:16vh;

        color:#fff;

        white-space: nowrap;

        text-shadow: 0 0 5px rgba(0,0,0,.8);

        font-size:13px;

    }

    .image-index{

        left:50%;

        transform: translateX(-50%);

        bottom:23vh;

        padding: 3px 15px 2px;

        background:rgba(0,0,0,.6);

        border-radius:33px;

    }

    .van-image-preview__cover{

        width: 100%;
        

    }

    .image-name{

        left:15px;

    }

    .image-des{

        right:15px;

    }
    .parse-cover{
        white-space: pre-wrap;
        img{
            max-width: 100%;
        }
        video{
            max-width: 100%;
        }
    }

    .bd{

        flex:1;

        height:0;

        padding:10px 0 20px;

        overflow-y:auto;

        .house-banner{

            position: relative;

            margin:0 15px;

            height:200px;

            border-radius:8px;

            .thumb-cover{

                width: 100%;

                height:100%;

                overflow: hidden;

                border-radius:5px;

                img{

                    width: 100%;

                    height:100%;

                }


            }
            
            .tag,.vr-icon{

                position: absolute;

                width: 45px;

                height:45px;

                line-height:47px;

                text-align: center;

                right:10px;

                bottom:-20px;

                z-index:15;

                border-radius:50%;

                background:#00a0e4;

                font-size:14px;

                color:#fff;

                letter-spacing: 1px;

            }

            .vr-icon{

                left:50%;

                top:50%;

                right:0;

                transform: translate(-50%,-50%);

                background: transparent;

                width: 80px;

                height:80px;

                padding-top:22px;

                .van-icon{

                    font-size:36px;

                    text-shadow:0 0 1px rgba(0,0,0,.3);

                }

            }

        }

        .house-info{

            padding: 20px 15px 0;

            .house-info-item{

                display: flex;

                &:first-child{

                    justify-content: space-between;

                    align-items: center;

                    padding-bottom:10px;

                }

                .house-name{

                    font-size:18px;

                    font-weight: bold;

                }

                .house-price{

                    color:#ef8037;

                    font-size:16px;

                }

                &.tag{

                    span{

                        padding-right:5px;

                        margin-left:5px;

                        position: relative;

                        &:after{

                            content:'';

                            position: absolute;

                            right:0;

                            top:55%;

                            width: 1px;

                            height:60%;

                            background: #eee;

                            transform: translateY(-50%);

                        }

                        &:first-child{

                            margin-left:0;

                        }

                        &:last-child:after{

                            display: none;

                        }

                    }


                }


            }

        }

        .house-record-text{
                    
            padding: 15px;

            span{

                display: inline-block;

                line-height:20px;

                padding: 3px 5px 1px;

                background: #f6f6f6;

                width: auto;

                font-size:13px;

                color:#999999;

            }


        }


        .incentive{

            margin:10px 15px 15px;

            background:#f3f9ff;

            color:#0076e4;

            height:120px;

            position: relative;

            padding: 15px;

            border-radius:5px;

            .incentive-title{

                font-size:16px;

                font-weight: bold;

                padding-bottom:15px;

            }

            .incentive-ctn{

                width: 120px;

                line-height:24px;

                font-size:14px;

                height:50px;

                white-space: pre-wrap;

            }

            .open{

                position: absolute;

                top:50%;

                transform: translateY(-50%);

                right:10px;

                font-size:25px;

            }

            .incentive-overlay-wrapper{

                background: #fff;

                position: absolute;

                width: 90%;

                left:50%;

                top:50%;

                transform: translate(-50%,-50%);

                padding: 15px;

                border-radius: 5px;

                .incentive-overlay__title{

                    font-size:16px;

                    padding-bottom:20px;

                    font-weight: bold;

                }

                .incentive-overlay__content{

                    white-space: pre-wrap;

                    line-height:24px;

                    font-size:14px;

                    min-height:120px;

                }

            }

        }

        .house-record-video{

            padding: 0 15px 15px;

            .house-record-video__title{

                font-size:16px;

                padding-bottom:15px;

                font-weight: bold;

            }

            .house-record-video__content{

                min-height:180px;

                position: relative;

                video{

                    width: 100%;

                    height:100%;

                    background: black;

                    object-fit:scale-down;

                    position: relative;

                    z-index:0;

                }

                .video-icon{

                    width: 50px;

                    height:50px;

                    background: rgba(0,0,0,.6);

                    border-radius: 50%;

                    text-align: center;

                    color:#fff;

                    .van-icon{

                        font-size:24px;

                        position: absolute;

                        left:0;

                        right:0;

                        top:0;

                        bottom:0;

                        width: 24px;

                        height:24px;

                        margin: auto;

                    }

                }

                .video-play{

                    position: absolute;

                    z-index:10;

                    width: 100%;

                    height:100%;

                    left:0;

                    top:0;

                    .video-poster{

                        width: 100%;

                        height:100%;

                        img{

                            width: 100%;

                            height:100%;

                        }

                    }

                }

            }

        }

        .tabs{

            border-top:10px solid #f6f6f6;

            padding-top:15px;

            .van-tab--active{

                color:#00a0e4;

            }

            .van-tabs__line{

                width: 125px;

                background-color:#00a0e4;

                height:1px;

            }

            .tab-item{

                &.base-info{

                    font-size:13px;

                    line-height:24px;

                    color:#666666;

                    padding: 15px 20px 20px;

                    .base-info-column{

                        margin-bottom:20px;

                        .base-info-column-title{

                            font-size:16px;

                            padding-bottom:5px;

                            color:#333333;

                        }

                        .base-info-column__sub-title{

                            color:#00a0e4;

                            padding:6px 0 4px;

                            font-size:14px;

                        }

                    }

                }

                &.y-rules{

                    .y-rules-cover{

                        padding:15px 20px 15px;

                    }

                    .y-rules-item{

                        .y-rules-item__title{

                            font-size:15px;

                            color:#00a0e4;

                            padding-bottom:10px;

                        }

                        .y-rules-item__ctn{

                            line-height:26px;

                            font-size:15px;

                            white-space: pre-line;

                        }

                    }

                }


                &.house-type-main{

                    .house-type-main-cover{

                        padding: 0 15px 15px;

                        .house-type-main-item{

                            &.none{

                                text-align: center;

                                padding-top:30px;

                                font-size:14px;

                                color:#999999;

                                justify-content: center;

                            }

                            display: flex;

                            align-items: flex-start;

                            padding: 15px 0;

                            .thumb{

                                width: 120px;

                                height:75px;

                                overflow: hidden;

                                box-shadow: 0 0 1px rgba(0,0,0,.08);

                                img{

                                    width: 100%;

                                    height:auto;

                                    min-height:100%;

                                }

                            }

                            .ctn{

                                flex:1;

                                min-height:75px;

                                width: 0;

                                display: flex;

                                flex-direction: column;

                                justify-content: space-between;

                                padding-left:10px;
                                
                                .item{

                                    font-size:12px;

                                    color:#999;

                                }

                                .house-type-name{

                                    font-size:13px;

                                }   
                                
                              

                            }

                        }

                    }

                }

                &.map{

                    .map-cover{

                        padding-top:10px;

                        .house-addr{

                            font-size:13px;

                            line-height:24px;

                            color:#666666;
                            
                            width: 240px;

                        }

                        .van-button--small{

                            height:30px;

                        }

                        .map-container{

                            height:180px;

                            margin-top:10px;

                            background: #f6f6f6;

                            position: relative;
                            
                            .tips{

                                position: absolute;

                                left:50%;

                                top:50%;

                                transform: translate(-50%,-50%);

                                color:#999999;

                                font-size:14px;

                            }

                        }

                    }

                }

            }

            &.hm-tabs{

                .van-tab--active{

                    color:#ffae00

                }

                .van-tabs__line{

                    width: 125px;

                    background-color:#ffae00;

                    height:1px;

                }

                .tab-item.base-info .base-info-column .base-info-column__sub-title,.tab-item.y-rules .y-rules-item .y-rules-item__title{

                    color:#ffae00;

                }

                .van-button--plain.van-button--info{
                    color:#ffae00;
                    border-color:#ffae00;
                }

            }

        }

    }

    .toolbar{

        display: flex;

        .toolbar-item{

            flex:1;

            height:55px;

            display: flex;

            flex-direction: column;

            align-items: center;

            justify-content: center;

            font-size:13px;

            position: relative;

            z-index:10;

            &:active{

                opacity: .6;

            }

            &.star.active{

                color: #00a0e4;

                .icon{

                    color: #00a0e4;

                }

            }

            a{

                display: block;
                
                width: 100%;

                height:100%;

                display: flex;

                flex-direction: column;

                align-items: center;

                justify-content: center;

            }

            .icon{

                font-size:18px;

            }

            p{

                line-height:16px;

            }

            &.record{

                flex:2.5;

                background: #00a0e4;

                color:#fff;

                flex-direction: row;

                p{

                    text-align: center;

                    font-size:16px;

                    &:first-child{

                        margin-right:20px;

                        font-size:14px;

                    }

                }

            }

        }

        &.share-cover{

            color:#00a0e4;

            .staff-avatar{

                width: 40px;

                height:40px;

                img{

                    width: 100%;

                    height:100%;

                }

            }

            .toolbar-item{

                height:60px;

                .toolbar-cover{

                    text-align: center;

                    width: 100%;

                }

                &.staff{

                    flex:1.5;

                    display: flex;

                    flex-direction: row;

                    justify-content: flex-start;

                    padding: 0 15px;

                    &:hover{

                        opacity: 1;

                    }

                    .avatar{

                        margin-right:10px;

                    }

                    .staff-info{

                        p:first-child{

                            font-size:17px;

                            line-height:28px;
                            
                            font-weight: bold;

                            color:#666666;

                        }

                    }

                }

                &.icon p:first-child{

                    line-height:22px;

                    .van-icon{

                        font-size:22px;

                    }

                }
                

            }

        }


        &.hm-toolbar{

            &.share-cover{

                color:#ffae00;

            }

            .toolbar-item{

                &.star.active{

                    color: #ffae00;

                    .icon{

                        color: #ffae00;

                    }

                }

                &.record{
                    background: #ffae00;
                }

            }

        }

    }

}

</style>
