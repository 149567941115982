export default{

    

    methods: {

        //分销商专用权限
        
        permissionCheck(){

            if(this.$store.state.userInfo?.distributorStaffType !='DIANZHANG'){

                this.$dialog.alert({

                    title:'无使用权限',

                    message:"您没有该页面的使用权限，请返回",

                    confirmButtonColor:"#00a0e4"

                }).then(()=>{

                    this.$router.replace({name:"home"});

                });

                return false;

            }else{

                return true;

            }

        },

        //客户列表权限
        
        permissionCustomerListCheck(){
            if(this.$store.state.userInfo.distributorStaffType !='DIANZHANG' && this.$store.state.userInfo.distributorStaffType !='YUEWUYUAN'){

                this.$dialog.alert({

                    title:'无使用权限',

                    message:"您没有该页面的使用权限，请返回",

                    confirmButtonColor:"#00a0e4"

                }).then(()=>{

                    this.$router.replace({name:"home"});

                });

                return false;

            }else{

                return true;

            }

        },

        //报备专员权限

        permissionAuditCheck(){

            if(this.$store.state.userInfo.role !='AUDITOR' && this.$store.state.userInfo.role !='AUDITORADMIN'){

                this.$dialog.alert({

                    title:'无使用权限',

                    message:"您没有该页面的使用权限，请返回",

                    confirmButtonColor:"#00a0e4"

                }).then(()=>{

                    this.$router.replace({name:"home"});

                });

                return false;

            }else{

                return true;

            }

        },

        
    },


}