export default {
    
    /*************** 用户相关接口 ***************/

    //获取公司相关信息

    getCompanyInfo:{type:'get',url:'/api/index/get-company'},

    //登录

    login:{type:"post",url:"/api/login/sign-by-code"},

    loginPassword:{type:"post",url:"/api/login/sign"},

    //退出登录

    logOut:{type:"post",url:"/api/login/logout"},

    //获取机器验证码

    getVerifyCode:{type:'get',url:"/api/login/verify-code"},

    //获取短信验证码

    getCode:{type:'get',url:"/api/login/get-code"},
    
    //更新用户数据

    updateUserData:{type:"post",url:'/api/user/update-info'},

    //密码修改

    changePassword:{type:'post',url:"/api/user/change-password"},

    //密码找回

    retrievePassword:{type:'post',url:'/api/login/retrieve-password'},

    //检查登录状态

    getInitConfig:{type:"post",url:"/api/login/check-token"},

    //获取未登录时的公共数据

    getCommonInitData:{type:'post',url:"/api/index/get-search-info"},

    //提交反馈信息

    help:{type:"post",url:"/api/user/add-gbook"},

    //获取微信验证码

    getWxCode:{type:'post',url:"/api/login/get-wx-code"},

    //获取微信地址

    bindWx:{type:"post",url:'/api/wechat/wx-login'},
    
    //获取微信地址

    wxCode:{type:"post",url:'/api/login/wx-code'},
    
    //微信登录

    signByWx:{type:"post",url:'/api/login/sign-by-wx'},

    //绑定微信code

    bindWxCode:{type:"get",url:'/api/wechat/oauth-callback'},

    //获取用户授权数据

    getShareUserCode:{type:'get',url:"/api/wechat/oauth-callback-share"},

    //解绑微信

    closeWx:{type:"get",url:'/api/user/wechat-out'},

    //获取微信接口参数

    getWxConfig:{type:'post',url:'/api/wechat/get-js-sdk'},

    //分销商注册

    distributorRegister:{type:"post",url:"/api/publicapi/reg-distributor"},

    //获取渠道对接人列表

    getChannelSelect:{type:"post",url:"/api/publicapi/get-channel-select",list:true},

    //获取公司查询列表

    getCompanySelect:{type:"get",url:"/api/publicapi/get-company-by-keyword",list:true},

    //查询公司信用代码
    getCompanyCode:{type:"get",url:"/api/publicapi/get-company-by-name"},
    //记录转发人

    addShareToVisitor:{type:'post',url:'/api/user/add-share-to-shar'},


    //记录查看人

    addShareToSharer:{type:'post',url:'/api/user/add-share-to-visitor'},

    //获取分享详情数据

    getUserShareStatistics:{type:'post',url:"/api/user/get-user-share-statistics"},

    //获取楼盘分享数据

    getUserShareHouseStatistics:{type:'post',url:"/api/user/get-user-share-house-statistics",list:true},

    //获取城市列表
    getCityList:{type:'get',url:'/api/publicapi/get-city'},



    

    
    /*************** 公共的接口 ***************/


    //上传图片

    upload:{type:'post',url:'/api/upload/index'},

    //外部图片上传

    externalUpload:{type:'post',url:'/api/upload/index'},

    
    //获取楼盘列表

    getHouseList:{type:"post",url:'/api/index/get-house-list',list:true},

    //获取楼盘详情

    getHouseData:{type:"post",url:'/api/index/get-house-data'},


    //收藏楼盘

    favoriteHouse:{type:'post',url:"/api/user/favorite-house"},

    //获取收藏列表

    favoriteHouseList:{type:'post',url:"/api/user/favorite-house-list",list:true},

    //楼盘佣金政策项

    policyPropertyGroup:{type:"get",url:"/api/index/policy-property-group"},
    
    commissionPolicyList:{type:"post",url:"/api/index/commission-policy-list",list:true},



    /*************** 首页数据接口 *************/


    getIndexData:{type:"get",url:"/api/index/get-index-data"},


    /*************** 报备单接口 ***************/

    //智能识别接口

    getDemoMessage:{type:"post",url:"/api/publicapi/get-demo-message"},

    //提交报备信息

    submitRecordData:{type:'post',url:'/api/record/post-record-data'},

    //获取报备列表

    getRecordList:{type:'post',url:'/api/record/get-record-list',list:true},

    //获取公司报备列表

    getSubDistributorRecordList:{type:'post',url:'/api/record/get-distributor-record-list',list:true},

    //上传报备图片

    uploadRecordImage:{type:'post',url:'/api/record/upload-record-image'},

    //删除报备图片

    removeRecordImage:{type:'post',url:'/api/record/remove-record-image'},

    //客户动态

    updateRecordCustomerInfo:{type:'post',url:'/api/record/update-record-customer-info'},


    //获取报备单数据

    getRecordData:{type:'post',url:"/api/record/get-record-data"},

    //报备单审核数据获取地址

    getRecordAuditData:{type:'post',url:'/api/record/get-record-audit-data'},

    //报备单审核提交

    submitRecordAuditData:{type:'post',url:'/api/record/submit-record-audit-data'},

    //获取客户列表
    
    getCustomerList:{type:"post",url:"/api/record/get-customer-list"},

    //获取带看人列表

    getDaikanList:{type:'post',url:'/api/record/get-daikan-list'},

    //删除客户

    removeCustomer:{type:"post",url:'/api/record/remove-customer'},

    //员工管理列表

    getStaffList:{type:"post",url:"/api/user/get-user-list"},

    //删除员工

    removeStaff:{type:"post",url:'/api/user/del-user'},

    //添加员工

    addStaff:{type:"post",url:'/api/user/add-user'},

    //读取批量数据

    getDemoUserData: {type:"post",url:"/api/user/get-demo-user-data"},

    //确认导入批量数据

    batchAddStaff:{type:"post",url:'/api/user/batch-add-user'},

    //获取经理人下拉菜单列表

    getStaffSelectList:{type:'post',url:'/api/user/get-users-select-list'},

    //更新经纪人数据

    updateStaff:{type:'post',url:"/api/user/update-user"},





    /******************************* 报备单统计接口 *****************************/
    
    getReportStatistics:{type:"post",url:'/api/record/get-record-statics'},

    //获取统计详情数据(经纪人)

    getUserToReportStatistics:{type:"post",url:'/api/record/get-user-to-record-statics',list:true},

    //获取统计详情数据(楼盘)
    
    getHouseToReportStatistics:{type:"post",url:'/api/record/get-house-to-record-statics',list:true},

    //获取统计用门店列表

    getShopSelectList:{type:'post',url:"/api/user/get-shop-select-list"},



    /******************************* 分销商报备接口 *****************************/

    //获取外部审批列表

    getReportAutoList:{type:'post',url:"/api/record/get-report-auto-list",list:true},
    
    //获取审批列表内容

    getReportAutoCount:{type:'get',url:"/api/record/get-report-auto-count"},

    //外部报备发起审批

    checkReport:{type:'post',url:'/api/record/check-report'}
}